import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import SearchTextRow from './searchtextrow';
import SearchStatusRow from './searchstatusrow';
import { setCustomFilters, } from "../actions/index";
import { Row, Col, } from "react-bootstrap";

const Analysis = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const updateState = (col, data) => {
        let filters = state.custom_filters;
        if (col === 'template' || col === 'library' || col === 'upload' || col === 'qc') filters.analysisstatus.series[col] = { ...filters.analysisstatus.series[col], ...data.data }        
        else if (col === 'analysisstatus') filters.analysisstatus.op = data.op        
        else filters[col] = { ...filters[col], ...data };
        dispatch(setCustomFilters(filters));
    }  

    return (
        <Row className="text-center">
            <Col lg={12}>
                <table>
                    <tbody>
                        <SearchStatusRow
                            data={state.custom_filters.analysisstatus}
                            title="Analysis Status" col="analysisstatus"
                            op={state.custom_filters.analysisstatus.op}
                            series={state.custom_filters.analysisstatus.series}
                            updateState={updateState} />

                        <SearchTextRow
                            title="Analysis Comment" col="analysiscomment"
                            op={state.custom_filters.analysiscomment != undefined ? state.custom_filters.analysiscomment.op : ''} val={state.custom_filters.analysiscomment.val != undefined ? state.custom_filters.analysiscomment.val : ''}
                            placeholder='e.g. SPC run'
                            updateState={updateState} />
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default Analysis;