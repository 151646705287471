import React, {useState, useEffect} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import {setRunListData, getRunDataAllGroupedAsync, setTimeZone} from "../actions/index";
import * as Utils from '../../utils/utils';
import { Select, MenuItem, FormControl } from '@mui/material';
import moment from 'moment-timezone';
import {
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogTitle, 
    DialogActions
  } from '@material-ui/core';

const TimeZone = (props) => {
    const { handleClose, isOpen } = props;
    
    const runlist = useSelector(state => state.runlist);
    const jwt = useSelector(state => state.jwt);
    const is_all_run_list_data_shown = useSelector(state => state.is_all_run_list_data_shown);
    const dispatch = useDispatch();

    const [selectedTimeZone, setSelectedTimeZone] = useState(localStorage.getItem('time_zone') || 'Pacific Standard Time');
    const [originalRunlist, setOriginalRunlist] = useState(is_all_run_list_data_shown); 
    
    const timeZoneMap = Utils.zoneDictionary;

    useEffect(() => {
        setOriginalRunlist(is_all_run_list_data_shown);
    }, [is_all_run_list_data_shown])

    const useStyles = makeStyles((theme) => ({
        dialogWrapper: {
            padding: theme.spacing(2),
            position: 'absolute',
            top: theme.spacing(2),
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        closeIcon: {
            marginLeft: 'auto',
        },
        storagesContainer: {
            display: 'flex',
            justifyContent: 'space-evenly', 
            gap: 5, 
            margin: '20px 0px',
        },
        alertContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px 0px',
            overflow: 'hidden',
          },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            margin: '15px 0px',
            //overflow: 'hidden',
        },
        outlineInput: {
            height: 20,
            width: 50,
            marginLeft: 10,
            marginRight: 10,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
            overflow: 'hidden',
        },
        defaultButton: {
            margin: theme.spacing(1),
            background: 'linear-gradient(113.96deg, #E9F5F5 100%, #D9E7F4 100%)',
            border: '0.5px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            color: '#189AB4',
            width: 150,
            '&:disabled': {
                background: '#f0f0f0', // Change this to the desired disabled background color
                color: '#666666', // Change this to the desired disabled text color
            },
        },
        button: {
            margin: theme.spacing(1),
            background: '-webkit-gradient(linear, left top, right bottom, from(#0075a9), to(#003049))',
            border: '0.5px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            color: 'white',
            width: 150,
            '&:disabled': {
                background: '#f0f0f0', // Change this to the desired disabled background color
                color: '#666666', // Change this to the desired disabled text color
            },
        },
        formControl: {
            "& .MuiInputBase-root": {
              borderWidth: "1px",
              borderStyle: "solid",
              minWidth: "120px",
              width: "280px",
              justifyContent: "center",
              cursor: "pointer" // Set cursor to pointer
            },
            "& .MuiSelect-select.MuiSelect-select": {
              paddingRight: "15px"
            },
            marginLeft: "auto" // Move the FormControl to the right
          },
    }));

    const classes = useStyles();

    const handleChange = (event) => {
        setSelectedTimeZone(event.target.value);
    };

    const handleDefaultRunlistDate = () => {
        setSelectedTimeZone("Pacific Standard Time");
        dispatch(setTimeZone('Pacific Standard Time'));
        originalRunlist ? dispatch(getRunDataAllGroupedAsync(undefined, jwt)) : dispatch(getRunDataAllGroupedAsync(100, jwt));
    }

    const handleConvert = (originalTime, originalTimeZone, targetTimeZone) => {
        try {
    
            // Convert original time to target time zone directly
            const converted = moment.tz(moment(originalTime, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'), originalTimeZone);
            
            // Parse converted time in target time zone and format it
            // moment.tz(converted, targetTimeZone)
            const formattedConvertedTime = converted.clone().tz(targetTimeZone).format('MM/DD/YYYY HH:mm:ss');
            
            return formattedConvertedTime;
        } catch (error) {
            console.log(error);
            return null; // or handle the error appropriately
        }
    };

    const handleUpdateRunlistDate = () => {
        try {
            originalRunlist ? dispatch(getRunDataAllGroupedAsync(undefined, jwt)) : dispatch(getRunDataAllGroupedAsync(100, jwt));
            let copyRunList = Utils.deepClone(runlist);

            const updatedRunlist = copyRunList.map(entry => {
                if (entry.timezone) {
                    entry.originalDateTime = entry.startdatetime;
                    
                    const startDatetime = handleConvert(entry.originalDateTime ||  entry.startdatetime, timeZoneMap[entry.timezone], timeZoneMap[selectedTimeZone]);
                    entry.startdatetime = startDatetime;
                    entry.timezone = timeZoneMap[selectedTimeZone];
                }
                return entry;
            });
            dispatch(setTimeZone(selectedTimeZone));
            dispatch(setRunListData(updatedRunlist));
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
        <Dialog disableEnforceFocus onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen} fullWidth maxWidth={'sm'} classes={{paper: classes.dialogWrapper}}>
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">Time Zone Settings</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <div className={classes.inputContainer}>
                    <Typography>Time Zone Name:</Typography>
                    <FormControl className={classes.formControl}>
                        <Select
                            labelId="zone-label"
                            id="zone-select"
                            value={selectedTimeZone}
                            onChange={handleChange}
                        >
                            {Object.keys(timeZoneMap).map((key) => (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
            </div>
            <div className={classes.buttonContainer}>
                <DialogActions>
                <Button autoFocus color="primary" className={classes.defaultButton} onClick={handleDefaultRunlistDate}>
                    Default
                </Button>
                <Button autoFocus color="primary" className={classes.button} onClick={handleUpdateRunlistDate}>
                    Apply
                </Button>
                </DialogActions>
            </div>
        </Dialog>
        </>
    );

}

export default TimeZone;