import React from 'react';

const SummaryHeader = (props) => {
    const { label, value, width_perc } = props;
    return (
        <div>
           <span><h1>{value}</h1></span>
           <hr style={{border: '2px solid #189AB4', width: `${width_perc}%`, margin: 'auto' }} />
           <span><h5>{label}</h5></span>
        </div>       
    )
};

export default SummaryHeader;