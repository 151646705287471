import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import SearchRangeRow from './searchrangerow';
import { setCustomFilters, } from "../actions/index";
import { Row, Col, } from "react-bootstrap";

const Library = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const updateState = (col, data) => {
        let filters = state.custom_filters;
        if (col === 'template' || col === 'library' || col === 'upload' || col === 'qc') filters.analysisstatus.series[col] = { ...filters.analysisstatus.series[col], ...data.data }        
        else if (col === 'analysisstatus') filters.analysisstatus.op = data.op        
        else filters[col] = { ...filters[col], ...data };
        dispatch(setCustomFilters(filters));
    }

    return (
        <Row className="text-center">
            <Col lg={12}>
                <table>
                    <tbody>
                        <SearchRangeRow
                            title="F95 min" col="f95_30x_min"
                            op={state.custom_filters.f95_30x_min != undefined ? state.custom_filters.f95_30x_min.op : ''}
                            min={state.custom_filters.f95_30x_min.min} max={state.custom_filters.f95_30x_min.max}
                            low={state.custom_filters.f95_30x_min.low} high={state.custom_filters.f95_30x_min.high}
                            updateState={updateState} />

                        <SearchRangeRow
                            title="F95 max" col="f95_30x_max"
                            op={state.custom_filters.f95_30x_max != undefined ? state.custom_filters.f95_30x_max.op : ''}
                            min={state.custom_filters.f95_30x_max.min} max={state.custom_filters.f95_30x_max.max}
                            low={state.custom_filters.f95_30x_max.low} high={state.custom_filters.f95_30x_max.high}
                            updateState={updateState} />


                        <SearchRangeRow
                            title="RLQ25" col="rlq25"
                            op={state.custom_filters.rlq25 != undefined ? state.custom_filters.rlq25.op : ''}
                            min={state.custom_filters.rlq25.min} max={state.custom_filters.rlq25.max}
                            low={state.custom_filters.rlq25.low} high={state.custom_filters.rlq25.high}
                            updateState={updateState} />

                        <SearchRangeRow
                            title="Read Length (bp)" col="rl"
                            op={state.custom_filters.rl != undefined ? state.custom_filters.rl.op : ''}
                            min={state.custom_filters.rl.min} max={state.custom_filters.rl.max}
                            low={state.custom_filters.rl.low} high={state.custom_filters.rl.high}
                            updateState={updateState} />
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default Library;