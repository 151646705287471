import React from 'react';
import { Form } from "react-bootstrap";

const TextBox = (props) => { 
    const {label, value, onChange, onKeyPress, style }  = props;  
    return (
        <React.Fragment>
            <Form.Label>{label}</Form.Label>
            <Form.Control type="text" value={value} onChange={onChange} onKeyPress={onKeyPress} style={style} />
        </React.Fragment>
    )
};

export default TextBox;