import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import StatusItems from './statusitems';
import SearchStatusGroup from './searchstatusgroup';

const displayValues = {
    AND: "AND",
    OR: "OR", 
    a: 'All'  
};

const SearchStatusRow = (props) => {

    const { op, updateState, title, series } = props;

    const [operation, setOperation] = React.useState({ op: op ? op : 'a', series: series });

    const question = (title) => {
        return (
            <FontAwesomeIcon title={`Filter by ${title}`} icon={faQuestionCircle} style={{ width: "16px", height: "16px", color: "#ABABAB" }}/>
        )
    }

    useEffect(() => {
        if (updateState && (operation.c || operation.d || operation.f || operation.l || operation.p || operation.op)) {
            updateState('analysisstatus', operation);
        }
    }, [operation]);

    useEffect(() => {
        setOperation({...operation, op: op});
    }, [op]);

    const handleSelect = (e) => {
        setOperation({ ...operation, op: e, dirty: true });        
    }

    return (
        <tr style={{height: '200px !important'}}>
            <td className="w100">{title}</td>
            <td className="w24">{question(title)}</td>
            <td className="w170">
               <StatusItems selection={operation} handleSelect={handleSelect} displayValues={displayValues} />
            </td>
            <td colSpan="4">
                <div className="d-flex align-items-center text-center">                  
                    <SearchStatusGroup
                        data={series.library} op={operation.op}
                        title="Library analysis pipeline" group="library"
                        updateState={updateState} />
                    <SearchStatusGroup
                        data={series.upload} op={operation.op}
                        title="Upload" group="upload"
                        updateState={updateState} />
                    <SearchStatusGroup
                        data={series.qc} op={operation.op}
                        title="QC" group="qc"
                        updateState={updateState} />
                </div>
            </td>
        </tr>
    )
}

export default SearchStatusRow;
