import AuthApp from './components/auth/authapp';
import {ErrorFallback, ErrorHandler} from './components/errorboundary/errorboundary';
import {ErrorBoundary} from 'react-error-boundary';

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={ErrorHandler}>
      <AuthApp />
    </ErrorBoundary>
  );
}

export default App;
