export const RenderOpticsCharts = csv => {
    try {
        var rows = csv;
        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }       

        const unpackByRingNum = (rows, key1, key2) => {
            const result = rows.map(function (row) {
                if (row['ring'] === key2) return row[key1];
            });
            return result.filter(x => x !== undefined);
        }

        function getRingCount(rows) {
            let rings = getUniqueRings(rows);
            return rings.length;
        }
        function getUniqueRings(rows) {
            let rings = [];
            rows.forEach((row) => {
                if (rings.findIndex(e => e === row.ring) === -1) {
                    rings.push(row.ring);
                }
            });
            var sortedRings = rings.sort(function (a, b) {
                return a - b;
            });
            return sortedRings;
        }

        const getColor = (colorIndex) => {
            var colors = ["#0000FF", "#00FF00", "#FF0000", "#ADD8E6", "#FFC0CB", "#FFA500", "#8F00FF", "#964B00", "#FFFF00", "#808080"];
            if (colorIndex > colors.length) colorIndex = (colorIndex - colors.length) - 1;
            else if (colorIndex === colors.length) colorIndex = 3; //Ensures that consecutive lines after index[0] are not the same
            return colors[colorIndex];
        }

        const getColorIndex = () => {
            return Math.floor(Math.random() * 10);
        }

        var meanBeadSignal;
        var fitMeanBeadSignal;
        var ringBeadSignal;
        var linearMeanBidSignal;
        var beadSize;
        var fitBeadSize;
        var numBeads;
        var meanBidSignalDN2;
        var varmeanBeadSignal;
        var data;
        var data1;
        var data2;
        var data3;
        var data4;

        var ringPresent = false;
        if (rows[0] !== undefined && rows[0].ring !== undefined && rows[0].ring !== "" && rows[0].linearMeanBidSignal !== "") {
            ringPresent = true;

            function createRingObjectPlot1(rows) {
                const rings = getUniqueRings(rows);
                let ringData = [];
                let ring;
                for (let i = 0; i < rings.length; i++) {
                    ring = rings[i];
                    let col = getColorIndex();
                    meanBeadSignal = i === 0 ? {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'meanBidSignal', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        //visible: 'legendonly'
                    } :  {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'meanBidSignal', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    };
                    ringData.push(meanBeadSignal);

                    fitMeanBeadSignal = {
                        type: "scatter",
                        mode: "lines",
                        name: `Gaussian Fit R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'fitMeanBidSignal', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    }
                    ringData.push(fitMeanBeadSignal);
                }
                return ringData;
            }
            data = createRingObjectPlot1(rows);

            function createRingObjectPlot2(rows) {
                const rings = getUniqueRings(rows);
                let ringData = [];
                let ring;
                for (let i = 0; i < rings.length; i++) {
                    ring = rings[i];
                    let col = getColorIndex();
                    ringBeadSignal =  i === 0 ? {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'meanBidSignal', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        //visible: 'legendonly'
                    } : {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'meanBidSignal', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    };
                    ringData.push(ringBeadSignal);

                    linearMeanBidSignal = {
                        type: "scatter",
                        mode: "lines",
                        name: `Linear Fit R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'linearMeanBidSignal', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    }
                    ringData.push(linearMeanBidSignal);
                }
                return ringData;
            }
            data1 = createRingObjectPlot2(rows);

            function createRingObjectPlot3(rows) {
                const rings = getUniqueRings(rows);
                let ringData = [];
                let ring;
                for (let i = 0; i < rings.length; i++) {
                    ring = rings[i];
                    let col = getColorIndex();
                    beadSize = i === 0 ? {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'beadSize', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        //visible: 'legendonly'
                    } : 
                    {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'beadSize', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    };
                    ringData.push(beadSize);

                    fitBeadSize = {
                        type: "scatter",
                        mode: "lines",
                        name: `Quadratic Fit R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'fitBeadSize', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    }
                    ringData.push(fitBeadSize);
                }
                return ringData;
            }
            data2 = createRingObjectPlot3(rows);

            function createRingObjectPlot4(rows) {
                const rings = getUniqueRings(rows);
                let ringData = [];
                let ring;
                for (let i = 0; i < rings.length; i++) {
                    ring = rings[i];
                    let col = getColorIndex();
                    numBeads = i === 0 ? {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'numBeads', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        //visible: 'legendonly'
                    } : {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'numBeads', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    };
                    ringData.push(numBeads);
                }
                return ringData;
            }
            data3 = createRingObjectPlot4(rows);

            function createRingObjectPlot5(rows) {
                const rings = getUniqueRings(rows);
                let ringData = [];
                let ring;
                for (let i = 0; i < rings.length; i++) {
                    ring = rings[i];
                    let col = getColorIndex();
                    meanBidSignalDN2 = i === 0 ? {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'varMeanBidSignal', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        //visible: 'legendonly'
                    } : 
                    {
                        type: "scatter",
                        mode: "lines",
                        name: `Data_R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'varMeanBidSignal', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    };
                    ringData.push(meanBidSignalDN2);

                    varmeanBeadSignal = {
                        type: "scatter",
                        mode: "lines",
                        name: `Linear Fit R${ring}`,
                        line: { color: getColor(col) },
                        y: unpackByRingNum(rows, 'varMeanBidSignalFit', ring.toString()),
                        x: unpackByRingNum(rows, 'fieldPosition', ring.toString()),
                        visible: 'legendonly'
                    }
                    ringData.push(varmeanBeadSignal);
                }
                return ringData;
            }
            data4 = createRingObjectPlot5(rows);

        }
        else {
            meanBeadSignal = {
                type: "scatter",
                mode: "lines",
                name: 'Data',
                line: { color: '#1f77b4' },
                y: unpack(rows, 'meanBidSignal'),
                x: unpack(rows, 'fieldPosition')
            };

            fitMeanBeadSignal = {
                type: "scatter",
                mode: "lines",
                name: 'Gaussian Fit',
                //line: { color: '#1f77b4' },
                y: unpack(rows, 'fitMeanBidSignal'),
                x: unpack(rows, 'fieldPosition')
            };

            beadSize = {
                type: "scatter",
                mode: "lines",
                name: 'Data',
                line: { color: '#1f77b4' },
                y: unpack(rows, 'beadSize'),
                x: unpack(rows, 'fieldPosition')
            };

            fitBeadSize = {
                type: "scatter",
                mode: "lines",
                name: 'Quadratic Fit',
                //line: { color: '#1f77b4' },
                y: unpack(rows, 'fitBeadSize'),
                x: unpack(rows, 'fieldPosition')
            };

            numBeads = {
                type: "scatter",
                mode: "lines",
                name: 'Data',
                line: { color: '#1f77b4' },
                y: unpack(rows, 'numBeads'),
                x: unpack(rows, 'fieldPosition')
            };

            meanBidSignalDN2 = {
                type: "scatter",
                mode: "markers",
                name: 'Data',
                //line: { color: '#1f77b4' },
                y: unpack(rows, 'meanBidSignalDN2'),
                x: unpack(rows, 'meanBidSignal')
            };

            varmeanBeadSignal = {
                type: "scatter",
                mode: "markers",
                name: 'Linear Fit',
                // line: { color: '#1f77b4' },
                y: unpack(rows, 'varMeanBidSignal'),
                x: unpack(rows, 'meanBidSignal')
            };

            data = [meanBeadSignal, fitMeanBeadSignal];
            data2 = [beadSize, fitBeadSize];
            data3 = [numBeads];
            data4 = [varmeanBeadSignal, meanBidSignalDN2];
        }

        var layout = {
            height: 500,   
            title: "Click on Radius to View the data",
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },           
            xaxis: {
                title: "Field Position (mm)",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                dtick: 0.05,               
                automargin: true,
            },
            yaxis: {
                title: "Mean Bead Signal (DN)",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            showlegend: true,
            legend: {
                font: {
                    size: 16,                   
                },
                orientation: "v",
                x: 15,
                y: 1,
            }
        };

        var layout1 = {
            height: 500,         
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },          
            title: "Click on Radius to View the data",           
            xaxis: {
                title: "Field Position (mm)",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                dtick: 0.05,                
                automargin: true,
            },
            yaxis: {
                title: "Mean Bead Signal Linear Fit (DN)",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {                
            },
            showlegend: true,
            legend: {
                font: {
                    size: 16,                    
                },
                orientation: "v",
                x: 15,
                y: 1,
            }
        };

        var layout2 = {
            height: 500,        
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },          
            title: "Click on Radius to View the data",           
            xaxis: {
                title: "Field Position (mm)",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                dtick: 0.05,                
                automargin: true,
            },
            yaxis: {
                title: "Bead Size (px)",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {              
            },
            showlegend: true,
            legend: {
                font: {
                    size: 16,                   
                },
                orientation: "v",
                x: 15,
                y: 1,
            }
        };

        var layout3 = {
            //autosize: true,    
            height: 500,      
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },          
            title: "Click on Radius to View the data",           
            xaxis: {
                title: "Field Position (mm)",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                dtick: 0.05,
              
                automargin: true,
            },
            yaxis: {
                title: "Number of Sample Beads",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
               
            },
            showlegend: true,
            legend: {
                font: {
                    size: 16,
                   
                },
                orientation: "v",
                x: 15,
                y: 1,
            }
        };

        var layout4 = {
            height: 500,
            autosize: true,          
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },           
            title: "Click on Radius to View the data",           
            xaxis: {
                title: "Field Position (mm)",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',              
                automargin: true,
            },
            yaxis: {
                title: "Variance of Background Signal (DN&#x00B2;)",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
               
            },
            showlegend: true,
            legend: {
                font: {
                    size: 16,
                   
                },
                orientation: "v",
                x: 15,
                y: 1,
            }
        };       

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        if (!ringPresent) {
            return { data: data, layout: layout, data3: data3, layout3: layout3, config: config };
        }
        else {
            return { data: data, layout: layout, data1: data1, layout1: layout1, data3: data3, layout3: layout3, config: config };
        }

        //return {data1: data1, layout1: layout1, data2: data2, layout2: layout2, data3: data3, layout3: layout3, data4: data4, layout4: layout4, config:config} ;
    }
    catch (e) { 
        return undefined;
    }
}


export const PlotLaserChart1 = (csv) => {
    try {
        var rows = csv;
        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }

        function time(rows, key) {
            return rows.map(function (row) { return row[key].split(' ')[1]; });
        }

        var laserSummary = {
            type: "scatter",
            mode: "lines",
            name: "Laser vs. Time",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'power_W'),
            x: unpack(rows, 'time')
        };

        var layout5 = {
            plot_bgcolor: "#303030",
            paper_bgcolor: "#303030",
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            width: 900,
            height: 450,
            xaxis: {
                title: "Time",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                tickcolor: 'white',
            },
            yaxis: {
                title: "Laser Power (W)",
                titlefont: {
                    "size": 14
                }
            },
            font: {
                color: 'white'
            },
            showlegend: true,
            legend: {
                font: {
                    size: 16,
                    color: 'white'
                },
                y: 1.3,
                x: 0.45
            }
        };

        var data5 = [laserSummary];

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,
            responsive: true
        };

        return { data: data5, layout: layout5, config: config };
    }
    catch { return {}; }
}

export const PlotLaserChart2 = (csv) => {
    try {
        var rows = csv;
        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }

        function time(rows, key) {
            return rows.map(function (row) { return row[key].split(' ')[1]; });
        }

        var meanPower = {
            type: "scatter",
            mode: "lines",
            name: "Mean Power (W)",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'mean_Power_W'),
            x: unpack(rows, 'time')
        };

        var powerRMS = {
            type: "scatter",
            mode: "lines",
            name: "Power RMS (W)",
            line: { color: '#ff7f0e' },
            y: unpack(rows, 'power_RMS_W'),
            x: unpack(rows, 'time')
        };

        var meanPowerRMS = {
            type: "scatter",
            mode: "lines",
            name: "Power RMS/Mean",
            line: { color: '#d62728' },
            y: unpack(rows, 'power_RMS_Mean'),
            x: unpack(rows, 'time')
        };

        var firstPower = {
            type: "scatter",
            mode: "lines",
            name: "First Power (W)",
            line: { color: '#9467bd' },
            y: unpack(rows, 'first_Power'),
            x: unpack(rows, 'time')
        };

        var layout6 = {
            plot_bgcolor: "#303030",
            paper_bgcolor: "#303030",
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            width: 900,
            height: 450,
            xaxis: {
                title: "Time",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                tickcolor: 'white',
            },
            yaxis: {
                title: "Metric",
                titlefont: {
                    "size": 14
                }
            },
            font: {
                color: 'white'
            },
            showlegend: true,
            legend: {
                font: {
                    size: 16,
                    color: 'white'
                },
                y: 1.3,
                x: 0.45
            }
        };

        var data6 = [meanPower, powerRMS, meanPowerRMS, firstPower];

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,
            responsive: true
        };

        return { data: data6, layout: layout6, config: config };
    }
    catch { return {}; }
}

export const PlotFWHMSummary = (csv) => {
    try {
        var rows = csv;
        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }
        //x = flow
        var MeanFWHM_Field = {
            type: "scatter",
            mode: "lines",
            name: "MeanFWHM_Field",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'meanFWHM_Field'),
            x: unpack(rows, 'flow')
        };

        var MeanFWHM = {
            type: "scatter",
            mode: "lines",
            name: "MeanFWHM",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'meanFWHM'),
            x: unpack(rows, 'flow')
        };

        var FWHM_RMS = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_RMS",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_RMS'),
            x: unpack(rows, 'flow')
        };

        var FWHM_RMS_Percent = {
            type: "scatter",
            mode: "lines",
            name: "MeanFWHM_Field",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_RMS_Percent'),
            x: unpack(rows, 'flow')
        };

        var FWHM_WithinTile_RMS_Percent = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_WithinTile_RMS_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_WithinTile_RMS_Percent'),
            x: unpack(rows, 'flow')
        };

        var FWHM_T2T_RMS_Percent = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_T2T_RMS_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_T2T_RMS_Percent'),
            x: unpack(rows, 'flow')
        };

        var FWHM_CrossScan_RMS_Percent = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_CrossScan_RMS_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_CrossScan_RMS_Percent'),
            x: unpack(rows, 'flow')
        };

        var FWHM_CrossFOV_RMS_Percent = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_CrossFOV_RMS_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_CrossFOV_RMS_Percent'),
            x: unpack(rows, 'flow')
        };

        var FWHM_T2T_RMS = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_T2T_RMS",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_T2T_RMS'),
            x: unpack(rows, 'flow')
        };

        var FWHM_WithinTile_RMS = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_WithinTile_RMS",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_WithinTile_RMS'),
            x: unpack(rows, 'flow')
        };

        var FWHM_CrossFOV_Above_1_45_Spec_Percent = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_CrossFOV_Above_1_45_Spec_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_CrossFOV_Above_1_45_Spec_Percent'),
            x: unpack(rows, 'flow')
        };

        var FWHM_Above_1_45_Spec_Percent = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_Above_1_45_Spec_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_Above_1_45_Spec_Percent'),
            x: unpack(rows, 'flow')
        };

        var FWHM_NaN = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_NaN",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_NaN'),
            x: unpack(rows, 'flow')
        };

        //x = radius
        var MeanFWHM_Field2 = {
            type: "scatter",
            mode: "lines",
            name: "MeanFWHM_Field",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'meanFWHM_Field'),
            x: unpack(rows, 'radius')
        };

        var MeanFWHM2 = {
            type: "scatter",
            mode: "lines",
            name: "MeanFWHM",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'meanFWHM'),
            x: unpack(rows, 'radius')
        };

        var FWHM_RMS2 = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_RMS",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_RMS'),
            x: unpack(rows, 'radius')
        };

        var FWHM_RMS_Percent2 = {
            type: "scatter",
            mode: "lines",
            name: "MeanFWHM_Field",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_RMS_Percent'),
            x: unpack(rows, 'radius')
        };

        var FWHM_WithinTile_RMS_Percent2 = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_WithinTile_RMS_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_WithinTile_RMS_Percent'),
            x: unpack(rows, 'radius')
        };

        var FWHM_T2T_RMS_Percent2 = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_T2T_RMS_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_T2T_RMS_Percent'),
            x: unpack(rows, 'radius')
        };

        var FWHM_CrossScan_RMS_Percent2 = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_CrossScan_RMS_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_CrossScan_RMS_Percent'),
            x: unpack(rows, 'radius')
        };

        var FWHM_CrossFOV_RMS_Percent2 = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_CrossFOV_RMS_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_CrossFOV_RMS_Percent'),
            x: unpack(rows, 'radius')
        };

        var FWHM_T2T_RMS2 = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_T2T_RMS",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_T2T_RMS'),
            x: unpack(rows, 'radius')
        };

        var FWHM_WithinTile_RMS2 = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_WithinTile_RMS",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_WithinTile_RMS'),
            x: unpack(rows, 'radius')
        };

        var FWHM_CrossFOV_Above_1_45_Spec_Percent2 = {
            type: "scatter",
            mode: "lines",
            name: "FWHM_CrossFOV_Above_1_45_Spec_Percent",
            line: { color: '#1f77b4' },
            y: unpack(rows, 'FWHM_CrossFOV_Above_1_45_Spec_Percent'),
            x: unpack(rows, 'radius')
        };
    }
    catch { return {} }
}

export const PlotFWHMSingleCam = (csv1, column, runid) => {
    try {
        var rows = csv1;

        let cname = column;

        var updateCam1 = function (id) {
            let nId = id.split("_");
            if (nId.length > 1) {
                return nId[0] + "_1";
            } else {
                return id;
            }
        }   
        

        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }

        const GetEvery5thFlow = (rows) => {
            let newData = [];

            for(let row of rows){
                if(row.Flow % 5 === 0){
                    newData.push(row);
                }
            }

            return newData.sort((a, b) => a - b);
        };

        const GetFieldCount = (rows) => {
            let updatedRows = GetEvery5thFlow(rows);
            let start = true;
            let fieldCount = 0;
            for (let row of updatedRows) {
                if (start) {
                    fieldCount++;
                    start = false;
                } else {
                    if (parseInt(row.Field) === 0) {
                        break;
                    } else {
                        fieldCount++;
                    }
                }
            }
            return fieldCount;
        }

        const getmeanFWHMFieldData = (rows, key) => {

            const result = [];
            rows.forEach(row => {
                if (parseInt(row["Flow"]) === 1) {
                    if (row["Field"] === key) {
                        result.push(parseFloat(row["MeanFWHMField"]));
                    }
                }
            });
            return result;
        }


        const GetAverageArray = (rows) => {
            let fieldCount = GetFieldCount(rows);
            let averageArray = [];
            for (let i = 0; i < fieldCount; i++) {
                let getMeanFWHMValues = getmeanFWHMFieldData(rows, i);
                let sum = getMeanFWHMValues.reduce((x, y) => x + y, 0);
                averageArray.push(sum / getMeanFWHMValues.length);
            }
            return averageArray;
        }

        var getYValue = function (rows, key) {
            let valY = [];
            let updatedRows = GetEvery5thFlow(rows);
            try {
                let count = 0;
                updatedRows.forEach(x => {
                    if (count === 0) {
                        let dat = parseFloat(x[key]) || 0;
                        valY.push(dat);
                    }
                    else if (count === 15) {
                        count = -1;
                    }
                    count++;
                });
            }
            catch (error) {

            }

            return valY;
        }

        var getRing = function (rows) {
            let iRings = [];
            let updatedRows = GetEvery5thFlow(rows);
            try {
                let pointer = parseInt(updatedRows[0].Flow) || 5;
                let count = 0;
                let j = 5;
                while (count < updatedRows.length) {
                    let x = j.toFixed(4);
                    iRings.push(x);
                    j += .077;
                    if (parseInt(updatedRows[count].Flow) !== pointer) {
                        j = Math.trunc(j);
                        j += 5;
                    }
                    count++;
                }
            }
            catch (error) {

            }

            return iRings;
        }

        const getRingCount = (rows) => {
            try {
                if (rows === null || rows.length === 0) return;
                let updatedRows = GetEvery5thFlow(rows);
                let fieldCount = GetFieldCount(rows);
    
                let totalRingCount = updatedRows.length / fieldCount;
                let lastFlow = updatedRows[updatedRows.length - 1].Flow / 5;

                return Math.floor(totalRingCount / lastFlow);
            } catch (e) {
                console.log(e);
            }
        }

        var xaxis = function (rows) {
            let ringCount = getRingCount(rows);

            var i = 5.01;
            let gap = 0.01;
            let ringFactor = gap * ringCount;
            let difFactor = i - ringFactor;
            let iRings = [];
            try {
                while (i <= rows.length + 1) {
                    iRings.push(parseFloat(i.toFixed(2)));
                    var currentDecimal = parseFloat((i % 5).toFixed(2));
                    if (currentDecimal >= ringFactor) {
                        i += difFactor;
                    } else {
                        i += gap;
                    }
                }
            }
            catch (error) {
            }

            return iRings;
        }

        var updateId = function (id) {
            let nId = id.split("_");
            if (nId.length > 1) {
                return nId[0] + "_1";
            } else {
                return id;
            }
        }

        var removeId = function (id) {
            let rId = id.split("_");
            if (rId.length > 1) {
                return rId[0];
            } else {
                return id;
            }
        }

        var camera1 = {
            type: "scatter",
            mode: "lines",
            name: `Camera 1: ${updateId(runid)} - ${cname}`,
            line: { color: "blue" },
            y: getYValue(rows, cname),
            x: getRing(rows)
        };

        var globalCamera1 = {
            type: "scatter",
            mode: "lines",
            name: `Camera 1: ${updateCam1(runid)} - meanFWHM @ field`,
            line: { color: "blue" },
            y: GetAverageArray(rows),
            x: unpack(rows, "Field")
        };

        var layout = {
            title: `${removeId(runid)}: ${cname} per ring and flow`,
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            //width: 1500,
            height: 650,
            xaxis: {
                title: "Flow x Radius",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                //tickcolor: 'white',
                tickvals: getRing(rows),
                ticktext: xaxis(rows),
                automargin: true,
            },
            yaxis: {
                title: "Metric",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
                //color: 'white'
            },
            showlegend: true,
            legend: {
                x: 0,
                y: -0.4
            },
        };

        var globalLayout = {
            title: `${removeId(runid)}: FWHM Global Profile`,
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            margin: {
                t: 70,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            //width: 1500,
            height: 650,
            xaxis: {
                title: "Field of View",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                //tickcolor: 'white',
                tickvals: unpack(rows, 'Field'),
                ticktext: unpack(rows, 'Field'),
                automargin: true,
            },
            yaxis: {
                title: "FWHM",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
                //color: 'white'
            },
            showlegend: true,
            legend: {
                x: 0,
                y: -0.4
            },
        };

        var data = [camera1];
        var globalData = [globalCamera1];

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,
            responsive: true
        };

        return { data: data, layout: layout, globalData, globalLayout, config: config };
    }
    catch (e) {
        console.log(e);
    }

}

export const PlotFWHMColumnSummary = (csv1, csv2, column, runid) => {
    try {
        var rows = csv1;
        var rows1 = csv2;

        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }

        let cname = column;       
        

        const GetEvery5thFlow = (rows) => {
            let newData = [];

            for(let row of rows){
                if(row.Flow % 5 === 0){
                    newData.push(row);
                }
            }

            return newData.sort((a, b) => a - b);
        };

        const GetFieldCount = (rows) => {

            let start = true;
            let fieldCount = 0;
            for (let row of rows) {
                if (start) {
                    fieldCount++;
                    start = false;
                } else {
                    if (parseInt(row.Field) === 0) {
                        break;
                    } else {
                        fieldCount++;
                    }
                }
            }
            return fieldCount;
        }

        const getmeanFWHMFieldData = (rows, key) => {

            const result = [];
            rows.forEach(row => {
                if (parseInt(row["Flow"]) === 1) {
                    if (row["Field"] === key) {
                        result.push(parseFloat(row["MeanFWHMField"]));
                    }
                }
            });
            return result;
        }


        const GetAverageArray = (rows) => {
            let fieldCount = GetFieldCount(rows);
            let averageArray = [];
            for (let i = 0; i < fieldCount; i++) {
                let getMeanFWHMValues = getmeanFWHMFieldData(rows, i);
                let sum = getMeanFWHMValues.reduce((x, y) => x + y, 0);
                averageArray.push(sum / getMeanFWHMValues.length);
            }
            return averageArray;
        }

        var getYValue = function (rows, key) {
            let valY = [];
            let updatedRows = GetEvery5thFlow(rows);
            try {
                let count = 0;
                updatedRows.forEach(x => {
                    if (count === 0) {
                        let dat = parseFloat(x[key]) || 0;
                        valY.push(dat);
                    }
                    else if (count === 15) {
                        count = -1;
                    }
                    count++;
                });
            }
            catch (error) {

            }

            return valY;
        }

        var getRing = function (rows) {
            let iRings = [];
            let updatedRows = GetEvery5thFlow(rows);
            try {
                let pointer = parseInt(updatedRows[0].Flow) || 5;
                let count = 0;
                let j = 5;
                while (count < updatedRows.length) {
                    let x = j.toFixed(4);
                    iRings.push(x);
                    j += .077;
                    if (parseInt(updatedRows[count].Flow) !== pointer) {
                        j = Math.trunc(j);
                        j += 5;
                    }
                    count++;
                }
            }
            catch (error) {

            }

            return iRings;
        }

        const getRingCount = (rows) => {
            try {
                if (rows === null || rows.length === 0) return;
                let updatedRows = GetEvery5thFlow(rows);
                let fieldCount = GetFieldCount(rows);
                
                if (updatedRows.length === 0) return;

                let lastFlow = updatedRows[updatedRows.length - 1].Flow;

                if (lastFlow === undefined) return; 
                
                lastFlow /= 5;
                let totalRingCount = updatedRows.length / fieldCount;
                return Math.floor(totalRingCount / lastFlow);
            } catch (e) {
                console.log(e);
            }
        }

        var xaxis = function (rows) {
            let ringCount = getRingCount(rows);

            var i = 5.01;
            let gap = 0.01;
            let ringFactor = gap * ringCount;
            let difFactor = i - ringFactor;
            let iRings = [];
            try {
                while (i <= rows.length + 1) {
                    iRings.push(parseFloat(i.toFixed(2)));
                    var currentDecimal = parseFloat((i % 5).toFixed(2));
                    if (currentDecimal >= ringFactor) {
                        i += difFactor;
                    } else {
                        i += gap;
                    }
                }
            }
            catch (error) {
            }

            return iRings;
        }


        var updateCam1 = function (id) {
            let nId = id.split("_");
            if (nId.length > 1) {
                return nId[0] + "_1";
            } else {
                return id;
            }
        }

        var updateCam2 = function (id) {
            let nId = id.split("_");
            if (nId.length > 1) {
                return nId[0] + "_2";
            } else {
                return id;
            }
        }

        var removeId = function (id) {
            let rId = id.split("_");
            if (rId.length > 1) {
                return rId[0];
            } else {
                return id;
            }
        }

        var camera1 = {
            type: "scatter",
            mode: "lines",
            name: `Camera 1: ${updateCam1(runid)} - ${cname}`,
            line: { color: "blue" },
            y: getYValue(rows, cname),
            x: getRing(rows)
        };


        var camera2 = {
            type: "scatter",
            mode: "lines",
            name: `Camera 2: ${updateCam2(runid)} - ${cname}`,
            line: { color: "red" },
            y: getYValue(rows1, cname),
            x: getRing(rows1)
        };

        var globalCamera1 = {
            type: "scatter",
            mode: "lines",
            name: `Camera 1: ${updateCam1(runid)} - meanFWHM @ field`,
            line: { color: "blue" },
            y: GetAverageArray(rows),
            x: unpack(rows, "Field")
        };

        var globalCamera2 = {
            type: "scatter",
            mode: "lines",
            name: `Camera 2: ${updateCam2(runid)} - meanFWHM @ field`,
            line: { color: "red" },
            y: GetAverageArray(rows1),
            x: unpack(rows1, "Field")
        };

        var layout = {
            title: `${removeId(runid)}: ${cname} per ring and flow`,
            margin: {
                t: 70,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            //width: 1500,
            height: 650,
            xaxis: {
                title: "Flow x Radius",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                tickvals: rows.length > rows1.length ? getRing(rows) : getRing(rows1),
                ticktext: rows.length > rows1.length ? xaxis(rows) : xaxis(rows1),
                automargin: true,
            },
            yaxis: {
                title: "Metric",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
                //color: 'white'
            },
            showlegend: true,
            legend: {
                x: 0,
                y: -0.4
            },
        };

        var globalLayout = {
            title: `${removeId(runid)}: FWHM Global Profile`,
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            margin: {
                t: 70,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            //width: 1500,
            height: 650,
            xaxis: {
                title: "Field of View",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                //tickcolor: 'white',
                tickvals: unpack(rows, 'Field'),
                ticktext: unpack(rows, 'Field'),
                automargin: true,
            },
            yaxis: {
                title: "FWHM",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
                //color: 'white'
            },
            showlegend: true,
            legend: {
                x: 0,
                y: -0.4
            },
        };

        var data = [camera1, camera2];
        var globalData = [globalCamera1, globalCamera2];

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,
            responsive: true
        };
        
        return { data: data, layout: layout, globalData, globalLayout, config: config };
    }
    catch (e) {
        //console.log(e);
    }

}

export const ComparePlotFWHMColumnSummary = (csv1, csv2, compareData, column, runid, chart) => {
    try {
        let rows = csv1;
        let rows1 = [];

        var data = [];
        var globalData = [];
    
        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }
    
        if (typeof csv2 !== "undefined")
            rows1 = csv2;
    
    
        let cname = column;    
      
        const GetEvery5thFlow = (rows) => {
            let newData = [];

            for(let row of rows){
                if(row.Flow % 5 === 0){
                    newData.push(row);
                }
            }

            return newData.sort((a, b) => a - b);
        };

        const GetFieldCount = (rows) => {
            let updatedRows = GetEvery5thFlow(rows);
            let start = true;
            let fieldCount = 0;
            for (let row of updatedRows) {
                if (start) {
                    fieldCount++;
                    start = false;
                } else {
                    if (parseInt(row.Field) === 0) {
                        break;
                    } else {
                        fieldCount++;
                    }
                }
            }
            return fieldCount;
        }

        const getmeanFWHMFieldData = (rows, key) => {

            const result = [];
            rows.forEach(row => {
                if (parseInt(row["Flow"]) === 1) {
                    if (row["Field"] === key) {
                        result.push(parseFloat(row["MeanFWHMField"]));
                    }
                }
            });
            return result;
        }


        const GetAverageArray = (rows) => {
            let fieldCount = GetFieldCount(rows);
            let averageArray = [];
            for (let i = 0; i < fieldCount; i++) {
                let getMeanFWHMValues = getmeanFWHMFieldData(rows, i);
                let sum = getMeanFWHMValues.reduce((x, y) => x + y, 0);
                averageArray.push(sum / getMeanFWHMValues.length);
            }
            return averageArray;
        }

        var getYValue = function (rows, key) {
            let valY = [];
            let updatedRows = GetEvery5thFlow(rows);
            try {
                let count = 0;
                updatedRows.forEach(x => {
                    if (count === 0) {
                        let dat = parseFloat(x[key]) || 0;
                        valY.push(dat);
                    }
                    else if (count === 15) {
                        count = -1;
                    }
                    count++;
                });
            }
            catch (error) {

            }

            return valY;
        }

        var getRing = function (rows) {
            let iRings = [];
            let updatedRows = GetEvery5thFlow(rows);
            try {
                let pointer = parseInt(updatedRows[0].Flow) || 5;
                let count = 0;
                let j = 5;
                while (count < updatedRows.length) {
                    let x = j.toFixed(4);
                    iRings.push(x);
                    j += .077;
                    if (parseInt(updatedRows[count].Flow) !== pointer) {
                        j = Math.trunc(j);
                        j += 5;
                    }
                    count++;
                }
            }
            catch (error) {

            }

            return iRings;
        }

        const getRingCount = (rows) => {
            try {
                if (rows === null || rows.length === 0) return;
                let updatedRows = GetEvery5thFlow(rows);
                let fieldCount = GetFieldCount(rows);
    
                let totalRingCount = updatedRows.length / fieldCount;
                let lastFlow = updatedRows[updatedRows.length - 1].Flow / 5;

                return Math.floor(totalRingCount / lastFlow);
            } catch (e) {
                console.log(e);
            }
        }

        var xaxis = function (rows) {
            let ringCount = getRingCount(rows);

            var i = 5.01;
            let gap = 0.01;
            let ringFactor = gap * ringCount;
            let difFactor = i - ringFactor;
            let iRings = [];
            try {
                while (i <= rows.length + 1) {
                    iRings.push(parseFloat(i.toFixed(2)));
                    var currentDecimal = parseFloat((i % 5).toFixed(2));
                    if (currentDecimal >= ringFactor) {
                        i += difFactor;
                    } else {
                        i += gap;
                    }
                }
            }
            catch (error) {
            }

            return iRings;
        }
    
        var updateId = function (id) {
            if (id !== undefined) {
                let nId = id.split("_");
                if (nId.length > 1) {
                    return nId[0] + "_1";
                } else {
                    return id;
                }
            }
            else return id;            
        }
    
        var updateCam2 = function (id) {
            if (id !== undefined) {
                let nId = id.split("_");
                if (nId.length > 1) {
                    return nId[0] + "_2";
                } else {
                    return id;
                }
            }
            else return id;            
        }
    
        var removeId = function (id) {
            if (id !== undefined) {
                let rId = id.split("_");
                if (rId.length > 1) {
                    return rId[0];
                } else {
                    return id;
                }
            }
            else return id;           
        }
    
        var camera1 = {
            type: "scatter",
            mode: "lines",
            name: `Camera 1: ${updateId(runid)} - ${cname}`,
            line: { color: "blue" },
            y: getYValue(rows, cname),
            x: getRing(rows)
        };
    
        var camera2;
    
        if (rows1.length > 0) {
            camera2 = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2: ${updateCam2(runid)} - ${cname}`,
                line: { color: "red" },
                y: getYValue(rows1, cname),
                x: getRing(rows1)
            };
        }
    
        if (compareData.length === 1) {
            var camera1a;
            var camera2a;
    
            var rows2 = compareData[0].compare1;
            camera1a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1a: ${updateId(compareData[0].runid)} - ${cname}`,
                line: { color: "orange" },
                y: getYValue(rows2, cname),
                x: getRing(rows2)
            };
    
            //if (rows1.length > 0) {
                var rows3 = compareData[0].compare2;
                camera2a = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2a: ${updateCam2(compareData[0].runid)} - ${cname}`,
                    line: { color: "green" },
                    y: getYValue(rows3, cname),
                    x: getRing(rows3)
                };
           // }
            if (rows1.length > 0)
                data = [camera1, camera2, camera1a, camera2a];
            else
                data = [camera1, camera1a, camera2a];
    
        }
        else if (compareData.length === 2) {
            var camera1a;
            var camera2a;
            var camera1b;
            var camera2b;
    
            var rows2 = compareData[0].compare1;
            camera1a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1a: ${updateId(compareData[0].runid)} - ${cname}`,
                line: { color: "orange" },
                y: getYValue(rows2, cname),
                x: getRing(rows2)
            };
    
            //if (rows1.length > 0) {
                var rows3 = compareData[0].compare2;
                camera2a = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2a: ${updateCam2(compareData[0].runid)} - ${cname}`,
                    line: { color: "green" },
                    y: getYValue(rows3, cname),
                    x: getRing(rows3)
                };
            //}
    
            var rows4 = compareData[1].compare1;
            camera1b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1b: ${updateId(compareData[1].runid)} - ${cname}`,
                line: { color: "purple" },
                y: getYValue(rows4, cname),
                x: getRing(rows4)
            };
    
            //if (rows1.length > 0) {
                var rows5 = compareData[1].compare2;
                camera2b = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2b: ${updateCam2(compareData[1].runid)} - ${cname}`,
                    line: { color: "yellow" },
                    y: getYValue(rows5, cname),
                    x: getRing(rows5)
                };
            //}
    
            if (rows1.length > 0)
                data = [camera1, camera2, camera1a, camera2a, camera1b, camera2b];
            else
                data = [camera1, camera1a, camera2a, camera1b, camera2b];
    
        } else if (compareData.length === 3) {
            var camera1a;
            var camera2a;
            var camera1b;
            var camera2b;
            var camera1c;
            var camera2c;
    
            var rows2 = compareData[0].compare1;
            camera1a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1a: ${updateId(compareData[0].runid)} - ${cname}`,
                line: { color: "orange" },
                y: getYValue(rows2, cname),
                x: getRing(rows2)
            };
    
            //if (rows1.length > 0) {
                var rows3 = compareData[0].compare2;
                camera2a = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2a: ${updateCam2(compareData[0].runid)} - ${cname}`,
                    line: { color: "green" },
                    y: getYValue(rows3, cname),
                    x: getRing(rows3)
                };
            //}
    
            var rows4 = compareData[1].compare1;
            camera1b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1b: ${updateId(compareData[1].runid)} - ${cname}`,
                line: { color: "purple" },
                y: getYValue(rows4, cname),
                x: getRing(rows4)
            };
    
            //if (rows1.length > 0) {
                var rows5 = compareData[1].compare2;
                camera2b = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2b: ${updateCam2(compareData[1].runid)} - ${cname}`,
                    line: { color: "yellow" },
                    y: getYValue(rows5, cname),
                    x: getRing(rows5)
                };
            //}
    
            var rows6 = compareData[2].compare1;
            camera1c = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1c: ${updateId(compareData[2].runid)} - ${cname}`,
                line: { color: "pink" },
                y: getYValue(rows6, cname),
                x: getRing(rows6)
            };
    
            //if (rows1.length > 0) {
                var  rows7 = compareData[2].compare2;
                camera2c = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2c: ${updateCam2(compareData[2].runid)} - ${cname}`,
                    line: { color: "white" },
                    y: getYValue(rows7, cname),
                    x: getRing(rows7)
                };
            //}
    
            if (rows1.length > 0)
                data = [camera1, camera2, camera1a, camera2a, camera1b, camera2b, camera1c, camera2c];
            else
                data = [camera1, camera1a, camera2a, camera1b, camera2b, camera1c, camera2c];
    
        } else if (compareData.length === 4) {
            var camera1a;
            var camera2a;
            var camera1b;
            var camera2b;
            var camera1c;
            var camera2c;
            var camera1d;
            var camera2d;
    
            var rows2 = compareData[0].compare1;
            camera1a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1a: ${updateId(compareData[0].runid)} - ${cname}`,
                line: { color: "orange" },
                y: getYValue(rows2, cname),
                x: getRing(rows2)
            };
    
            //if (rows1.length > 0) {
                var rows3 = compareData[0].compare2;
                camera2a = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2a: ${updateCam2(compareData[0].runid)} - ${cname}`,
                    line: { color: "green" },
                    y: getYValue(rows3, cname),
                    x: getRing(rows3)
                };
            //}
    
            var rows4 = compareData[1].compare1;
            camera1b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1b: ${updateId(compareData[1].runid)} - ${cname}`,
                line: { color: "purple" },
                y: getYValue(rows4, cname),
                x: getRing(rows4)
            };
    
            //if (rows1.length > 0) {
                var rows5 = compareData[1].compare2;
                camera2b = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2b: ${updateCam2(compareData[1].runid)} - ${cname}`,
                    line: { color: "yellow" },
                    y: getYValue(rows5, cname),
                    x: getRing(rows5)
                };
            //}
    
            var rows6 = compareData[2].compare1;
            camera1c = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1c: ${updateId(compareData[2].runid)} - ${cname}`,
                line: { color: "pink" },
                y: getYValue(rows6, cname),
                x: getRing(rows6)
            };
    
            //if (rows1.length > 0) {
                var rows7 = compareData[2].compare2;
                camera2c = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2c: ${updateCam2(compareData[2].runid)} - ${cname}`,
                    line: { color: "white" },
                    y: getYValue(rows7, cname),
                    x: getRing(rows7)
                };
            //}
    
            var rows8 = compareData[3].compare1;
            camera1d = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1d: ${updateId(compareData[3].runid)} - ${cname}`,
                line: { color: "brown" },
                y: getYValue(rows8, cname),
                x: getRing(rows8)
            };
    
            //if (rows1.length > 0) {
                var rows9 = compareData[3].compare2;
                camera2d = {
                    type: "scatter",
                    mode: "lines",
                    name: `Camera 2d: ${updateCam2(compareData[3].runid)} - ${cname}`,
                    line: { color: "black" },
                    y: getYValue(rows9, cname),
                    x: getRing(rows9)
                };
            //}
    
            if (rows1.length > 0)
                data = [camera1, camera2, camera1a, camera2a, camera1b, camera2b, camera1c, camera2c, camera1d, camera2d];
            else
                data = [camera1, camera1a, camera2a, camera1b, camera2b, camera1c, camera2c, camera1d, camera2d];
        }   
        
    
        var globalCamera1 = {
            type: "scatter",
            mode: "lines",
            name: `Camera 1: ${updateId(runid)} - meanFWHM @ field`,
            line: { color: "blue" },
            y: GetAverageArray(rows),
            x: unpack(rows, "Field")
        };
    
        var globalCamera2;
    
        if (rows1.length > 0) {
            globalCamera2 = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2: ${updateCam2(runid)} - meanFWHM @ field`,
                line: { color: "blue" },
                y: GetAverageArray(rows1),
                x: unpack(rows1, "Field")
            };
        }   
        
    
        if (compareData.length === 1) {
            var globalCamera1a;
            var globalCamera2a;
    
            var rows2 = compareData[0].compare1;
            globalCamera1a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1a: ${updateId(compareData[0].runid)} - meanFWHM @ field`,
                line: { color: "orange" },
                y: GetAverageArray(rows2),
                x: unpack(rows2, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows3 = compareData[0].compare2;
            globalCamera2a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2a: ${updateCam2(compareData[0].runid)} - meanFWHM @ field`,
                line: { color: "green" },
                y: GetAverageArray(rows3),
                x: unpack(rows3, "Field")
            };
            // }
            if (rows1.length > 0)
                globalData = [globalCamera1, globalCamera2, globalCamera1a, globalCamera2a];
            else
                globalData = [globalCamera1, globalCamera1a, globalCamera2a];
    
        }
        else if (compareData.length === 2) {
            var globalCamera1a;
            var globalCamera2a;
            var globalCamera1b;
            var globalCamera2b;
    
            var rows2 = compareData[0].compare1;
            globalCamera1a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1a: ${updateId(compareData[0].runid)} - meanFWHM @ field`,
                line: { color: "orange" },
                y: GetAverageArray(rows2),
                x: unpack(rows2, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows3 = compareData[0].compare2;
            globalCamera2a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2a: ${updateCam2(compareData[0].runid)} - meanFWHM @ field`,
                line: { color: "green" },
                y: GetAverageArray(rows3),
                x: unpack(rows3, "Field")
            };
            //}
    
            var rows4 = compareData[1].compare1;
            globalCamera1b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1b: ${updateId(compareData[1].runid)} - meanFWHM @ field`,
                line: { color: "purple" },
                y: GetAverageArray(rows4),
                x: unpack(rows4, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows5 = compareData[1].compare2;
            globalCamera2b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2b: ${updateCam2(compareData[1].runid)} - meanFWHM @ field`,
                line: { color: "yellow" },
                y: GetAverageArray(rows5),
                x: unpack(rows5, "Field")
            };
            //}
    
            if (rows1.length > 0)
                globalData = [globalCamera1, globalCamera2, globalCamera1a, globalCamera2a, globalCamera1b, globalCamera2b];
            else
                globalData = [globalCamera1, globalCamera1a, globalCamera2a, globalCamera1b, globalCamera2b];
    
        } else if (compareData.length === 3) {
            var globalCamera1a;
            var globalCamera2a;
            var globalCamera1b;
            var globalCamera2b;
            var globalCamera1c;
            var globalCamera2c;
    
            var rows2 = compareData[0].compare1;
            globalCamera1a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1a: ${updateId(compareData[0].runid)} - meanFWHM @ field`,
                line: { color: "orange" },
                y: GetAverageArray(rows2),
                x: unpack(rows2, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows3 = compareData[0].compare2;
            globalCamera2a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2a: ${updateCam2(compareData[0].runid)} - meanFWHM @ field`,
                line: { color: "green" },
                y: GetAverageArray(rows3),
                x: unpack(rows3, "Field")
            };
            //}
    
            var rows4 = compareData[1].compare1;
            globalCamera1b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1b: ${updateId(compareData[1].runid)} - meanFWHM @ field`,
                line: { color: "purple" },
                y: GetAverageArray(rows4),
                x: unpack(rows4, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows5 = compareData[1].compare2;
            globalCamera2b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2b: ${updateCam2(compareData[1].runid)} - meanFWHM @ field`,
                line: { color: "yellow" },
                y: GetAverageArray(rows5),
                x: unpack(rows5, "Field")
            };
            //}
    
            var rows6 = compareData[2].compare1;
            globalCamera1c = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1c: ${updateId(compareData[2].runid)} - meanFWHM @ field`,
                line: { color: "pink" },
                y: GetAverageArray(rows6),
                x: unpack(rows6, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows7 = compareData[2].compare2;
            globalCamera2c = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2c: ${updateCam2(compareData[2].runid)} - meanFWHM @ field`,
                line: { color: "white" },
                y: GetAverageArray(rows7),
                x: unpack(rows7, "Field")
            };
            //}
    
            if (rows1.length > 0)
                globalData = [globalCamera1, globalCamera2, globalCamera1a, globalCamera2a, globalCamera1b, globalCamera2b, globalCamera1c, globalCamera2c];
            else
                globalData = [globalCamera1, globalCamera1a, globalCamera2a, globalCamera1b, globalCamera2b, globalCamera1c, globalCamera2c];
    
        } else if (compareData.length === 4) {
            var globalCamera1a;
            var globalCamera2a;
            var globalCamera1b;
            var globalCamera2b;
            var globalCamera1c;
            var globalCamera2c;
            var globalCamera1d;
            var globalCamera2d;
    
            var rows2 = compareData[0].compare1;
            globalCamera1a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1a: ${updateId(compareData[0].runid)} - meanFWHM @ field`,
                line: { color: "orange" },
                y: GetAverageArray(rows2),
                x: unpack(rows2, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows3 = compareData[0].compare2;
            globalCamera2a = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2a: ${updateCam2(compareData[0].runid)} - meanFWHM @ field`,
                line: { color: "green" },
                y: GetAverageArray(rows3),
                x: unpack(rows3, "Field")
            };
            //}
    
            var rows4 = compareData[1].compare1;
            globalCamera1b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1b: ${updateId(compareData[1].runid)} - meanFWHM @ field`,
                line: { color: "purple" },
                y: GetAverageArray(rows4),
                x: unpack(rows4, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows5 = compareData[1].compare2;
            globalCamera2b = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2b: ${updateCam2(compareData[1].runid)} - meanFWHM @ field`,
                line: { color: "yellow" },
                y: GetAverageArray(rows5),
                x: unpack(rows5, "Field")
            };
            //}
    
            var rows6 = compareData[2].compare1;
            globalCamera1c = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1c: ${updateId(compareData[2].runid)} - meanFWHM @ field`,
                line: { color: "pink" },
                y: GetAverageArray(rows6),
                x: unpack(rows6, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows7 = compareData[2].compare2;
            globalCamera2c = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2c: ${updateCam2(compareData[2].runid)} - meanFWHM @ field`,
                line: { color: "white" },
                y: GetAverageArray(rows7),
                x: unpack(rows7, "Field")
            };
            //}
    
            var rows8 = compareData[3].compare1;
            globalCamera1d = {
                type: "scatter",
                mode: "lines",
                name: `Camera 1d: ${updateId(compareData[3].runid)} - meanFWHM @ field`,
                line: { color: "brown" },
                y: GetAverageArray(rows8),
                x: unpack(rows8, "Field")
            };
    
            //if (rows1.length > 0) {
                var rows9 = compareData[3].compare2;
            globalCamera2d = {
                type: "scatter",
                mode: "lines",
                name: `Camera 2d: ${updateCam2(compareData[3].runid)} - meanFWHM @ field`,
                line: { color: "black" },
                y: GetAverageArray(rows9),
                x: unpack(rows9, "Field")
            };
            //}
    
            if (rows1.length > 0)
                globalData = [globalCamera1, globalCamera2, globalCamera1a, globalCamera2a, globalCamera1b, globalCamera2b, globalCamera1c, globalCamera2c, globalCamera1d, globalCamera2d];
            else
                globalData = [globalCamera1, globalCamera1a, globalCamera2a, globalCamera1b, globalCamera2b, globalCamera1c, globalCamera2c, globalCamera1d, globalCamera2d];
        }
    
        var layout = {
            title: `${removeId(runid)}: ${cname} per ring and flow`,
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            //width: 1500,
            height: 650,
            xaxis: {
                title: "Flow x Radius",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                tickvals: rows.length > rows1.length ? getRing(rows) : getRing(rows1),
                ticktext: rows.length > rows1.length ? xaxis(rows) : xaxis(rows1),
                //tickcolor: 'white',
                automargin: true,
            },
            yaxis: {
                title: "Metric",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
                //color: 'white'
            },
            showlegend: true,
            legend: {
                x: 0,
                y: -0.4
            },
        };
    
        var globalLayout = {
            title: `${removeId(runid)}: FWHM Global Profile`,
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            margin: {
                t: 70,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            //width: 1500,
            height: 650,
            xaxis: {
                title: "Field of View",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                //tickcolor: 'white',
                tickvals: unpack(rows, 'Field'),
                ticktext: unpack(rows, 'Field'),
                automargin: true,
            },
            yaxis: {
                title: "FWHM",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
                //color: 'white'
            },
            showlegend: true,
            legend: {
                x: 0,
                y: -0.4
            },
        };   
    
        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo'],
            displaylogo: false,
            displayModeBar: false,
            responsive: true
        };  
    
        if (chart === 'FWHMChart') return { data: data, layout: layout, config: config };
        else return { data: globalData, layout: globalLayout, config: config };
    }
    catch {       
    }

}