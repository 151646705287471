import React, {useState, useEffect} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { setCustomStorageValues, deleteRunsApply, getRunInfoData, getDiskSpacData, calculcateStorageFreeSpace, setAlertStorageRun } from "../actions/index";
import Stack from '@mui/material/Stack';
import {
    Typography,
    IconButton,
    Button,
    OutlinedInput,
    Dialog,
    DialogTitle, 
    DialogContent, 
    DialogActions
  } from '@material-ui/core';
  import PopupMessage from '../popUp/popUpMessage';

const Storage = (props) => {
    const { handleClose, isOpen } = props;
    const custom_storage = useSelector(state => state.custom_storage);   
    const jwt = useSelector(state => state.jwt);   
    const delete_run_request_status = useSelector(state => state.delete_run_request_status);   
    const calculated_storage_free_space = useSelector(state => state.calculated_storage_free_space);   
    const diskspace = useSelector(state => state.diskspace);   
    const run_info = useSelector(state => state.run_info);   
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        alertRun: custom_storage.alertRun,
        allRuns: custom_storage.allRuns,
        baseCallingQuality: custom_storage.baseCallingQuality,
        signalQualityWafer:custom_storage.signalQualityWafer,
        systemDebug: custom_storage.systemDebug,
        ampRuns: custom_storage.ampRuns,
    });
    const [message, setMessage] = useState('');
    const [runInfoCount, setRunInfoCount] = useState(undefined);
    const [diskSpace, setDiskSpace] = useState(undefined);
    const [lastCleanupDate, setLastCleanupDate] = useState(localStorage.getItem('lastCleanupDate'));
    const [calcFreeSpace, setCalcFreeSpace] = useState(undefined);
    const [PopUpOpen, setPopUpOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [storedValues, setStoredValues] = useState({});
  
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: parseInt(value) });
    };
    
    const handleDefaultClick = () => {
        setMessage('');
        setConfirmationMessage(false);
        try {
            const defaultValues = {
                alertRun: 50,
                allRuns: 18,
                baseCallingQuality: 6,
                signalQualityWafer: 6,
                systemDebug: 3,
                ampRuns: 3
            };
            setValues(defaultValues);
            dispatch(setCustomStorageValues(defaultValues));
            const stringify = JSON.stringify(defaultValues);
            dispatch(calculcateStorageFreeSpace(stringify, jwt));
            if (diskspace < defaultValues.alertRun) {
                dispatch(setAlertStorageRun(true));
            } else {
                dispatch(setAlertStorageRun(false));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleApplyClick = () => {
        setMessage('');
        setConfirmationMessage(false);
        try {
            const updatedSettings = {
                alertRun: parseInt(values.alertRun),
                allRuns: parseInt(values.allRuns),
                baseCallingQuality: parseInt(values.baseCallingQuality),
                signalQualityWafer: parseInt(values.signalQualityWafer),
                systemDebug: parseInt(values.systemDebug),
                ampRuns: parseInt(values.ampRuns),
            };
    
            setValues(updatedSettings);
            dispatch(setCustomStorageValues(updatedSettings));
            
            const stringify = JSON.stringify(values);
            dispatch(calculcateStorageFreeSpace(stringify, jwt));
            if (diskspace < updatedSettings.alertRun) {
                dispatch(setAlertStorageRun(true));
            } else {
                dispatch(setAlertStorageRun(false));
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleDeleteClick = async () => {
        try {
            const updatedSettings = {
                alertRun: parseInt(values.alertRun),
                allRuns: parseInt(values.allRuns),
                baseCallingQuality: parseInt(values.baseCallingQuality),
                signalQualityWafer: parseInt(values.signalQualityWafer),
                systemDebug: parseInt(values.systemDebug),
                ampRuns: parseInt(values.ampRuns),
            };
    
            const currentDate = new Date().toLocaleString();
            setLastCleanupDate(currentDate);
            localStorage.setItem('lastCleanupDate', currentDate);
    
            setConfirmationMessage(true);
            setMessage('Data for the specified period will be permanently deleted. Press OK to start deleting or Cancel.');
            setValues(updatedSettings)
            dispatch(setCustomStorageValues(updatedSettings));
            setPopUpOpen(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleConfirmationOk = async () => {
        try {
            dispatch(deleteRunsApply(values, jwt));
            dispatch(getRunInfoData(jwt));
            dispatch(getDiskSpacData(jwt));
            setMessage('Deleting data will resume in the background.');
        } catch (error) {
            console.error('Error deleting data:', error);
            setMessage('Error deleting data:', error);
        }
    
        //setPopUpOpen(false);
        setConfirmationMessage(false);
    };

    const handleConfirmationCancel = () => {
        setConfirmationMessage(false);
        setMessage('');
        setPopUpOpen(false);
        //handleClose();
    };

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem('customStorageValues')) || {};
        setValues({
            alertRun: storedValues.alertRun || custom_storage.alertRun,
            allRuns: storedValues.allRuns || custom_storage.allRuns,
            baseCallingQuality: storedValues.baseCallingQuality || custom_storage.baseCallingQuality,
            signalQualityWafer: storedValues.signalQualityWafer || custom_storage.signalQualityWafer,
            systemDebug: storedValues.systemDebug || custom_storage.systemDebug,
            ampRuns: storedValues.ampRuns || custom_storage.ampRuns,
        });
    }, []);

    useEffect(() => {
        const storedValues = JSON.parse(localStorage.getItem('customStorageValues')) || {};
        if (storedValues.alertRun > diskspace) {
            dispatch(setAlertStorageRun(true));
        } else {
            dispatch(setAlertStorageRun(false));
        }
    }, [diskspace]);
    

    // Save values to local storage whenever values change
    useEffect(() => {
        localStorage.setItem('customStorageValues', JSON.stringify(values));
    }, [values]);

    useEffect(() => {
        const storedValuesFromLocalStorage = JSON.parse(localStorage.getItem('customStorageValues')) || {};
        setStoredValues(storedValuesFromLocalStorage);
    }, []);

    useEffect(() => {
        if(run_info !== undefined) setRunInfoCount(run_info);
    }, [run_info]);

    useEffect(() => {
        if(diskspace !== undefined) setDiskSpace(diskspace);
    }, [diskspace]);

    useEffect(() => {
        if(calculated_storage_free_space !== undefined) setCalcFreeSpace(calculated_storage_free_space);
    }, [calculated_storage_free_space]);

    useEffect(() => {
        if(delete_run_request_status) setDeleteStatus(delete_run_request_status);
        const successMessage = sessionStorage.getItem('delete_success_message');
        if (successMessage) {
            setMessage(successMessage);
            // Clear the success message after displaying
            sessionStorage.removeItem('delete_success_message');
            dispatch(getRunInfoData(jwt));
            dispatch(getDiskSpacData(jwt));
        }
    }, [delete_run_request_status]);

    const useStyles = makeStyles((theme) => ({
        dialogWrapper: {
            padding: theme.spacing(2),
            position: 'absolute',
            top: theme.spacing(2),
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        closeIcon: {
            marginLeft: 'auto',
        },
        storagesContainer: {
            display: 'flex',
            justifyContent: 'space-evenly', 
            gap: 5, 
            margin: '20px 0px',
        },
        alertContainer: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px 0px',
            overflow: 'hidden',
          },
        inputContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '15px 0px',
            //overflow: 'hidden',
        },
        outlineInput: {
            height: 20,
            width: 50,
            marginLeft: 10,
            marginRight: 10,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
            overflow: 'hidden',
        },
        button: {
            margin: theme.spacing(1),
            background: 'linear-gradient(113.96deg, #189AB4 0%, #006096 100%)',
            border: '0.5px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            color: 'white',
            width: 150,
            '&:disabled': {
                background: '#f0f0f0', // Change this to the desired disabled background color
                color: '#666666', // Change this to the desired disabled text color
            },
        },
        deleteButton: {
            margin: theme.spacing(1),
            background: 'linear-gradient(98.3deg, rgb(128, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%)',
            border: '0.5px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '4px',
            color: 'white',
            width: 150,
            '&:disabled': {
                background: '#f0f0f0', // Change this to the desired disabled background color
                color: '#666666', // Change this to the desired disabled text color
            },
        }
    }));

    const classes = useStyles();

    return (
        <>
        <Dialog disableEnforceFocus onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen} fullWidth maxWidth={'md'} classes={{paper: classes.dialogWrapper}} BackdropProps={Boolean(deleteStatus) ? { onClick: () => {} } : {}}>
        <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Storage Management</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>
        </DialogTitle>
        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 6 }}>
            <Typography>Stored Runs: {runInfoCount}</Typography>
            <Typography>Available Storage (runs): {diskSpace}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 6 }}>
            <Typography>Storage to be freed with current rules (runs): {calcFreeSpace}</Typography>
            <Typography>Last clean-up completed at: {lastCleanupDate}</Typography>
        </div> */}
        <div style={{ display: 'grid', gridTemplateRows: 'repeat(2, 1fr)', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', marginBottom: 6 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Stored Runs: {runInfoCount}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Available Storage (runs): {diskSpace}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Storage to be freed with current rules (runs): {calcFreeSpace}</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>Last clean-up completed at: {lastCleanupDate}</Typography>
            </div>
        </div>
          <DialogContent dividers style={{overflow: 'hidden', margin: 0}}>
            <div className={classes.inputContainer}>
                <Typography>Alert when available storage is below:</Typography>
                <OutlinedInput
                    id="alertRun"
                    name='alertRun'
                    type="number"
                    value={values.alertRun}
                    onChange={handleInputChange}
                    className={classes.outlineInput}
                    disabled={Boolean(delete_run_request_status)}
                />
                <Typography>runs</Typography>
            </div>
            </DialogContent>
            <DialogContent dividers style={{overflow: 'hidden', margin: 0}}>
                <Stack spacing={1} margin={1}>
                    <div className={classes.inputContainer}>
                        <Typography>Remove all data for runs older than:</Typography>
                        <OutlinedInput
                            id="allRuns"
                            name='allRuns'
                            type="number"
                            value={values.allRuns}
                            onChange={handleInputChange}
                            className={classes.outlineInput}
                            disabled={Boolean(delete_run_request_status)}
                        />
                        <Typography>months</Typography>
                    </div>
                    <div className={classes.inputContainer}>
                        <Typography>Remove base-calling quality data for runs older than:</Typography>
                        <OutlinedInput
                            id="baseCallingQuality"
                            name='baseCallingQuality'
                            type="number"
                            value={values.baseCallingQuality}
                            onChange={handleInputChange}
                            className={classes.outlineInput}
                            disabled={Boolean(delete_run_request_status)}
                        />
                        <Typography>months</Typography>
                    </div>
                    <div className={classes.inputContainer}>
                        <Typography>Remove signal quality and wafer maps data for runs older than:</Typography>
                        <OutlinedInput
                            id="signalQualityWafer"
                            name='signalQualityWafer'
                            type="number"
                            value={values.signalQualityWafer}
                            onChange={handleInputChange}
                            className={classes.outlineInput}
                            disabled={Boolean(delete_run_request_status)}
                        />
                        <Typography>months</Typography>
                    </div>
                    <div className={classes.inputContainer}>
                        <Typography>Remove system debug data for runs older than:</Typography>
                        <OutlinedInput
                            id="systemDebug"
                            name='systemDebug'
                            type="number"
                            value={values.systemDebug}
                            onChange={handleInputChange}
                            className={classes.outlineInput}
                            disabled={Boolean(delete_run_request_status)}
                        />
                        <Typography>months</Typography>
                    </div>
                    <div className={classes.inputContainer}>
                        <Typography>Remove amp runs older than:</Typography>
                        <OutlinedInput
                            id="ampRuns"
                            name='ampRuns'
                            type="number"
                            value={values.ampRuns}
                            onChange={handleInputChange}
                            className={classes.outlineInput}
                            disabled={Boolean(delete_run_request_status)}
                        />
                        <Typography>months</Typography>
                    </div>
                </Stack> 
            </DialogContent>
            <DialogContent dividers style={{overflow: 'hidden', margin: 0}}>
                <div className={classes.buttonContainer}>
                  <DialogActions>
                    <Button autoFocus onClick={handleDefaultClick} color="primary" className={classes.button} disabled={Boolean(delete_run_request_status)}>
                      Default
                    </Button>
                    <Button autoFocus onClick={handleApplyClick} color="primary" className={classes.button} disabled={Boolean(delete_run_request_status)}>
                      Apply
                    </Button>
                  </DialogActions>
                </div>
            </DialogContent>
            <div className={classes.buttonContainer}>
                <DialogActions>
                <Button className={classes.deleteButton} autoFocus onClick={handleDeleteClick} color="primary" disabled={Boolean(delete_run_request_status)}>
                    Delete
                </Button>
                </DialogActions>
            </div>
        </Dialog>
        {message ? (
            <PopupMessage
                isOpen={PopUpOpen}
                message={message}
                confirmationMessage={confirmationMessage}
                onOk={confirmationMessage ? handleConfirmationOk : handleConfirmationCancel}
                onCancel={handleConfirmationCancel}
            />
        ) : null}
        </>
    );

}

export default Storage;