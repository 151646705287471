import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import SearchRangeRow from './searchrangerow';
import { setCustomFilters, } from "../actions/index";
import { Row, Col, } from "react-bootstrap";

const Phasing = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const updateState = (col, data) => {
        let filters = state.custom_filters;
        filters[col] = { ...filters[col], ...data };
        dispatch(setCustomFilters(filters));
    }

    return (
        <Row className="text-center">
            <Col lg={12}>
                <table>
                    <tbody>
                        <SearchRangeRow
                            title="Lag rate (%)" col="lag"
                            op={state.custom_filters.lag != undefined ? state.custom_filters.lag.op : ''}
                            min={state.custom_filters.lag.min} max={state.custom_filters.lag.max}
                            low={state.custom_filters.lag.low} high={state.custom_filters.lag.high}
                            updateState={updateState} />

                        <SearchRangeRow
                            title="Lead rate (%)" col="lead"
                            op={state.custom_filters.lead != undefined ? state.custom_filters.lead.op : ''}
                            min={state.custom_filters.lead.min} max={state.custom_filters.lead.max}
                            low={state.custom_filters.lead.low} high={state.custom_filters.lead.high}
                            updateState={updateState} />
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default Phasing;