import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import StatusItems from './statusitems';
import { useSelector } from "react-redux";

const SearchTextRow = props => {
    const { op, val, col, updateState, title, placeholder } = props;
    const [operation, setOperation] = React.useState({ op: op ? op : 'a', val: val });

    const state = useSelector(state => state);

    const question = (title) => {
        return (
            <FontAwesomeIcon title={`Filter by ${title}`} icon={faQuestionCircle} style={{ width: "16px", height: "16px", color: "#ABABAB" }} />
        )
    }

    useEffect(() => {
        if (operation.val !== undefined) {
            let report = {                 
                ...operation, saved: true
            }
            updateState(col, report);
        }        
    }, [operation]);   

    useEffect(() => {    
       if (state.custom_filters[col].op && state.custom_filters[col].op === 'a') setOperation({ op: 'a', val: '', dirty: false});            
    }, [state.custom_filters]); 

    const displayValues =  {
        c: "Contains",
        d: "Doesn't contain", 
        e: "Equals", 
        a: "All"
    };

    const handleSelect = (e) => {        
        if (e === 'a') setOperation({ op: e, dirty: false, val : '' })       
        else setOperation({ ...operation, op: e, dirty: true });
    }

    const handleInputChange = (e) => {
        let val = e.target.value;      
        setOperation( { ...operation, val: val, dirty: true });
    }

    return (
        <tr>
            <td className="w100">{title}</td>
            <td>{question(title)}</td>
            <td className="w160">
                <StatusItems selection={operation} handleSelect={handleSelect} displayValues={displayValues} />
            </td>
            <td colSpan="4">
                <Form.Control type='text' value={operation.val} onChange={handleInputChange} placeholder={placeholder} disabled={operation.op === 'a'} />
            </td>
        </tr>
    )
}

export default SearchTextRow;
