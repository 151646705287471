import React from 'react';
import { CSVLink } from "react-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const CSVDownloadLink = (props) => {
    const { data, filename, title } = props;   
    return (
        <CSVLink asyncOnClick={true} filename={filename} data={data} style={{ textDecoration: 'none', color: '#0075a9'}}>           
           <FileDownloadIcon />{title !== undefined ? title : 'Download Raw CSV'}
        </CSVLink>
    )
}

export default CSVDownloadLink;