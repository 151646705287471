export const RenderThetaProfilingCharts = (csv, tileArea, flow, radiusVal) => {
    try {
        const unpackThetaByRadiusObj = (rows, key, radiusVal) => {
            let result = [];
            rows.filter(row => {
                if (Math.round(row['radius']) === Math.round(radiusVal)) {
                    result.push({ 'theta': row['theta'], key: row[key] });
                }
            });

            return result;
        }             

        let rows = csv;

        if (rows === null) {
            return null;
        }

        let dataDensity = flow === '1' ? 'count_beads_sig_gt_threshold' : 'count_beads_sig_gt_threshold';

        let data1 = unpackThetaByRadiusObj(rows, dataDensity, radiusVal).sort((a, b) => (parseFloat(a.theta) > parseFloat(b.theta)) ? 1 : -1);

        let data_theta1_raw = data1.map(row => {
            return { 'theta': (parseFloat(row.theta) - Math.trunc(parseFloat(row.theta))) * 360, 'key': row.key };
        }).sort((a, b) => (parseFloat(a.theta) > parseFloat(b.theta)) ? 1 : -1);

        let data_theta1_X = data_theta1_raw.map(row => {
            return parseFloat(row.theta);
        });

        let data_theta1_Y = data_theta1_raw.map(row => {
            return (parseFloat(row.key) / parseFloat(tileArea)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        });

        let data_theta1 = [{
            type: "scatter",
            mode: "lines",
            name: 'Profile for per tile bead density',
            line: { color: '#1f77b4' },          
            x: data_theta1_X,
            y: data_theta1_Y,
        }];

        let layout_theta1 = {           
            xaxis: {
                title: "Theta",
                showline: true,
                tickmode: 'array',
                tickvals: [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360],
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',

                tickcolor: 'white',
            },
            yaxis: {
                title: "Bead Density",
                showline: true,
                titlefont: {
                    "size": 14
                }
            },
            titlefont: {
                "size": 14
            },           
            height: 450,
            font: {
                size: 14
            },
            showlegend: true,
            legend: {
                x: 0.2,
                y: 1.5
            },            
        }

        let data2 = unpackThetaByRadiusObj(rows, 'mean_Sig', radiusVal).sort((a, b) => (parseFloat(a.theta) > parseFloat(b.theta)) ? 1 : -1);

        let data_theta2_raw = data2.map(row => {
            return { 'theta': (parseFloat(row.theta) - Math.trunc(parseFloat(row.theta))) * 360, 'key': row.key };
        }).sort((a, b) => (parseFloat(a.theta) > parseFloat(b.theta)) ? 1 : -1);

        let data_theta2_X = data_theta2_raw.map(row => {
            return parseFloat(row.theta);
        });

        let data_theta2_Y = data_theta2_raw.map(row => {
            return parseFloat(row.key);
        });

        let data_theta2 = [{
            type: "scatter",
            mode: "lines",
            name: 'Profile for per tile signal intensity',
            line: { color: '#ff7f0e' },          
            x: data_theta2_X,
            y: data_theta2_Y,
        }];

        let layout_theta2 = {          
            xaxis: {
                title: "Theta",
                tickmode: 'array',
                tickvals: [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360],
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                showline: true,
                tickcolor: 'white',
            },
            yaxis: {
                title: "Signal Intensity",
                showline: true,
                titlefont: {
                    "size": 14
                }
            },
            titlefont: {
                "size": 14
            },            
            height: 450,
            font: {
                size: 14
            },
            showlegend: true,
            legend: {
                x: 0.2,
                y: 1.5
            },           
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data_theta1, layout_theta1, data_theta2, layout_theta2, config };
    }
    catch  {      
        return undefined; 
    }
}