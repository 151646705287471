import React from 'react';
import { Alert } from "react-bootstrap";

const ShowAlert = (props) => { 
    const {text, type, headerText }  = props;  
    return (
        <React.Fragment>            
            <Alert variant={type} text={text} style={{marginTop: 15}}>
                {headerText !== undefined ? <Alert.Heading>{headerText}</Alert.Heading> : null}
                {text}
            </Alert>
        </React.Fragment>
    )
};

export default ShowAlert;