export const RenderCircleCharts = (csv, radius, theta, beadsPerTile, flow, cminDensityVal, cmaxDensityVal, cminSignalVal, cmaxSignalVal, cminSNRVal,
    cmaxSNRVal, tileArea, isBaseLine, draw3rdPlot, runid, crop, pixelSize, tileWidth, tileHeight, cminBrightBeadVal, cmaxBrightBeadVal, cminBSLValCalc,
    cmaxBSLValCalc, camera) => {
    try {
        let widthInit = 450;
        let heightInit = 450;
        if (isBaseLine) {
            widthInit = 450;
            heightInit = 450;
        }

        function unpack(rows, key) {
            return rows.map(function (row) {
                return row[key];
            });
        }

        function unpackFLoat(rows, key) {
            return rows.map(function (row) {
                return parseFloat(row[key]).toFixed(1);
            });
        }

        function unpackByTileArea(rows, key) {
            return rows.filter(f => isNumeric(f[key])).map(function (row) {
                if (crop !== undefined) {
                    if (crop.length) {
                        let cropObj = crop.find(f => parseInt(f.radius) === row.radius);
                        if (cropObj !== undefined)
                            return (parseFloat(row[key]) / parseFloat(((pixelSize * (tileWidth - cropObj.cropLeft - cropObj.cropRight) * pixelSize * tileHeight) / 1000000))).toFixed(0);
                    }
                    else return (parseFloat(row[key]) / parseFloat(tileArea)).toFixed(0);
                }
                else return (parseFloat(row[key]) / parseFloat(tileArea)).toFixed(0);
            });
        }

        let rows = csv;

        if (rows == null) {
            return null;
        }

        let isBrightBeads = false;
        let snrLayoutTag = "SNR per tile";
        if (!isBaseLine) {
            if (rows[0].mean_beads_sig_gt_threshold !== "" && rows[0].mean_beads_sig_gt_threshold !== null) {
                isBrightBeads = true;
                snrLayoutTag = `Flow ${flow} Signal of Bright Beads per tile`;
            }
        }

        function getSnr(rows) {
            return rows.filter(f => isNumeric(f['mean_Sig']) && isNumeric(f['mean_SigStd'])).map(function (row) {
                return parseFloat(row['mean_Sig']) / parseFloat(row['mean_SigStd']);
            });
        }


        function getBsl(rows) {
            return rows.filter(f => isNumeric(f['mean_Sig'])).map(function (row) {
                return parseFloat(row['mean_Sig']);
            });
        }

        function getBrightBeads(rows) {
            return rows.filter(f => isNumeric(f['mean_beads_sig_gt_threshold'])).map(function (row) {
                return parseFloat(row['mean_beads_sig_gt_threshold']);
            });
        }

        function isNumeric(str) {
            if (typeof str !== "string") return false
            return !isNaN(str) &&
                !isNaN(parseFloat(str))
        }

        let dataDensity = flow === '1' ? 'count_beads_sig_gt_threshold' : 'count_beads_sig_gt_threshold';

        let text_density = [];
        function makeTextDensity(rows) {
            return rows.filter(f => isNumeric(f[dataDensity])).map(function (row) {
                try {
                    if (crop !== undefined) {
                        if (crop.length) {
                            let cropObj = crop.find(f => parseInt(f.radius) === row.radius);
                            if (cropObj !== undefined) text_density.push('density: ' + (parseFloat(row[dataDensity]) / ((pixelSize * (tileWidth - cropObj.cropLeft - cropObj.cropRight) * pixelSize * tileHeight) / 1000000)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                        }
                        else text_density.push('density: ' + (parseInt(row[dataDensity]) / ((pixelSize * tileWidth * pixelSize * tileHeight) / 1000000)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                        
                    }
                    else {                        
                        text_density.push('density: ' + (parseInt(row[dataDensity]) / ((pixelSize * tileWidth * pixelSize * tileHeight) / 1000000)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                    }
                }
                catch { }
            });
        }
        makeTextDensity(rows);

        let text_signal = [];
        function makeTextSignal(rows) {
            return rows.map(function (row) { text_signal.push('signal: ' + row['mean_Sig']); });
        }
        makeTextSignal(rows);

        let text_snr = [];
        function makeTextSNR(rows) {
            return rows.filter(f => isNumeric(f['mean_Sig']) && isNumeric(f['mean_SigStd'])).map(function (row) { text_snr.push('Snr: ' + parseFloat(row['mean_Sig']) / parseFloat(row['mean_SigStd'])); });
        }
        makeTextSNR(rows);

        let text_bsl = [];
        function makeTextBSL(rows) {
            return rows.filter(f => isNumeric(f['mean_Sig'])).map(function (row) { text_snr.push('Zero Flow: ' + parseInt(row['mean_Sig'])); });
        }
        makeTextBSL(rows);

        let text_bright_bead = [];
        function makeTextBrightBead(rows) {
            return rows.filter(f => isNumeric(f['mean_beads_sig_gt_threshold'])).map(function (row) {
                if (row['mean_beads_sig_gt_threshold'] !== undefined && row['mean_beads_sig_gt_threshold'] !== null)
                    text_bright_bead.push('Signal of Bright Beads: ' + parseInt(row['mean_beads_sig_gt_threshold']));
            });
        }
        makeTextBrightBead(rows);

        let data_density = [
            {
                type: "scatterpolargl",
                r0: 90,
                r: unpackFLoat(rows, radius),
                theta: unpack(rows, theta).map(function (row) { return (row - Math.trunc(row)) * 360; }),
                thetaunit: "gradus",
                mode: "markers",
                name: "Beads",
                orientation: "v",
                marker: {
                    symbol: "square-200",
                    color: unpackByTileArea(rows, dataDensity),
                    cmin: cminDensityVal,
                    cmax: cmaxDensityVal,
                    size: 3,
                    opacity: 1,
                    colorbar: {
                        len: 0.85,
                        thickness: 10,
                        xpad: 38,
                        tickmode: "auto",
                        nticks: 4,
                        tickangle: "-90",
                        tickvals: unpackByTileArea(rows, dataDensity)
                    },
                    colorscale: [[0, 'rgb(0,0,255)'], [0.33, 'rgb(0, 255, 255)'], [0.66, 'rgb(0, 255, 0)'], [1, 'rgb(225, 125, 40)']],
                },
                cliponaxis: false,
                text: text_density
            }
        ]

        let layout_density = {
            title: camera === undefined ? `<b>[${runid}]</b> - Bead density per mm&#x00B2;` : `<b>[${runid !== undefined ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}]</b> - Bead density per mm&#x00B2;`,           
            margin: {
                l: 40,                
                b: 12
            },
            titlefont: {
                "size": 14
            },
            width: widthInit,
            height: heightInit,
            font: {
                size: 14
            },
            showlegend: false,
            polar: {
                bgcolor: "rgb(105,105,105)",
                angularaxis: {
                    tickwidth: 2,
                    linewidth: 2,
                    layer: "below traces",
                    rotation: 90,
                    dtick: 30,
                    direction: "clockwise",
                    gridwidth: 2
                },
                radialaxis: {
                    side: "clockwise",
                    showline: false,
                    visible: false,
                    linewidth: 0.1,
                    tickwidth: 0.1,
                    gridcolor: "white",
                    gridwidth: 0.1
                }
            },           
        }

        let data_signal = [
            {
                type: "scatterpolargl",
                r0: 90,
                r: unpackFLoat(rows, radius),
                theta: unpack(rows, theta).map(function (row) { return (row - Math.trunc(row)) * 360; }),
                thetaunit: "gradus",
                mode: "markers",
                name: "Beads",
                orientation: "v",
                marker: {
                    symbol: "square",
                    color: unpack(rows, 'mean_Sig'),
                    cmin: cminSignalVal,
                    cmax: cmaxSignalVal,
                    size: 3,
                    opacity: 1,
                    opacity: 0.7,
                    colorbar: {
                        len: 0.85,
                        thickness: 10,
                        xpad: 38,
                        tickmode: "auto",
                        nticks: 4,
                        tickangle: "-90",
                        tickvals: unpack(rows, 'mean_Sig')
                    },
                    colorscale: [[0, 'rgb(0,0,255)'], [0.33, 'rgb(0, 255, 255)'], [0.66, 'rgb(0, 255, 0)'], [1, 'rgb(225, 125, 40)']],
                },
                cliponaxis: false,
                text: text_signal
            }
        ]


        let layout_signal = {
            title: isBaseLine ? `<b>[${runid}]</b> - Zero Flow Signal per tile` : `<b>[${runid}]</b> - Flow ${flow} Signal per tile`,           
            margin: {
                l: 30,                
                b: 12,
                r: 20
            },
            titlefont: {
                "size": 14
            },
            width: widthInit,
            height: heightInit,
            font: {
                size: 14
            },
            showlegend: false,
            polar: {
                bgcolor: "rgb(105,105,105)",
                angularaxis: {
                    tickwidth: 2,
                    linewidth: 2,
                    layer: "below traces",
                    rotation: 90,
                    dtick: 30,
                    direction: "clockwise",
                    gridwidth: 2
                },
                radialaxis: {
                    side: "clockwise",
                    showline: false,
                    visible: false,
                    linewidth: 2,
                    tickwidth: 2,
                    gridcolor: "grey",
                    gridwidth: 2
                }
            },           
        }

        let data_snr = [
            {
                type: "scatterpolargl",
                r0: 90,
                r: unpackFLoat(rows, radius),
                theta: unpack(rows, theta).map(function (row) { return (row - Math.trunc(row)) * 360; }),
                thetaunit: "gradus",
                mode: "markers",
                name: "Beads",
                orientation: "v",
                marker: {
                    symbol: "square",
                    color: getSnr(rows),
                    cmin: cminSNRVal,
                    cmax: cmaxSNRVal,
                    size: 3,
                    opacity: 1,
                    colorbar: {
                        len: 0.85,
                        thickness: 10,
                        xpad: 38,
                        tickmode: "auto",
                        nticks: 4,
                        tickangle: "-90",
                        tickvals: getSnr(rows)
                    },
                    colorscale: [[0, 'rgb(0,0,255)'], [0.33, 'rgb(0, 255, 255)'], [0.66, 'rgb(0, 255, 0)'], [1, 'rgb(225, 125, 40)']],
                },
                cliponaxis: false,
                text: text_snr
            }
        ]


        let layout_snr = {
            title: isBaseLine ? `<b>[${runid}]</b> - Zero Flow Signal per tile` : `<b>[${runid}]</b> - ${snrLayoutTag}`,
            //paper_bgcolor: "#303030",
            //plot_bgcolor: "#303030",
            margin: {
                l: 30,               
                b: 12,
                r: 1
            },
            titlefont: {
                "size": 14
            },
            width: widthInit,
            height: heightInit,
            font: {
                size: 14
            },
            showlegend: false,
            polar: {
                bgcolor: "rgb(105,105,105)",
                angularaxis: {
                    tickwidth: 2,
                    linewidth: 2,
                    layer: "below traces",
                    rotation: 90,
                    dtick: 30,
                    direction: "clockwise",
                    gridwidth: 2
                },
                radialaxis: {
                    side: "clockwise",
                    showline: false,
                    visible: false,
                    linewidth: 2,
                    tickwidth: 2,
                    gridcolor: "grey",
                    gridwidth: 2
                }
            },           
        }

        let data_bright_bead = [
            {
                type: "scatterpolargl",
                r0: 90,
                r: unpackFLoat(rows, radius),
                theta: unpack(rows, theta).map(function (row) { return (row - Math.trunc(row)) * 360; }),
                thetaunit: "gradus",
                mode: "markers",
                name: "Beads",
                orientation: "v",
                marker: {
                    symbol: "square",
                    color: getBrightBeads(rows),
                    cmin: cminBrightBeadVal,
                    cmax: cmaxBrightBeadVal,
                    size: 3,
                    opacity: 1,
                    colorbar: {
                        len: 0.85,
                        thickness: 10,
                        xpad: 38,
                        tickmode: "auto",
                        nticks: 4,
                        tickangle: "-90",
                        tickvals: getBrightBeads(rows)
                    },
                    colorscale: [[0, 'rgb(0,0,255)'], [0.33, 'rgb(0, 255, 255)'], [0.66, 'rgb(0, 255, 0)'], [1, 'rgb(225, 125, 40)']],
                },
                cliponaxis: false,
                text: text_bright_bead
            }
        ]

        let data_bsl = [
            {
                type: "scatterpolargl",
                r0: 90,
                r: unpackFLoat(rows, radius),
                theta: unpack(rows, theta).map(function (row) { return (row - Math.trunc(row)) * 360; }),
                thetaunit: "gradus",
                mode: "markers",
                name: "Beads",
                orientation: "v",
                marker: {
                    symbol: "square",
                    color: getBsl(rows),
                    cmin: cminBSLValCalc,
                    cmax: cmaxBSLValCalc,
                    size: 3,
                    opacity: 1,
                    colorbar: {
                        len: 0.85,
                        thickness: 10,
                        xpad: 38,
                        tickmode: "auto",
                        nticks: 4,
                        tickangle: "-90",
                        tickvals: getBsl(rows)
                    },
                    colorscale: [[0, 'rgb(0,0,255)'], [0.33, 'rgb(0, 255, 255)'], [0.66, 'rgb(0, 255, 0)'], [1, 'rgb(225, 125, 40)']],
                },
                cliponaxis: false,
                text: text_bsl
            }
        ]

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo'],
            displaylogo: false,
            displayModeBar: false
        };

        if (isBaseLine) {
            return { data_density, layout_density, data_signal, layout_signal, data_snr: data_bsl, layout_snr, config };
        }
        else {
            let result = {};
            result.data_density = data_density;
            result.layout_density = layout_density;
            result.data_signal = data_signal;
            result.layout_signal = layout_signal;
            result.config = config;           
            if (draw3rdPlot) {
                if (isBrightBeads) {
                    result.data_snr = data_bright_bead;
                    result.layout_snr = layout_snr;
                } else {
                    result.data_snr = data_snr;
                    result.layout_snr = layout_snr;
                }
            }
            return result;
        }
    }
    catch (e) {
        return {};
    }
}

export const RenderCircleChartsByFlow = (csv, radius, theta, beadsPerTile, flow, cminDensityVal, cmaxDensityVal, cminSignalVal, cmaxSignalVal, cminSNRVal, cmaxSNRVal, tileArea, crop, pixelSize, tileWidth, tileHeight) => {
    try {
        const unpack = (rows, key) => {
            return rows.map(function (row) {
                return row[key];
            });
        }

        function unpackFLoat(rows, key) {
            return rows.map(function (row) {
                return parseFloat(row[key]).toFixed(1);
            });
        }

        function isNumeric(str) {
            if (typeof str !== "string") return false
            return !isNaN(str) &&
                !isNaN(parseFloat(str))
        }

        function unpackByTileArea(rows, key) {
            return rows.filter(f => isNumeric(f[key])).map(function (row) {
                if (crop !== undefined) {
                    if (crop.length) {
                        let cropObj = crop.find(f => parseInt(f.radius) === row.radius);
                        if (cropObj !== undefined)
                            return (parseFloat(row[key]) / parseFloat(((pixelSize * (tileWidth - cropObj.cropLeft - cropObj.cropRight) * pixelSize * tileHeight) / 1000000))).toFixed(0);
                    }
                    else return (parseFloat(row[key]) / parseFloat(tileArea)).toFixed(0);
                }
                else return (parseFloat(row[key]) / parseFloat(tileArea)).toFixed(0);
            });
        }

        let rows = csv;

        if (rows === null) {
            return null;
        }
       
        let dataDensity = flow === '1' ? 'count_beads_sig_gt_threshold' : 'count_beads_sig_gt_threshold';

        let text_density = [];
        function makeTextDensity(rows) {
            return rows.filter(f => isNumeric(f[dataDensity])).map(function (row) {
                try {
                    if (crop !== undefined) {
                        if (crop.length) {
                            let cropObj = crop.find(f => parseInt(f.radius) === row.radius);
                            if (cropObj !== undefined)
                                text_density.push('density: ' + (parseFloat(row[dataDensity]) / ((pixelSize * (tileWidth - cropObj.cropLeft - cropObj.cropRight) * pixelSize * tileHeight) / 1000000)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                        }
                        else {
                            //text_density.push('density: ' + (parseFloat(row[dataDensity]) / parseFloat(tileArea)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                            text_density.push('density: ' + (parseInt(row[dataDensity]) / ((pixelSize * tileWidth * pixelSize * tileHeight) / 1000000)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                        }
                    }
                    else {
                        //text_density.push('density: ' + (parseFloat(row[dataDensity]) / parseFloat(tileArea)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                        text_density.push('density: ' + (parseInt(row[dataDensity]) / ((pixelSize * tileWidth * pixelSize * tileHeight) / 1000000)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'));
                    }
                }
                catch { }
            });
        }
        makeTextDensity(rows);           

        let data_density = [
            {
                type: "scatterpolargl",
                r0: 90,
                r: unpackFLoat(rows, radius),
                theta: unpack(rows, theta).map(function (row) { return (row - Math.trunc(row)) * 360; }),
                thetaunit: "gradus",
                mode: "markers",
                name: "Beads",
                orientation: "v",
                marker: {
                    symbol: "square-200",
                    color: unpackByTileArea(rows, dataDensity),
                    cmin: cminDensityVal,
                    cmax: cmaxDensityVal,
                    size: 3,
                    opacity: 1,
                    colorbar: {
                        len: 0.85,
                        thickness: 10,
                        xpad: 38,
                        tickmode: "auto",
                        nticks: 4,
                        tickangle: "-90",
                        tickvals: unpackByTileArea(rows, dataDensity)
                    },
                    colorscale: [[0, 'rgb(0,0,255)'], [0.33, 'rgb(0, 255, 255)'], [0.66, 'rgb(0, 255, 0)'], [1, 'rgb(225, 125, 40)']],
                },
                cliponaxis: false,
                text: text_density
            }
        ]

        let layout_density = {           
            margin: {
                l: 45,
                t: 2,
                b: 12
            },
            titlefont: {
                "size": 14
            },
            font: {
                size: 14
            },
            width: 450,
            height: 450,
            showlegend: false,
            polar: {
                bgcolor: "rgb(105,105,105)",
                angularaxis: {
                    tickwidth: 2,
                    linewidth: 2,
                    layer: "below traces",
                    rotation: 90,
                    dtick: 30,
                    direction: "clockwise",
                    gridwidth: 2
                },
                radialaxis: {
                    side: "clockwise",
                    showline: false,
                    visible: false,
                    linewidth: 2,
                    tickwidth: 2,
                    gridcolor: "grey",
                    gridwidth: 2
                }
            },
            font: {
                color: 'white'
            },
        }

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo'],
            displaylogo: false,
            displayModeBar: false,
            //staticPlot: true
        };


        return { data_density, layout_density, config };
    }
    catch (e) {
        console.log(e);
    }
}