import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    getSignalPlotsDataAsync, setSignalPlotsRenderData, getHistogramPlotsDataAsync, setHistogramPlotsRenderData, setIsSignalPlotsLoading, setIsHistogramPlotsLoading,
    getRunConfigTableDataAsync, getRunConfigurationPlots, checkRunIsBarcoded, getBeadsFilteringTableData, getBeadsFilteringPlots, getBarcodeStatisticsTableData, getBERTableData, setBERTableRenderData, getBERMERTableData, setBERHMERTableRenderData, getErrorPlots, getRLQTableData, setRLQTableRenderData, getFMetricsTableData, setFMetricsTableRenderData, geBarcodeCoveragePlots,
    geBaseQualityPlots, gePhasingPlots, geSignalsPlots, getUniformityPlots, getUniformitySignalPlots, getQTableData, checkBaseCallingReportExist, getSignalsLinearityTableData, setSignalsLinearityTableRenderData, getSignalsLinearityPlots, getHistogramPlotsDataByFlowAsync, setHistogramPlotsByFlowRenderData, getClumpingsTableData,
    setClumpingsTableRenderData, getCoveragePlots, setPhasingTableRenderData, getPhasingTableData, setCovGCBiasTableRenderData, checkVCReportExists, setActiveTabName, setCurrentRunData, setRunIsBarcoded, setActiveInstrHealthSubTabName, setActiveTemplateReportSubTabName, getReadLengthPlots, getPolyPlots, 
    getRunSummaryDensityPlotDataAsync, checkTemplateReportExistAsync, geSignalsPlotsPNG, getBaseQualityBerPerFlowPlot, getHomoIndelError, setQTableRenderData
} from "../actions/index";
import { Tabs, Tab, Container, Row, Col, } from "react-bootstrap";
import ShowAlert from '../showalert/showalert';
import * as mpld3 from 'mpld3';
import Signals from '../signals/signals';
import RunSummary from '../runsummary/runsummary';
import * as Utils from '../../utils/utils';
import Density from '../density/density';
import InstrumentHealth from '../instrumenthealth/instrumenthealth';
import HorizontalSpinner from '../spinner/horizontalspinner';
import TemplateReport from '../templatereport/templatereport';
import RenderTable from '../maintable/table';
import DownloadLink from '../downloadlink/downloadlink';
import Frame from '../frame/frame';
import Notification from '../notification/notification';

const Root = props => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const { mainTableData, handleClearAdvancedSearch } = props;

    const [signalPlotsData, setSignalPlotsData] = useState(undefined);
    const [isTemplateReportExist, setTemplateReportExist] = useState(false);
    const [isHGBaseCallingReportExist, setHGBaseCallingReportExist] = useState(false);
    const [isECBaseCallingReportExist, setECBaseCallingReportExist] = useState(false);
    const [ecBasecallingReportData, setEcBasecallingReportData] = useState(undefined);
    const [hgBasecallingReportData, setHgBasecallingReportData] = useState(undefined);

    const setRunData = () => {
        let copy = state.runlist;
        let unified = copy.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId));
        if (state.is_unified_row_selected && unified && unified.length > 0) dispatch(setCurrentRunData(unified));
        else if (!state.is_unified_row_selected && unified && unified.length > 0) {
            if (unified[0].subRows) {
                let nonUnified = unified[0].subRows.filter(f => f.runid === state.runId);
                dispatch(setCurrentRunData(nonUnified));
            }
            else dispatch(setCurrentRunData(unified));
        } else if (state.is_unified_row_selected && unified.length === 0) {
            let analysisRun = state.runId.split('.a')[0]
            let unifiedAnalysisRun = copy.filter(f => f.runid === analysisRun);
            if (unifiedAnalysisRun[0].subRows) {
                let nonUnifiedAnalysisRun = unifiedAnalysisRun[0].subRows.filter(f => f.runid === state.runId);
                dispatch(setCurrentRunData(nonUnifiedAnalysisRun));
            }
            else dispatch(setCurrentRunData(unifiedAnalysisRun));
        }
    }

    useEffect(() => {
        dispatch(setBERHMERTableRenderData([]));
        dispatch(setBERTableRenderData([]));
        dispatch(setRLQTableRenderData([]));
        dispatch(setFMetricsTableRenderData([]));
        dispatch(setQTableRenderData([]));
        dispatch(setSignalsLinearityTableRenderData([]));
        dispatch(setClumpingsTableRenderData([]));
        dispatch(setPhasingTableRenderData([]));
        dispatch(setCovGCBiasTableRenderData([]));
        setSignalPlotsData(undefined);
        setRunData();

        if (state.plotly_plots_list !== undefined && state.plotly_plots_list.length > 0) {

            state.plotly_plots_list.forEach(plot => Utils.purgePlot(plot));
        }

        if (state.plotly_uri_list !== undefined && state.plotly_uri_list.length > 0) {

            state.plotly_uri_list.forEach(plot => Utils.purgeUriPlot(plot));
        }
    }, [state.runId]);

    const handleTabsSelect = key => {
        try {
            setRunData();
            let exists = state.isRunBarcoded.filter(f => f.runid === state.runId);
            if (exists.length > 0) {
                dispatch(setRunIsBarcoded(state.runId, exists[0].data));
            }
            else dispatch(checkRunIsBarcoded(state.runId, state.jwt));
            if (!state.is_unified_row_selected) {
                dispatch(setActiveTabName(key));
                dispatch(checkTemplateReportExistAsync(state.runId, state.jwt));
                dispatch(checkBaseCallingReportExist(state.runId, 'HG', state.jwt));
                dispatch(checkBaseCallingReportExist(state.runId, 'EC', state.jwt));
                if (key === 'signals') {
                    handleSignalsTabClick(state.runId);
                }
                else if (key === 'run_summary') {
                    dispatch(setActiveTabName('run_summary'));
                    handleRunSummaryTabClick();
                }
                else if (key === 'hgBaseCallingReport') {
                    handleHGBaseCallingTabClick();
                }
                else if (key === 'ecBaseCallingReport') {
                    handleECBaseCallingTabClick();
                }
                else if (key === 'density') {
                    let existsDensity = state.histogram_by_flow_plots_data.filter(f => f.runid === state.runId);
                    if (existsDensity.length === 0) {
                        dispatch(getHistogramPlotsDataByFlowAsync(state.runId, state.instrumentId, '1,2,3,4,5,6,7,8', state.jwt));
                        dispatch(getClumpingsTableData(state.runId, state.jwt));
                    }
                    else {
                        dispatch(setHistogramPlotsByFlowRenderData(existsDensity[0].data, state.runId));
                        dispatch(getClumpingsTableData(state.runId, state.jwt));
                    }
                }
                else if (key === 'instrument_health') {
                    dispatch(setActiveInstrHealthSubTabName(state.active_instr_health_sub_tab_name));
                }
                else if (key === 'template_report') {
                    dispatch(setActiveTemplateReportSubTabName(key));
                }
            }
            else {
                dispatch(setActiveTabName('run_summary'));
                handleRunSummaryTabClick();
            }
        }
        catch { }
    }

    useEffect(() => {
        if (state.active_tab_name === 'instrument_health') {
            dispatch(setIsSignalPlotsLoading(true));
            handleTabsSelect('signals');
        }
    }, [state.runId]);

    useEffect(() => {
        dispatch(setActiveTabName(state.is_unified_row_selected ? 'run_summary' : state.active_tab_name));
    }, [state.is_unified_row_selected]);

    const handleHGBaseCallingTabClick = () => {
        dispatch(checkBaseCallingReportExist(state.runId, 'HG', state.jwt));
    }

    const handleECBaseCallingTabClick = () => {
        dispatch(checkBaseCallingReportExist(state.runId, 'EC', state.jwt));
    }

    const handleSignalsTabClick = (runid) => {
        try {
            let existsSignal = state.signal_plots_data.filter(f => f.runid === state.runId);
            if (existsSignal.length === 0) {
                dispatch(getSignalPlotsDataAsync(runid, state.jwt));
            }
            else {
                dispatch(setIsSignalPlotsLoading(true));
                dispatch(setSignalPlotsRenderData(existsSignal[0].data, state.runId));
            }

            let existsHistogram = state.histogram_plots_data.filter(f => f.runid === state.runId && f.flow === state.flow);
            if (existsHistogram.length === 0) {
                if (state.instrumentId !== null) {
                    let tileArea = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrId === state.instrumentId);
                    dispatch(getHistogramPlotsDataAsync(state.runId, state.flow, state.instrumentId, tileArea !== undefined && tileArea.length > 0 ? tileArea[0] : undefined, state.jwt));
                }
            }
            else {
                dispatch(setIsHistogramPlotsLoading(true));
                dispatch(setHistogramPlotsRenderData(existsHistogram[0].data, state.runId, state.flow));
            }
        }
        catch { }
    }

    const handleRunSummaryTabClick = () => {
        try {
            dispatch(getSignalPlotsDataAsync(state.runId, state.jwt));
            const isRunBarcoded = state.isRunBarcoded.filter(f => f.runid === state.runId).length > 0 ? state.isRunBarcoded.filter(f => f.runid === state.runId)[0].data : undefined;
            /////////////////////////////// RUN SUMMARY /////////////////////////////// 

            ///// RUNCONFIGURATION /////
            if (state.run_config_table_render_data.filter(f => f.runid === state.runId).length === 0) {
                dispatch(getRunConfigTableDataAsync(state.runId, state.flow, state.jwt));
            }
            dispatch(getRunSummaryDensityPlotDataAsync(state.runId, state.flow, state.instrumentId, state.jwt));
            if (state.run_config_plots.filter(f => f.runid === state.runId).length === 0) {
                dispatch(getRunConfigurationPlots(state.runId, 'RDCALL', state.rsq_plots_by_camera_value, state.jwt));
            }

            ///// READFILTERING /////
            if (state.beads_filtering_table_data.filter(f => f.runid === state.runId).length === 0) {
                dispatch(getBeadsFilteringTableData(state.runId, isRunBarcoded, state.jwt));
            }
            if (state.beads_filtering_plots.filter(f => f.runid === state.runId).length === 0) {
                //dispatch(getBeadsFilteringPlots(state.runId, 'BFALL', isRunBarcoded, state.reads_filtering_plots_by_camera_value));
                dispatch(getBeadsFilteringPlots(state.runId, 'BFALL', isRunBarcoded, 'unified', state.jwt));
            }
            if (state.barcode_statistics_table_data.filter(f => f.runid === state.runId).length === 0) {
                dispatch(getBarcodeStatisticsTableData(state.runId, state.jwt));
            }
            dispatch(getQTableData(state.runId, state.jwt));
            dispatch(getBERTableData(state.runId, state.jwt));
            dispatch(getBERMERTableData(state.runId, state.jwt));
            dispatch(getErrorPlots(state.runId, 'ERRALL', isRunBarcoded, state.error_plots_by_camera_value, state.jwt));
            dispatch(getReadLengthPlots(state.runId, state.read_length_plots_by_camera_value, state.jwt));
            dispatch(getRLQTableData(state.runId, state.jwt));
            dispatch(getCoveragePlots(Utils.getRunIdWithoutCamera(state.runId), 'COVALL', isRunBarcoded, state.jwt));
            dispatch(getPolyPlots(state.runId, 'POLY', isRunBarcoded, state.poly_plots_by_camera_value, state.jwt));
            dispatch(getFMetricsTableData(state.runId, isRunBarcoded, state.jwt));
            dispatch(getBaseQualityBerPerFlowPlot(state.runId, 'BQALL_BER_PER_FLOW', false, state.jwt));
            dispatch(geBarcodeCoveragePlots(Utils.getRunIdWithoutCamera(state.runId), 'BCALL', 'BCALL_JSON', state.jwt));
            dispatch(getSignalsLinearityTableData(state.runId, isRunBarcoded, state.jwt));
            if (!isRunBarcoded) dispatch(getSignalsLinearityPlots(state.runId, 'SLALL', state.jwt));

            dispatch(getHomoIndelError(state.runId, state.jwt));

            if (!state.is_unified_row_selected) {
                dispatch(geBaseQualityPlots(state.runId, 'BQALL', state.jwt));
                dispatch(gePhasingPlots(state.runId, 'PALL', state.jwt));
                dispatch(geSignalsPlots(state.runId, 'SALL', state.jwt));
                dispatch(geSignalsPlotsPNG(state.runId, 'SALL_PNG', state.jwt));
                //dispatch(geCoverageGCPlots(state.runId, 'CGCALL'));               
                dispatch(getUniformityPlots(state.runId, 'UALL', state.jwt));
                dispatch(getUniformitySignalPlots(state.runId, 'USALL', false, state.jwt));
                dispatch(getPhasingTableData(state.runId, state.jwt));               
                dispatch(checkVCReportExists(Utils.getRunIdWithoutCamera(state.runId), state.jwt));
            }
        }
        catch { }
    }

    useEffect(() => {
        try {
            if (state.rlq_table_data && state.rlq_table_data.length > 0) {
                RenderRLQTable(state.rlq_table_data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }, [state.rlq_table_data]);

    useEffect(() => {
        try {
            if (state.signals_linearity_table_data && state.signals_linearity_table_data.length > 0) {
                RenderSignalsLinearityTable(state.signals_linearity_table_data);
            }
        }
        catch (e) {
            console.log(e);
        }
    }, [state.signals_linearity_table_data]);

    useEffect(() => {
        try {
            if (state.clumpings_table_data && state.clumpings_table_data.length > 0) {
                if (state.clumpings_table_data.length >= 2) {
                    dispatch(setClumpingsTableRenderData(state.clumpings_table_data));
                }
            }
            else dispatch(setClumpingsTableRenderData([]));
        }
        catch { }
    }, [state.clumpings_table_data]);

    useEffect(() => {
        try {
            if (state.covgcbias_table_data.data && state.covgcbias_table_data.data.length > 0) {
                RenderCovGCBiasTable(state.covgcbias_table_data.data, state.covgcbias_table_data.barcode);
            }
        }
        catch { }
    }, [state.covgcbias_table_data]);

    const RenderCovGCBiasTable = (data, barcode) => {
        try {
            if (data.length > 0) {
                const regex = new RegExp(/GC \d+-\d+/, 'g');
                let filtered = data[0].filter(row => row[0].match(regex));
                dispatch(setCovGCBiasTableRenderData({ data: filtered, barcode: barcode.replace('_LibMD_', '') }));
            }
        }
        catch { }
    }

    const RenderRLQTable = data => {
        try {
            if (data.length > 0) {
                let renderData = data.filter(f => f[0] === 'avg_rlqx' || f[0] === 'avg_rl').map(m => {
                    if (m.length >= 2) {
                        if (m[0] === "avg_rlqx") {
                            m[0] = "RLQ25 (bp)";
                            return m;
                        }
                        if (m[0] === "avg_rl") {
                            m[0] = "Read Length (bp)";
                            return m;
                        }
                        else return m;
                    }
                });
                dispatch(setRLQTableRenderData(renderData));
            }
        }
        catch { }
    }

    const RenderSignalsLinearityTable = data => {
        try {
            if (data.length > 0) {
                dispatch(setSignalsLinearityTableRenderData(data));
            }
        }
        catch { }
    }

    useEffect(() => {
        if (state.ec_basecalling_report !== undefined) {
            setEcBasecallingReportData(state.ec_basecalling_report);
        }
        else setEcBasecallingReportData(undefined);
    }, [state.ec_basecalling_report]);

    useEffect(() => {
        if (state.hg_basecalling_report !== undefined) {
            setHgBasecallingReportData(state.hg_basecalling_report);
        }
        else setHgBasecallingReportData(undefined);
    }, [state.hg_basecalling_report]);

    useEffect(() => {
        try {
            if (state.is_template_report_exist !== undefined && state.is_template_report_exist.length > 0) {
                let exists = state.is_template_report_exist.filter(f => f.runid === state.runId);
                if (exists !== undefined && exists.length > 0) {
                    setTemplateReportExist(exists[0].data);
                }
                else setTemplateReportExist(false);
            }
        }
        catch { }
    }, [state.is_template_report_exist]);

    useEffect(() => {
        try {
            if (state.is_hg_basecalling_report_exist !== undefined && state.is_hg_basecalling_report_exist === true) {
                setHGBaseCallingReportExist(true);
            }
            else if (state.active_tab_name !== 'hgBaseCallingReport') {
                setHGBaseCallingReportExist(false);
            }
            else setHgBasecallingReportData(undefined);
        }
        catch { }
    }, [state.is_hg_basecalling_report_exist]);

    useEffect(() => {
        try {
            if (state.is_ec_basecalling_report_exist !== undefined && state.is_ec_basecalling_report_exist === true) {
                setECBaseCallingReportExist(true);
            }
            else if (state.active_tab_name !== 'ecBaseCallingReport') {
                setECBaseCallingReportExist(false);
            }
            else setEcBasecallingReportData(undefined);
        }
        catch { }
    }, [state.is_ec_basecalling_report_exist]);

    return (
        <Container>
            <Row>
                <Col lg={12} style={{ marginTop: 15, height: 5 }}>
                    {state.is_run_data_loading ? <HorizontalSpinner /> : null}
                </Col>
                <Col lg={12} style={{ marginTop: 15, height: 5 }}>
                    <Notification />
                </Col>
                <Col lg={12} id="mainTableRoot" style={{ width: '100%', marginTop: 15 }}>
                    {(mainTableData !== undefined && mainTableData.length > 0) && <RenderTable mainTableData={mainTableData} handleTabsSelect={handleTabsSelect} handleClearAdvancedSearch={handleClearAdvancedSearch} />}
                </Col>
            </Row>
            <div className="tableContainer">
                <Row>
                    <Col>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="seq" role="tabpanel" aria-labelledby="seq-tab">
                                {state.current_run_data !== undefined && state.current_run_data.length > 0 ?
                                    <React.Fragment>
                                        <Tabs fill defaultActiveKey={state.active_tab_name} id="gridtabs" style={{ marginTop: 15 }} onSelect={handleTabsSelect} activeKey={state.active_tab_name}>
                                            {!state.is_unified_row_selected &&
                                                <Tab eventKey="instrument_health" title="Instrument Health">
                                                    <Tab.Container id="left-tabs-example" defaultActiveKey="run_time">
                                                        <InstrumentHealth signalPlotsData={signalPlotsData} setSignalPlotsData={setSignalPlotsData} />
                                                    </Tab.Container>
                                                </Tab>
                                            }

                                            {!state.is_unified_row_selected &&
                                                <Tab eventKey="density" title="Density" style={{ marginTop: 15 }}>
                                                    <Density></Density>
                                                </Tab>
                                            }

                                            {!state.is_unified_row_selected &&
                                                <Tab eventKey="signals" title="Signals">
                                                    <Signals signalPlotsData={signalPlotsData} setSignalPlotsData={setSignalPlotsData} />
                                                </Tab>
                                            }

                                            {isTemplateReportExist !== undefined && isTemplateReportExist === true && !state.is_unified_row_selected &&
                                                <Tab eventKey="template_report" title="Template Report">
                                                    <Tab.Container id="left-tabs-example2" defaultActiveKey="pdf_report">
                                                        <TemplateReport />
                                                    </Tab.Container>
                                                </Tab>
                                            }

                                            {isECBaseCallingReportExist !== undefined && isECBaseCallingReportExist === true && !state.is_unified_row_selected &&
                                                <Tab eventKey="ecBaseCallingReport" title="EC Base-Calling Report">
                                                    <Row style={{ marginTop: 15, marginBottom: 15 }}>
                                                        <Col lg={12}>
                                                            {ecBasecallingReportData !== undefined && state.active_tab_name === 'ecBaseCallingReport' ? <DownloadLink file={ecBasecallingReportData.download} file_name={`EC_Base_Calling_Report_${state.runId}.html`} file_link_label={'Download Report'} /> : null}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12}>
                                                            {/* {ecBasecallingReportData !== undefined && state.active_tab_name === 'ecBaseCallingReport' ? <div dangerouslySetInnerHTML={{ __html: ecBasecallingReportData.html }} /> : <ShowAlert type={'warning'} text='Report not found' />} */}
                                                            {ecBasecallingReportData !== undefined && state.active_tab_name === 'ecBaseCallingReport' ? <Frame data={ecBasecallingReportData.html} /> : <ShowAlert type={'warning'} text='Report not found' />}
                                                        </Col>
                                                    </Row>
                                                </Tab>
                                            }

                                            {isHGBaseCallingReportExist !== undefined && isHGBaseCallingReportExist === true && !state.is_unified_row_selected && state.user.roles && state.user.roles.map(m => m.name).indexOf('Customer') === -1 &&

                                                <Tab eventKey="hgBaseCallingReport" title="HG Base-Calling Report">
                                                    <Row style={{ marginTop: 15, marginBottom: 15 }}>
                                                        <Col lg={12}>
                                                            {hgBasecallingReportData !== undefined && state.active_tab_name === 'hgBaseCallingReport' ? <DownloadLink file={hgBasecallingReportData.download} file_name={`HG_Base_Calling_Report_${state.runId}.html`} file_link_label={'Download Report'} /> : null}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12}>
                                                            {/* {hgBasecallingReportData !== undefined && state.active_tab_name === 'hgBaseCallingReport' ? <div dangerouslySetInnerHTML={{ __html: hgBasecallingReportData.html }} /> : <ShowAlert type={'warning'} text='Report not found' />} */}
                                                            {hgBasecallingReportData !== undefined && state.active_tab_name === 'hgBaseCallingReport' ? <Frame data={hgBasecallingReportData.html} /> : <ShowAlert type={'warning'} text='Report not found' />}
                                                        </Col>
                                                    </Row>
                                                </Tab>
                                            }

                                            <Tab eventKey="run_summary" title="Run Summary">
                                                <RunSummary />
                                            </Tab>
                                        </Tabs>
                                    </React.Fragment>
                                    : null
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container >
    );
};

export default Root;