import { useMediaQuery } from "@material-ui/core";

const useDeviceQuery = () => {
  const device = {
    isBigScreen: useMediaQuery("(min-device-width:2700px)"),

    isBigScreen1: useMediaQuery("(min-device-width:1850px) and (max-device-width:2200px)"),

    isBigScreen2: useMediaQuery("(min-device-width:2250px) and (max-device-width:2700px)"),

    isBigScreen3: useMediaQuery("(max-device-width:2150px)"),

    isBigScreen4: useMediaQuery("(min-device-width:3300px)"),

    isBigScreen5: useMediaQuery("(min-device-width:2750px) and (max-device-width:3200px)"),
    
    isMedium3Screen: useMediaQuery("(min-device-width:1500px) and (max-device-width:1599px)"),

    isMedium2Screen: useMediaQuery("(min-device-width:1440px) and (max-device-width:1824px)"),
    
    isMedium1Screen: useMediaQuery("(min-device-width:1280px) and (max-device-width:1440px)"),

    isMediumScreen: useMediaQuery("(min-device-width:1280px)"),

    isSmallScreen: useMediaQuery("(max-width:1280px)"),

    isMobileScreen: useMediaQuery("(max-width:600px)"),

    isTabletScreen: useMediaQuery("(min-device-width:768px)"),

    isPhoneScreen: useMediaQuery("(max-width:600px)"),

    isPortrait: useMediaQuery("(orientation:portrait)"),

    isLandscape: useMediaQuery("(orientation:landscape)"),

    isTabletPortrait: useMediaQuery(
      "(min-device-width:768px) and (orientation:portrait)"
    ),

    isTabletLandscape: useMediaQuery(
      "(min-device-width:768px) and (orientation:landscape)"
    ),

    isPhonePortrait: useMediaQuery(
      "(max-width:600px) and (orientation:portrait)"
    ),

    isPhoneLandscape: useMediaQuery(
      "(max-width:600px) and (orientation:landscape)"
    ),
  };

  return device;
};

export default useDeviceQuery;