import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const PopupMessage = (props) => {
    const { isOpen, message, onOk, onCancel, confirmationMessage } = props;
    const [open, setOpen] = useState(false);


    const handlePopupClose = (event, reason) => {
        console.log('handlePopupClose', event, reason);
        if (reason && reason === "backdropClick") {
            return;
        }

        if (reason && reason === 'buttonClick') {
            return;
        }
        
          setOpen(false);
    }

    useEffect(() => {
        try {
            if (message || confirmationMessage) {
                //setOpen(true);
            }
        } catch (error) {
            console.log(error);
        }
    }, [message, confirmationMessage]);

    useEffect(() => {
        // Update local state based on the isOpen prop
        setOpen(isOpen);
    }, [isOpen]);

        return (
        <Dialog disableEnforceFocus open={open} onClose={handlePopupClose} fullWidth maxWidth={'sm'}>
            <DialogTitle style={{ alignItems: 'center', marginBottom: 6, textAlign: 'center' }}>{message || confirmationMessage}</DialogTitle>
            <DialogActions>
                <Button onClick={confirmationMessage ? onOk : onCancel} color={confirmationMessage ? 'primary' : 'success'} variant="outlined">
                    OK
                </Button>
                {confirmationMessage && (
                    <Button onClick={onCancel} color="error" variant="outlined">
                        Cancel
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default PopupMessage;