import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import SearchRangeRow from './searchrangerow';
import { setCustomFilters } from "../actions/index";
import { Row, Col, } from "react-bootstrap";

const Signal = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const updateState = (col, data) => {
        let filters = state.custom_filters;
        filters[col] = { ...filters[col], ...data };
        dispatch(setCustomFilters(filters));
    }   

    return (
        <Row className="text-center">
            <Col lg={12}>
                <table>
                    <tbody>
                        <SearchRangeRow
                            title="First Flow" col="firsttsig"
                            op={state.custom_filters.firsttsig != undefined ? state.custom_filters.firsttsig.op : ''}
                            min={state.custom_filters.firsttsig.min} max={state.custom_filters.firsttsig.max}
                            low={state.custom_filters.firsttsig.low} high={state.custom_filters.firsttsig.high}
                            updateState={updateState} />     

                        <SearchRangeRow
                            title="Drop rate (%)" col="droop"
                            op={state.custom_filters.droop != undefined ? state.custom_filters.droop.op : ''}
                            min={state.custom_filters.droop.min} max={state.custom_filters.droop.max}
                            low={state.custom_filters.droop.low} high={state.custom_filters.droop.high}
                            updateState={updateState} />                

                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default Signal;