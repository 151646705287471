import React from 'react';
import { Spinner} from "react-bootstrap";

const LoadingSpinner = (props) => {    
    return (
        <React.Fragment>
           <Spinner animation="grow" />           
        </React.Fragment>
    )
};

export default LoadingSpinner;