export const RenderRSQCharts = (csv, runid, cminDensityVal, cmaxDensityVal, camera) => {
    try {
        var widthInit = 450;
        var heightInit = 450;         
       
        function makeText(rows) {
            return rows.map(function (row) { return `Value: ${row.toFixed(2)}`; });
        }      

        var maxMetricMean = Math.max(...csv.Metric_mean);

        var sortedTheta = csv.Theta.map(m => parseFloat(m)).sort(function(a,b) { return a - b;});
        var sortedRadius = csv.Radius.map(m => parseFloat(m).toFixed(1)).map((r, i) => {            
            return csv.Radius[csv.Theta.indexOf(sortedTheta[i])].toFixed(1);
        });
        var sortedMetricMean = csv.Metric_mean.map(m => parseFloat(m)).map((r, i) => {
            return csv.Metric_mean[csv.Theta.indexOf(sortedTheta[i])];
        });      

        var data = [
            {
                type: "scatterpolargl",
                r0: 90,
                r: sortedRadius,
                theta: sortedTheta,
                thetaunit: "radians",
                mode: "markers",
                name: "Beads",
                orientation: "v",
                marker: {
                    symbol: "square-200",
                    color: sortedMetricMean,
                    cmin: cminDensityVal,
                    cmax: maxMetricMean,
                    size: 3,
                    opacity: 1,
                    colorbar: {
                        len: 0.85,
                        thickness: 10,
                        xpad: 38,
                        tickmode: "auto",
                        nticks: 4,
                        tickangle: "-90",
                        tickvals: sortedMetricMean
                    },
                    colorscale: [[0, 'rgb(0,0,255)'], [0.33, 'rgb(0, 255, 255)'], [0.66, 'rgb(0, 255, 0)'], [1, 'rgb(225, 125, 40)']],
                },
                cliponaxis: false,
                text: makeText(sortedMetricMean)
            }
        ]

        var layout = {
            title: `<b>[${runid !== undefined && runid !== null && runid.includes('_') ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}]</b> - Median RSQ per Tile`,          
            margin: {
                l: 40,
                //t: 10,
                b: 50
            },
            titlefont: {
                "size": 14
            },
            width: widthInit,
            height: heightInit,
            font: {
                size: 14
            },
            showlegend: false,
            polar: {
                bgcolor: "rgb(105,105,105)",
                angularaxis: {
                    tickwidth: 2,
                    linewidth: 2,
                    layer: "below traces",
                    rotation: 90,
                    dtick: 30,
                    direction: "clockwise",
                    gridwidth: 2
                },
                radialaxis: {
                    side: "clockwise",
                    showline: false,
                    visible: false,
                    linewidth: 0.1,
                    tickwidth: 0.1,
                    gridcolor: "white",
                    gridwidth: 0.1
                }
            },
            font: {
                //color: 'white'
            },
        }        

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo'],
            displaylogo: false,
            displayModeBar: false
        };

        return { data, layout, config };
    }
    catch (e) {        
        return {};
    }
}