export const RenderPolyPlot1 = (csv, runid, camera) => {
    try {
        var trace1 = {
            x: Object.keys(csv).filter(f => f === '1').map(m => m.toString()),
            y: Object.entries(csv).map(([key, value]) => {
                if (key === '1') return value;
            }).filter(m => m !== undefined).map(m => m[0] / 100),
            name: 'Monoclonal',
            type: 'bar',
            text: Object.entries(csv).map(([key, value]) => {
                if (key === '1') return value;
            }).filter(m => m !== undefined).map(m => m[0]).map(m => `${m.toFixed(2)}%`),
            textposition: 'auto',
            marker: { color: 'blue' }
        };

        var trace2 = {
            x: Object.keys(csv).filter(f => f === '2' || f === '3+').map(m => m.toString()),
            y: Object.entries(csv).map(([key, value]) => {
                if (key === '2' || key === '3+') return value;
            }).filter(m => m !== undefined).map(m => m[0] / 100),
            name: 'Polyclonal',
            type: 'bar',
            text: Object.entries(csv).map(([key, value]) => {
                if (key === '2' || key === '3+') return value;
            }).filter(m => m !== undefined).map(m => m[0]).map(m => `${m.toFixed(2)}%`),
            textposition: 'auto',
            marker: { color: 'orange' }
        };

        var trace3 = {
            x: Object.keys(csv).filter(f => f === 'Total Undetermined').map(m => m.toString()),
            y: Object.entries(csv).map(([key, value]) => {
                if (key === 'Total Undetermined') return value;
            }).filter(m => m !== undefined).map(m => m[0] / 100),
            name: 'Undetermined',
            type: 'bar',
            text: Object.entries(csv).map(([key, value]) => {
                if (key === 'Total Undetermined') return value;
            }).filter(m => m !== undefined).map(m => m[0]).map(m => `${m.toFixed(2)}%`),
            textposition: 'auto',
            marker: { color: '#800080' }
        };

        /*var trace3 = {
            x: '1', 
            y: ((parseFloat(csv['Total Estimate']) - parseFloat(csv['Total Measure']))/100).toFixed(1), 
            name: 'Polyclonal',          
            type: 'bar',
            text: ((parseFloat(csv['Total Estimate']) - parseFloat(csv['Total Measure']))).toFixed(1).toString(),
            textposition: 'auto',            
        };     */

        var data = [trace1, trace2, trace3];

        var layout = {
            title: `${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}: Polyc-BC ${csv['Total Measure'][0].toFixed(2)}% Measured (Inter BC PC),<br> ${csv['Total Estimate'][0].toFixed(2)}% Total (Inter BC PC + Intra BC PC estimation)`,
            //barmode: 'stack',
            yaxis: {
                title: 'Percentage of Sample',
                tickformat: ',.0%',
                range: [0, 1]
            },
            xaxis: {
                type: 'category',
                title: 'Number of Barcodes per Read'
            }
        };

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch (e) {
        return undefined;
    }
}

// export const RenderPolyPlot2 = (csv, runid, camera) => {
//     try {
//         let barcodesArr = csv.barcodes.names.map((m, i) => {
//             return { name: m, order: csv.barcodes.order[i] };
//         });

//         let updateBarcodesArr;
//         let exceedLimit = false;

//         if(barcodesArr.length > 25){
//             exceedLimit = true;
//             updateBarcodesArr = barcodesArr.slice(0, 25);
//         } else updateBarcodesArr = barcodesArr;

//         // Filter out 'TT' and create mapping
//         let mapping = updateBarcodesArr
//             .filter(item => item.name !== 'TT')
//             .map((item, index) => ({ name: item.name, dataIndex: index }));

//         // Sort the mapping based on barcode names
//         mapping.sort((a, b) => a.name.localeCompare(b.name));

//         // Create sorted x and y arrays
//         let updateXY = mapping.map(item => item.name);

//         // Create sorted z array
//         let updateZ = Object.entries(csv)
//             .filter(([key, _]) => key !== 'barcodes')
//             .map(([_, value]) => {
//                 return mapping.map(item => value[item.dataIndex]);
//             });

//         var trace1 = {
//             x: updateXY,
//             y: updateXY,
//             z: updateZ,
//             type: 'heatmap',
//             showscale: false,
//             colorscale: [
//                 ['0.0', 'rgb(255,255,255)'],
//                 ['0.25', 'rgb(221,221,221)'],
//                 ['0.5', 'rgb(203,203,203)'],
//                 ['0.75', 'rgb(190,190,190)'],
//                 ['1.0', 'rgb(180,180,180)']
//             ],
//         };

//         var data = [trace1];

//         var layout = {
//             title: `${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''} polyclonal confusion`,
//             annotations: [],
//             xaxis: {
//                 ticks: '',
//                 side: 'bottom',
//                 tickfont: {
//                     family: 'Arial',
//                     size: exceedLimit ? 7 : 12,
//                 },
//             },
//             yaxis: {
//                 ticks: '',
//                 ticksuffix: ' ',
//                 width: 700,
//                 height: 700,
//                 autosize: false,
//                 tickfont: {
//                     family: 'Arial',
//                     size: exceedLimit ? 7 : 12,
//                 },
//             }
//         };

//         for (var i = 0; i < updateXY.length; i++) {
//             for (var j = 0; j < updateXY.length; j++) {
//                 var result = {
//                     xref: 'x1',
//                     yref: 'y1',
//                     x: updateXY[j],
//                     y: updateXY[i],
//                     text: `${updateZ[i][j]}%`,
//                     font: {
//                         family: 'Arial',
//                         size: exceedLimit ? 7 : 12,
//                         color: 'rgb(0, 0, 0)'
//                     },
//                     showarrow: false,
//                 };
//                 layout.annotations.push(result);
//             }
//         }

//         var config = {
//             modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
//             displaylogo: false,
//             displayModeBar: true
//         };

//         return { data, layout, config };
//     }
//     catch (e) {
//         return undefined;
//     }
// }

export const RenderPolyPlot2 = (csv, runid, camera) => {
    try {
        let barcodesArr = csv.barcodes.names.map((m, i) => {
            return { name: m, order: csv.barcodes.order[i] };
        });

        let updateBarcodesArr;
        let updateXY;
        let updateZ;
        let exceedLimit = false;

        if(barcodesArr.length > 25){
            exceedLimit = true;
            updateBarcodesArr = barcodesArr.slice(0, 25);
        } else updateBarcodesArr = barcodesArr;
        //let barcodes = updateBarcodesArr.sort((firstItem, secondItem) => firstItem.order - secondItem.order);
        
        let xy = updateBarcodesArr.map(m => m.name).filter(f => f !== 'TT');
        
        let z = Object.entries(csv).map(([key, value]) => {
            if (key !== 'barcodes') return value;
        }).filter(f => f !== undefined);
        
        if(xy.length > 25){
            updateXY = xy.slice(0, 25);
        } else updateXY = xy;
        if(z.length > 25){
            updateZ = z.slice(0, 25);
        } else updateZ = z;

        var trace1 = {
            x: updateXY,
            y: updateXY,
            z: updateZ,
            type: 'heatmap',
            showscale: false,
            colorscale: [
                ['0.0', 'rgb(255,255,255)'],
                ['0.25', 'rgb(221,221,221)'],
                ['0.5', 'rgb(203,203,203)'],
                ['0.75', 'rgb(190,190,190)'],
                ['1.0', 'rgb(180,180,180)']
            ],
        };

        var data = [trace1];

        var layout = {
            title: `${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''} polyclonal confusion`,
            annotations: [],
            xaxis: {
                ticks: '',
                side: 'bottom',
                tickfont: {
                    family: 'Arial',
                    size: exceedLimit ? 7 : 12,
                },
            },
            yaxis: {
                ticks: '',
                ticksuffix: ' ',
                width: 700,
                height: 700,
                autosize: false,
                tickfont: {
                    family: 'Arial',
                    size: exceedLimit ? 7 : 12,
                },
            }
        };

        for (var i = 0; i < updateXY.length; i++) {
            for (var j = 0; j < updateXY.length; j++) {
                var result = {
                    xref: 'x1',
                    yref: 'y1',
                    x: updateXY[j],
                    y: updateXY[i],
                    text: `${z[i][j]}%`,
                    font: {
                        family: 'Arial',
                        size: exceedLimit ? 7 : 12,
                        color: 'rgb(0, 0, 0)'
                    },
                    showarrow: false,
                };
                layout.annotations.push(result);
            }
        }

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch (e) {
        return undefined;
    }
}

export const RenderPolyPlot3 = (csv, runid, camera) => {
    try {
        var data = [];
        
        Object.entries(csv).forEach(([key, value]) => {
            if (key !== 'bars') {
                data.push({
                    x: csv.bars.map(m => parseFloat(m).toFixed(1)),
                    y: value,
                    name: key.includes('passed') ? key.replace('passed', '<br /> passed') : key,
                    type: 'bar',
                    text: value,
                    textposition: 'auto',
                });
            }
        });

        var layout = {
            title: `RSQ Distribution ${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}`,
            //barmode: 'stack',
            yaxis: {
                title: '# Reads',
            },
            xaxis: {
                type: 'category',
                title: 'RSQ'
            },
                x: 0.95,
                y: 1.0,
                font: {
                    size: 12
                },
        };

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch (e) {
        return undefined;
    }
}