import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import SearchRangeRow from './searchrangerow';
import { setCustomFilters, } from "../actions/index";
import { Row, Col, } from "react-bootstrap";

const Accuracy = () => {   
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const updateState = (col, data) => {
        let filters = state.custom_filters;
        if (col === 'template' || col === 'library' || col === 'upload' || col === 'qc') filters.analysisstatus.series[col] = { ...filters.analysisstatus.series[col], ...data.data };        
        else if (col === 'analysisstatus') filters.analysisstatus.op = data.op;        
        else filters[col] = { ...filters[col], ...data };
        dispatch(setCustomFilters(filters));
    }

    return (
        <Row className="text-center">
            <Col lg={12}>
                <table>
                    <tbody>
                        <SearchRangeRow
                            title="Indel Rate TT (%)" col="indel_rate"
                            op={state.custom_filters.indel_rate != undefined ? state.custom_filters.indel_rate.op : ''}
                            min={state.custom_filters.indel_rate.min} max={state.custom_filters.indel_rate.max}
                            low={state.custom_filters.indel_rate.low} high={state.custom_filters.indel_rate.high}
                            updateState={updateState} dirty={state.custom_filters.indel_rate.dirty} saved={state.custom_filters.indel_rate.saved}
                            disableLow={state.custom_filters.indel_rate.disableLow} disableHigh={state.custom_filters.indel_rate.disableHigh} 
                            disableSlider={state.custom_filters.indel_rate.disableSlider} />
                            
                        <SearchRangeRow
                            title="STS" col="sts"
                            op={state.custom_filters.sts != undefined ? state.custom_filters.sts.op : ''}
                            min={state.custom_filters.sts.min} max={state.custom_filters.sts.max}
                            low={state.custom_filters.sts.low} high={state.custom_filters.sts.high}
                            updateState={updateState} />

                        <SearchRangeRow
                            title="R80 (%)" col="r80"
                            op={state.custom_filters.r80 != undefined ? state.custom_filters.r80.op : ''}
                            min={state.custom_filters.r80.min} max={state.custom_filters.r80.max}
                            low={state.custom_filters.r80.low} high={state.custom_filters.r80.high}
                            updateState={updateState} />

                        <SearchRangeRow
                            title="Ber80 @200 (%)" col="ber80_200"
                            op={state.custom_filters.ber80_200 != undefined ? state.custom_filters.ber80_200.op : ''}
                            min={state.custom_filters.ber80_200.min} max={state.custom_filters.ber80_200.max}
                            low={state.custom_filters.ber80_200.low} high={state.custom_filters.ber80_200.high}
                            updateState={updateState} />  

                        <SearchRangeRow
                            title="Ber80 (%)" col="ber80"
                            op={state.custom_filters.ber80 != undefined ? state.custom_filters.ber80.op : ''}
                            min={state.custom_filters.ber80.min} max={state.custom_filters.ber80.max}
                            low={state.custom_filters.ber80.low} high={state.custom_filters.ber80.high}
                            updateState={updateState} />                                            

                    </tbody>
                </table>
            </Col>
        </Row>
    );
}

export default Accuracy;