import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setBeadsFilteringTableRenderData, setBarcodeStatisticsTableRenderData, setReadsFilteringPlotByCameraValue, setBeadsFilteringPlotIsLoading, setBeadsFilterTablePdf } from "../actions/index";
import { Row, Col, Table, } from "react-bootstrap";
import Image from '../image/image';
import * as Utils from '../../utils/utils';
import RenderReadsFilteringCharts from '../../plots/readsfilteringplots/readsfilteringplots';
import RenderPlot from '../plots/renderplot';
import useDeviceQuery from '../../utils/hooks/useDeviceMedia';

const ReadFiltering = (props) => {
    const dispatch = useDispatch();

    const state = useSelector(state => state);

    const [dataTable1, setDataTable1] = useState([]);
    const [dataTable1H5, setDataTable1H5] = useState(undefined);
    const [dataTable2, setDataTable2] = useState([]);
    const [dataPlots, setDataPlots] = useState([]);
    const [dataPlotsH5, setDataPlotsH5] = useState([]);    
    const [renderMenuItemsData, setRenderMenuItemsData] = useState([]);
    const { isMedium1Screen, isMedium2Screen, isSmallScreen } = useDeviceQuery();

    useEffect(() => {
        setDataTable1([]);
        setDataTable1H5(undefined);
        setDataTable2([]);
        setDataPlots([]);
        setDataPlotsH5(undefined);        
        dispatch(setReadsFilteringPlotByCameraValue('unified'));
        Utils.purgePlot('ReadsFilteringPlot');
    }, [state.runId]);

    useEffect(() => {
        try {
            let exists = state.beads_filtering_plots.filter(f => f.runid === state.runId);
            let existsJson = state.beads_filtering_table_data.filter(f => f.runid === state.runId);
            if (exists !== undefined && exists.length > 0) {
                if (exists[0].data !== undefined && exists[0].data.type === 'h5' && exists[0].data.data !== undefined) {
                    let data = exists[0].data.data[state.by_camera_value_global];

                    let total_reads = data.barcodes.indexOf('allReads') !== -1 ? (data.total_reads_passed[data.barcodes.indexOf('allReads')] + data.total_reads_failed[data.barcodes.indexOf('allReads')]).toFixed(1) : (data.total_reads_passed[data.barcodes.indexOf('allReads')] + data.total_reads_failed[data.barcodes.indexOf('allReads')]);
                    let total_reads_perc = data.barcodes.indexOf('allReads') !== -1 ? (data.passed_rsq[data.barcodes.indexOf('allReads')] + data.failed_rsq[data.barcodes.indexOf('allReads')]).toFixed(2) : (data.passed_rsq[data.barcodes.indexOf('allReads')] + data.failed_rsq[data.barcodes.indexOf('allReads')]);
                    let total_reads_passed = data.barcodes.indexOf('allReads') !== -1 ? (data.total_reads_passed[data.barcodes.indexOf('allReads')]).toFixed(1) : (data.total_reads_passed[data.barcodes.indexOf('allReads')]);
                    let total_reads_passed_perc = data.barcodes.indexOf('allReads') !== -1 ? (data.passed_rsq[data.barcodes.indexOf('allReads')]).toFixed(2) : (data.passed_rsq[data.barcodes.indexOf('allReads')]);
                    let total_reads_bc_matched = data.barcodes.indexOf('BarcodeIdentified') !== -1 ? (data.total_reads_passed[data.barcodes.indexOf('BarcodeIdentified')]).toFixed(1) : (data.total_reads_passed[data.barcodes.indexOf('BarcodeIdentified')]);
                    let total_reads_bc_matched_perc = data.barcodes.indexOf('BarcodeIdentified') !== -1 ? data.passed_rsq[data.barcodes.indexOf('BarcodeIdentified')].toFixed(2) : data.passed_rsq[data.barcodes.indexOf('BarcodeIdentified')];
                    RenderBeadsFilteringH5Table({ total_reads, total_reads_perc, total_reads_passed, total_reads_passed_perc, total_reads_bc_matched, total_reads_bc_matched_perc });
                }
                else if (existsJson !== undefined && existsJson.length > 0 && existsJson[0].data !== undefined) {
                    if (state.beads_filtering_table_data.filter(f => f.runid === state.runId).length > 0) {
                        RenderBeadsFilteringTable(state.beads_filtering_table_data.filter(f => f.runid === state.runId)[0].data);
                    }
                    else {
                        setDataTable1([]);
                    }
                }
            }
            else if (existsJson !== undefined && existsJson.length > 0 && existsJson[0].data !== undefined) {
                if (state.beads_filtering_table_data.filter(f => f.runid === state.runId).length > 0) {
                    RenderBeadsFilteringTable(state.beads_filtering_table_data.filter(f => f.runid === state.runId)[0].data);
                }
                else {
                    setDataTable1([]);
                }
            }
            else {
                setDataTable1([]);
                setDataTable1H5(undefined);
            }
        }
        catch (e) {
            setDataTable1([]);
            setDataTable1H5(undefined);
        }

    }, [state.beads_filtering_table_data, state.runId, state.beads_filtering_plots]);

    const RenderBeadsFilteringTable = data => {
        try {
            if (data != undefined && data != null) {
                let renderData = data.filter(m => m[0] != '' && m[0] != "Pass RSQ" && m[0] != "HG" && m[0] != "Pass short");
                if (renderData && renderData.length) {
                    renderData.map(m => {
                        if (m.length >= 3) {
                            switch (m[0]) {
                                case "Pass 3CZ":
                                    m[0] = "Pass filters";
                                    break;
                                case "no variants":
                                    m[0] = "SNP filtered";
                                    break;
                                case "Aligned & BC":
                                    m[0] = "BC matched";
                                    break;
                                default:
                                    break;
                            }
                            m[1] = (parseInt(m[1]) / 1000000).toFixed(1);
                            m[2] = parseFloat(m[2]).toFixed(1);
                        }
                    });
                    dispatch(setBeadsFilteringTableRenderData(renderData, state.runId));
                    setDataTable1(renderData);
                }
                else {
                    setDataTable1([]);
                }
            }
            else setDataTable1([]);
        }
        catch (e) {
            setDataTable1([]);
        }
    }

    const RenderBeadsFilteringH5Table = data => {
        try {
            if (data != undefined && data != null){
                setDataTable1H5(data);
                let formattedData = Utils.formatBeadsTableData(data);
                dispatch(setBeadsFilterTablePdf(formattedData));
            } else setDataTable1H5(undefined);
            dispatch(setBeadsFilteringPlotIsLoading(false));
        }
        catch (e) {
            setDataTable1H5(undefined);
            dispatch(setBeadsFilteringPlotIsLoading(false));
        }
    }

    useEffect(() => {
        if (state.barcode_statistics_table_data.filter(f => f.runid === state.runId).length > 0) {
            RenderBarcodeStatisticsTable(state.barcode_statistics_table_data.filter(f => f.runid === state.runId)[0].data);
        }
        else setDataTable2([]);

    }, [state.barcode_statistics_table_data, state.runId]);

    const RenderBarcodeStatisticsTable = data => {
        try {
            if (data != undefined && data != null) {
                data.map((m, i) => {
                    if (i === 0) {
                        m.map((n, j) => {
                            if (n === "CV")
                                data[0][j] = "Population CV";
                            if (n === "mean/min")
                                data[0][j] = "Mean/Min";
                            if (n === "Other (%)")
                                data[0][j] = "Unlisted BC (%)";
                        });
                    }
                });

                dispatch(setBarcodeStatisticsTableRenderData(data, state.runId));
                setDataTable2(data);
            }
            else setDataTable2([]);
        }
        catch {
            setDataTable2([]);
        }
    }

    useEffect(() => {
        try {
            let exists = state.beads_filtering_plots.filter(f => f.runid === state.runId);
            if (exists !== undefined && exists.length > 0) {
                if (exists[0].data !== undefined && exists[0].data.type === 'h5' && exists[0].data.data !== undefined) {
                    let plots = undefined;
                    let data = exists[0].data.data[state.by_camera_value_global];
                    if (Utils.isObjectEmpty(data) || data === undefined) {
                        let camera = Utils.getRunIdByCamera(state.runId);
                        let data = exists[0].data.data[`camera_${camera.camera}`];                        
                        if (data !== undefined) plots = RenderReadsFilteringCharts(data, state.runId, state.by_camera_value_global);
                    }
                    else plots = RenderReadsFilteringCharts(data, state.runId, state.by_camera_value_global);

                    if (plots != undefined) {
                        setDataPlotsH5(plots);
                    }
                    else {
                        setDataPlotsH5(undefined);
                    }

                    dispatch(setBeadsFilteringPlotIsLoading(false));
                }
                else if (exists[0].data !== undefined && exists[0].data.type === 'png' && exists[0].data.data !== undefined) {
                    setDataPlots(exists[0].data.data);
                    setDataPlotsH5(undefined);
                }
                else {
                    setDataPlots(undefined);
                    setDataPlotsH5(undefined);
                    dispatch(setBeadsFilteringPlotIsLoading(false));
                }
            }
        }
        catch {
            setDataPlots(undefined);
            setDataPlotsH5(undefined);
            dispatch(setBeadsFilteringPlotIsLoading(false));
        }
    }, [state.beads_filtering_plots, state.runId]);

    useEffect(() => {
        try {
            Utils.purgePlot('ReadsFilteringPlot'); 
            let exists = state.beads_filtering_plots.filter(f => f.runid === state.runId);
            if (exists !== undefined && exists.length > 0) {
                if (exists[0].data !== undefined && exists[0].data.type === 'h5' && exists[0].data.data !== undefined) {
                    let data = exists[0].data.data[state.by_camera_value_global];
                    let plots = RenderReadsFilteringCharts(data, state.runId, state.by_camera_value_global);

                    if (plots != undefined) setDataPlotsH5(plots);
                    else setDataPlotsH5(undefined);

                    let total_reads = data.barcodes.indexOf('allReads') !== -1 ? (data.total_reads_passed[data.barcodes.indexOf('allReads')] + data.total_reads_failed[data.barcodes.indexOf('allReads')]).toFixed(1) : (data.total_reads_passed[data.barcodes.indexOf('allReads')] + data.total_reads_failed[data.barcodes.indexOf('allReads')]);
                    let total_reads_perc = data.barcodes.indexOf('allReads') !== -1 ? (data.passed_rsq[data.barcodes.indexOf('allReads')] + data.failed_rsq[data.barcodes.indexOf('allReads')]).toFixed(2) : (data.passed_rsq[data.barcodes.indexOf('allReads')] + data.failed_rsq[data.barcodes.indexOf('allReads')]);
                    let total_reads_passed = data.barcodes.indexOf('allReads') !== -1 ? (data.total_reads_passed[data.barcodes.indexOf('allReads')]).toFixed(1) : (data.total_reads_passed[data.barcodes.indexOf('allReads')]);
                    let total_reads_passed_perc = data.barcodes.indexOf('allReads') !== -1 ? (data.passed_rsq[data.barcodes.indexOf('allReads')]).toFixed(2) : (data.passed_rsq[data.barcodes.indexOf('allReads')]);
                    let total_reads_bc_matched = data.barcodes.indexOf('BarcodeIdentified') !== -1 ? (data.total_reads_passed[data.barcodes.indexOf('BarcodeIdentified')]).toFixed(1) : (data.total_reads_passed[data.barcodes.indexOf('BarcodeIdentified')]);
                    let total_reads_bc_matched_perc = data.barcodes.indexOf('BarcodeIdentified') !== -1 ? data.passed_rsq[data.barcodes.indexOf('BarcodeIdentified')].toFixed(2) : data.passed_rsq[data.barcodes.indexOf('BarcodeIdentified')];
                    RenderBeadsFilteringH5Table({ total_reads, total_reads_perc, total_reads_passed, total_reads_passed_perc, total_reads_bc_matched, total_reads_bc_matched_perc });

                    dispatch(setBeadsFilteringPlotIsLoading(false));
                }
                else if (exists[0].data !== undefined && exists[0].data.type === 'png' && exists[0].data.data !== undefined) {
                    setDataPlots(exists[0].data.data);
                }
                else {
                    setDataPlots(undefined);
                    setDataPlotsH5(undefined);
                    dispatch(setBeadsFilteringPlotIsLoading(false));
                }
            }
        }
        catch (e) {
            setDataPlots(undefined);
            setDataPlotsH5(undefined);
            dispatch(setBeadsFilteringPlotIsLoading(false));
        }
    }, [state.by_camera_value_global]);  

    return (
        <React.Fragment>
            <Col lg="3" md="6" sm="12" xs="12">
                {dataTable1.length > 0 ?
                    <Table striped bordered hover style={{ marginTop: 20 }}>
                        <thead>
                        </thead>
                        <tbody>
                            <tr>
                                <th>UA statistics</th>
                                <th>M reads</th>
                                <th>% of sample</th>
                            </tr>
                            {dataTable1.length > 0 && dataTable1.map((item, i) => {
                                if (item.length >= 3)
                                    return <tr key={Utils.generateKey(i)}>
                                        <td key={Utils.generateKey(i)}><strong>{item[0]}</strong></td>
                                        <td key={Utils.generateKey(i)}>{item[1]}</td>
                                        <td key={Utils.generateKey(i)}>{item[2]}</td>
                                    </tr>;
                            })}
                        </tbody>
                    </Table> : null
                }
                {dataTable1H5 !== undefined &&
                    <Table striped bordered hover style={{ marginTop: 20 }}>
                        <thead>
                        </thead>
                        <tbody>
                            <tr>
                                <th>UA statistics</th>
                                <th>M reads</th>
                                <th>% of sample</th>
                            </tr>
                            <tr key={Utils.generateKey(1)}>
                                <td key={Utils.generateKey(2)}><strong>{'Total'}</strong></td>
                                <td key={Utils.generateKey(3)}>{dataTable1H5.total_reads}</td>
                                <td key={Utils.generateKey(4)}>{dataTable1H5.total_reads_perc}</td>
                            </tr>
                            <tr key={Utils.generateKey(1)}>
                                <td key={Utils.generateKey(2)}><strong>{'Pass filter'}</strong></td>
                                <td key={Utils.generateKey(3)}>{dataTable1H5.total_reads_passed}</td>
                                <td key={Utils.generateKey(4)}>{dataTable1H5.total_reads_passed_perc}</td>
                            </tr>
                            <tr key={Utils.generateKey(1)}>
                                <td key={Utils.generateKey(2)}><strong>{'BC matched'}</strong></td>
                                <td key={Utils.generateKey(3)}>{dataTable1H5.total_reads_bc_matched}</td>
                                <td key={Utils.generateKey(4)}>{dataTable1H5.total_reads_bc_matched_perc}</td>
                            </tr>
                        </tbody>
                    </Table>
                }
                {dataTable2.length > 0 ?
                    <Table striped bordered hover style={{ marginTop: 20 }}>
                        <tbody>
                            <tr>
                                <th colSpan={3}>Barcode pooling statistics</th>
                            </tr>
                            {dataTable2.length > 0 && dataTable2.map((item, i) => {
                                if (item.length >= 3)
                                    return <tr key={Utils.generateKey(i)}>
                                        <td key={Utils.generateKey(i)}>{item[0]}</td>
                                        <td key={Utils.generateKey(i)}>{item[1]}</td>
                                        <td key={Utils.generateKey(i)}>{item[2]}</td>
                                    </tr>;
                            })}
                        </tbody>
                    </Table> : null
                }
            </Col>
            <Col lg={9} md={6} sm={12}>
                {(dataPlots !== undefined) &&
                    <Row>
                        <Col lg={6} md={12} sm={12}>
                            {(dataPlots !== undefined && dataPlots.plot1 != undefined) && <Image src={dataPlots.plot1} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />}
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            {(dataPlots !== undefined && dataPlots.plot2 != undefined) && <Image src={dataPlots.plot2} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />}
                        </Col>
                    </Row>
                }
                {(dataPlotsH5 !== undefined) &&
                    <Row>
                        <Col lg={{ span: 12, }}>
                            <Row>                              
                                {<RenderPlot id="ReadsFilteringPlot" data={(dataPlotsH5 !== undefined && dataPlotsH5.data) && dataPlotsH5.data} layout={(dataPlotsH5 !== undefined && dataPlotsH5.layout) && dataPlotsH5.layout} config={(dataPlotsH5 !== undefined && dataPlotsH5.config) && dataPlotsH5.config} isLoading={state.beads_filtering_plot_is_loading}
                                    type={'rounded'} width={'100%'} height={300} />}
                            </Row>
                        </Col>
                    </Row>
                }
            </Col>
        </React.Fragment>
    );

};

export default ReadFiltering;