import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { Row, Col, } from "react-bootstrap";

const valuetext = value => {
    return `${value}`;
}

const RangeSliderCommon = (props) => {
    const { defaultValue, onChange, disabled, getAriaLabel, valueLabelDisplay } = props;

    const [sliderValue, setSliderValue] = React.useState([]);
    const [minSliderInputValue, setMinSliderInputValue] = React.useState(defaultValue[0]);
    const [maxSliderInputValue, setMaxSliderInputValue] = React.useState(defaultValue[1]);
    const [initValue, setInitValue] = React.useState(defaultValue);

    React.useEffect(() => {
        if (defaultValue !== undefined && defaultValue.length >= 2) {
            setInitValue(defaultValue);
        }
    }, []);

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        onChange(newValue);
        setMinSliderInputValue(parseInt(newValue[0])); 
        setMaxSliderInputValue(parseInt(newValue[1]));
    };

    const handleMinInputChange = (e) => {        
        setSliderValue([parseInt(e.target.value), sliderValue[1]]);
        onChange([parseInt(e.target.value), sliderValue[1]])
        setMinSliderInputValue(parseInt(e.target.value));              
    };
   

    const handleMaxInputChange = (e) => {
        setSliderValue([sliderValue[0], parseInt(e.target.value)]);
        onChange([sliderValue[0], parseInt(e.target.value)]);
        setMaxSliderInputValue(parseInt(e.target.value));        
    };

    React.useEffect(() => {
        if (defaultValue !== undefined && defaultValue.length >= 2) {            
            setSliderValue(defaultValue);            
        }
    }, [defaultValue]); 

    return (
        <Box>
            <Row>
                <Col lg={2}>
                    <TextField
                        id="outlined-uncontrolled"
                        label="Min:"
                        onChange={handleMinInputChange}
                        value={minSliderInputValue}
                        //defaultValue={minSliderInputValue}
                        type="number"
                        size="small"
                        inputProps={{style: { textAlign: 'center' }}} 
                    />
                </Col>
                <Col lg={8} style={{marginTop: 10}}>
                    <Slider
                        getAriaLabel={getAriaLabel}
                        value={sliderValue}
                        min={0}
                        max={initValue[1] * 2}
                        defaultValue={[parseInt(initValue[0]), parseInt(initValue[1])]}
                        onChange={handleSliderChange}
                        getAriaValueText={valuetext}
                        valueLabelDisplay={valueLabelDisplay}
                        disabled={disabled}
                    />
                </Col>
                <Col lg={2}>
                    <TextField
                        id="outlined-uncontrolled"
                        label="Max:"
                        onChange={handleMaxInputChange}
                        value={maxSliderInputValue}
                        //defaultValue={maxSliderInputValue}
                        type="number"
                        size="small"  
                        inputProps={{style: { textAlign: 'center'}}}                     
                    />
                </Col>
            </Row>
        </Box>
    );
}

export default RangeSliderCommon;
