export const RenderEPCRChart = (csv, isInit, chartWidth, chartHeight) => {
    try {
        let data = [];
        function unpack(rows, key) {
            return rows.map(function (row) { return parseFloat(row[key]).toFixed(2); });
        }

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        function unpackByName(rows, key, name) {
            let tmp = rows.filter(function (row) {
                return row.Name === name;
            });
            return tmp.map(function (row) { return row[key]; });
        }

        if (isInit) {
            let values =
                [
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "HotTank Temperature"
                    },
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "ColdTank Temperature"
                    },
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "Lid Temperature"
                    },
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "Block Temperature"
                    }
                ];

                values.forEach(function (i, item) {
                let data_temp = {
                    type: "scatter",
                    mode: "lines",
                    name: item.name,
                    x: [],
                    y: [],
                    //line: { color: '#1f77b4' },        
                };
                data.push(data_temp);
            });
        }
        else {
            let values = groupBy(csv, 'Name');

            for (const [key, value] of Object.entries(values)) {
                let data_temp = {
                    type: "scatter",
                    mode: "lines",
                    name: key,
                    x: unpackByName(value, 'TimeStamp', key),
                    y: unpack(value, 'Value'),
                    //line: { color: '#1f77b4' },        
                };
                data.push(data_temp);
            }            
        }

        let layout = {};
        if (chartWidth !== undefined && chartHeight !== undefined) {
            layout = {
                //plot_bgcolor: "#303030",
                //paper_bgcolor: "#303030",
                margin: {
                    t: 40,
                    l: 45
                },
                titlefont: {
                    "size": 14
                },
                width: chartWidth,
                height: chartHeight,
                xaxis: {
                    autotick: true,
                    ticks: 'outside',
                    tickformat: '%X',
                    //tick0: 0,
                    dtick: 1,
                    //tickcolor: 'white',
                    showline: true,
                    tickfont: {
                        size: 10,
                        //color: 'white'
                    },
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    tick0: 0,
                    dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                        //color: 'white'
                    },
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                        //color: 'white'
                    },
                }
            };
        }
        else {
            layout = {
                //plot_bgcolor: "#303030",
                //paper_bgcolor: "#303030",
                margin: {
                    t: 40,
                    l: 45
                },
                titlefont: {
                    "size": 14
                },
                //width: 450,
                //height: 450,
                xaxis: {
                    autotick: true,
                    ticks: 'outside',
                    tickformat: '%X',
                    //tick0: 0,
                    dtick: 1,
                    //tickcolor: 'white',
                    showline: true,
                    tickfont: {
                        size: 10,
                       //color: 'white'
                    },
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    tick0: 0,
                    dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                        //color: 'white'
                    },
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                        //color: 'white'
                    },
                }
            };
        }

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,           
        };

        return { data, layout, config };
    }
    catch (e) {
        return {};
    }
}

export const RenderEPCRChartHealth = (csv, isInit) => {
    try {
        let data = [];
        let data1 = [];
        function unpack(rows, key) {
            return rows.map(function (row) { return parseFloat(row[key]).toFixed(2); });
        }

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        function unpackByName(rows, key, name) {
            let tmp = rows.filter(function (row) {
                return row.Name === name;
            });
            return tmp.map(function (row) { return row[key]; });
        }

        if (isInit) {
            let values =
                [
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "Hot Tank Pressure"
                    },
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "Cold Tank Pressure"
                    },
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "Flow 1 Pressure"
                    },
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "Flow 2 Pressure"
                    }
                ];

            let values1 =
                [
                    {
                        "value": "",
                        "timeStamp": "",
                        "name": "Flowmeter speed"
                    }
                ];

                values.forEach(function (i, item) {
                if (item.name.trim() === 'Hot Tank Pressure' || item.name.trim() === 'Cold Tank Pressure' || item.name.trim() === 'Flow 1 Pressure' || item.name.trim() === 'Flow 2 Pressure') {
                    let data_temp = {
                        type: "scatter",
                        mode: "lines",
                        name: item.name,
                        x: [],
                        y: [],
                        //line: { color: '#1f77b4' },        
                    };
                    data.push(data_temp);
                }
            });

            values1.forEach(function (i, item) {
                if (item.name.trim() === 'Flowmeter speed') {
                    let data_temp = {
                        type: "scatter",
                        mode: "lines",
                        name: item.name,
                        x: [],
                        y: [],
                        //line: { color: '#1f77b4' },        
                    };
                    data1.push(data_temp);
                }
            });
        }
        else {
            let values = groupBy(csv, 'Name');

            for (const [key, value] of Object.entries(values)) {
                if (key.trim() === 'Hot Tank Pressure' || key.trim() === 'Cold Tank Pressure' || key.trim() === 'Flow 1 Pressure' || key === 'Flow 2 Pressure') {
                    let data_temp = {
                        type: "scatter",
                        mode: "lines",
                        name: key,
                        x: unpackByName(value, 'TimeStamp', key),
                        y: unpack(value, 'Value'),
                        //line: { color: '#1f77b4' },        
                    };
                    data.push(data_temp);
                }                
            }    

            for (const [key, value] of Object.entries(values)) {
                if (key.trim() === 'Flowmeter speed') {
                    let data_temp = {
                        type: "scatter",
                        mode: "lines",
                        name: key,
                        x: unpackByName(value, 'TimeStamp', key),
                        y: unpack(value, 'Value'),
                        //line: { color: '#1f77b4' },        
                    };
                    data1.push(data_temp);
                }                
            }
        }

        let layout = {
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            margin: {
                t: 40,
                l: 45
            },
            titlefont: {
                "size": 14
            },
            //width: 450,
            //height: 450,
            xaxis: {
                autotick: true,
                ticks: 'outside',
                tickformat: '%X',
                //tick0: 0,
                dtick: 1,
                //tickcolor: 'white',
                showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
            },
            yaxis: {
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                tick0: 0,
                //dtick: 5,
                showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
            },
            showlegend: true,
            legend: {
                font: {
                    size: 10,
                    //color: 'white'
                },
            }
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,           
        };

        return data1.length ? { data, layout, data1, 'layout1': layout, config } : { data, layout, config };
    }
    catch (e) {
        return {};
    }
}

export const RenderEPCRChartHealthAllTemperatures = (csv) => {
    try {
        let data = [];       

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        function unpackByType(rows, key, type) {
            let tmp = rows.filter(function (row) {
                return row.Type === type;
            });
            return tmp.map(function (row) { return row[key]; });
        }

        let values = groupBy(csv, 'Key');

        for (const [key, value] of Object.entries(values)) {
            let data_temp = {
                type: "scatter",
                mode: "lines",
                name: key,
                x: unpackByType(value, 'Date', 'Temperature'),
                y: unpackByType(value, 'Value', 'Temperature'),
                //line: { color: '#1f77b4' },        
            };
            data.push(data_temp);
        }

        let layout = {
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            margin: {
                t: 40,
                l: 45
            },
            titlefont: {
                "size": 14
            },
            //width: 850,
            height: 650,
            xaxis: {
                type: 'date',
                tickformat: '%x %X',
                //tick0: 0,
                //dtick: 1,
                //tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
            },
            yaxis: {
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                tick0: 0,
                //dtick: 5,
                showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
            },
            showlegend: true,
            legend: {
                font: {
                    size: 10,
                    //color: 'white'
                },
            }
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,           
        };

        return { data, layout, config };
    }
    catch {
        return {};
    }
}

export const RenderEPCRChartHealthAllPressures = (csv) => {
    try {
        let data = [];       

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        function unpackByType(rows, key, type) {
            let tmp = rows.filter(function (row) {
                return row.Type === type;
            });
            return tmp.map(function (row) { return row[key]; });
        }

        let values = groupBy(csv, 'Key');

        for (const [key, value] of Object.entries(values)) {
            let data_temp = {
                type: "scatter",
                mode: "lines",
                name: key,
                x: unpackByType(value, 'Date', 'Pressure'),
                y: unpackByType(value, 'Value', 'Pressure'),
                //line: { color: '#1f77b4' },        
            };
            data.push(data_temp);
        }


        let layout = {
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            margin: {
                t: 40,
                l: 45
            },
            titlefont: {
                "size": 14
            },
            //width: 850,
            height: 650,
            xaxis: {
                type: 'date',
                tickformat: '%x %X',
                //tick0: 0,
                //dtick: 1,
                //tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
            },
            yaxis: {
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                tick0: 0,
                //dtick: 5,
                showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
            },
            showlegend: true,
            legend: {
                font: {
                    size: 10,
                    //color: 'white'
                },
            }
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,           
        };

        return { data, layout, config };
    }
    catch {
        return {};
    }
}

export const RenderEPCRChartCompare = (csvs) => {
    try {
        let data = [];

        function unpack(rows, key) {
            return rows.map(function (row) { return parseFloat(row[key]).toFixed(2); });
        }

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        function unpackByName(rows, key, name) {
            let tmp = rows.filter(function (row) {
                return row.name === name;
            });
            return tmp.map(function (row) { return row[key]; });
        }

        function ParseDirName(dirName) {
            let result;
            if (dirName != null) {
                let spl = dirName.split('_');
                let dtStr = spl[0];
                let dtTmp = dtStr.split('-');
                let date = `${dtTmp[1]}/${dtTmp[2]}/${dtTmp[0]}`;
                let tsStr = spl[1];
                let tsTmp = tsStr.split('-');
                let timestamp = `${tsTmp[0]}:${tsTmp[1]}:${tsTmp[2]}`;
                let descr;
                if (spl.length === 4) {
                    descr = `${spl[2]} ${spl[3]}`;
                }
                else descr = spl[2];
                result = `[${date} ${timestamp}] ${descr}`;
            }
            return result;
        }

        csvs.forEach(function (i, csv) {
            let values = groupBy(csv.value, 'name');
            values.forEach(function (i, item) {
                if (item[0].name === 'Block Temperature') {
                    let data_temp = {
                        type: "scatter",
                        mode: "lines",
                        name: ParseDirName(csv.name),
                        x: unpackByName(csv.value, 'timeStamp', item[0].name),
                        y: unpack(item, 'value'),
                        //line: { color: '#1f77b4' },        
                    };
                    data.push(data_temp);
                }
            });
        });

        let layout = {
            plot_bgcolor: "#303030",
            paper_bgcolor: "#303030",
            margin: {
                t: 40,
                l: 45
            },
            titlefont: {
                "size": 14
            },
            //width: 450,
            //height: 450,
            xaxis: {
                autotick: true,
                ticks: 'outside',
                tickformat: '%X',
                //tick0: 0,
                dtick: 1,
                tickcolor: 'white',
                showline: true,
                tickfont: {
                    size: 10,
                    color: 'white'
                },
            },
            yaxis: {
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                tick0: 0,
                dtick: 5,
                showline: true,
                tickfont: {
                    size: 10,
                    color: 'white'
                },
            },
            showlegend: true,
            legend: {
                font: {
                    size: 10,
                    color: 'white'
                },
            }
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,           
        };

        return { data, layout, config };  
    }
    catch (e) {
        return {};
    }
}

export const RenderEPCRChartCompareStacked = (csvs) => {
    try {
        let data = [];

        function unpack(rows, key) {
            return rows.map(function (row) { return parseFloat(row[key]).toFixed(2); });
        }

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        function unpackByName(rows, key, name) {
            let tmp = rows.filter(function (row) {
                return row.name === name;
            });
            return tmp.map(function (row) { return row[key]; });
        }

        function ParseDirName(dirName) {
            let result;
            if (dirName != null) {
                let spl = dirName.split('_');
                let dtStr = spl[0];
                let dtTmp = dtStr.split('-');
                let date = `${dtTmp[1]}/${dtTmp[2]}/${dtTmp[0]}`;
                let tsStr = spl[1];
                let tsTmp = tsStr.split('-');
                let timestamp = `${tsTmp[0]}:${tsTmp[1]}:${tsTmp[2]}`;
                let descr;
                if (spl.length === 4) {
                    descr = `${spl[2]} ${spl[3]}`;
                }
                else descr = spl[2];
                result = `[${date} ${timestamp}] ${descr}`;
            }
            return result;
        }

        let filteredData = [];
        csvs.filter(function (row) {
            if (row.value.length > 0) {
                filteredData.push(row);
            }
        });

        filteredData.forEach(function (i, csv) {
            let values = groupBy(csv.value, 'name');
            let xaxisVal = i !== 0 ? `x${i + 1}` : 'x';
            values.forEach(function (j, item) {
                if (item[0].name === 'Block Temperature' && item.length > 0) {
                    let data_temp = {
                        type: "scatter",
                        mode: "lines",
                        name: ParseDirName(csv.name),
                        x: unpackByName(csv.value, 'timeStamp', item[0].name),
                        y: unpack(item, 'value'),
                        xaxis: xaxisVal,
                        //line: { color: '#1f77b4' },        
                    };
                    data.push(data_temp);
                }
            });
        });

        function AddAxis(data, layout) {
            for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                    let xaxis = {
                        domain: [0, 1 / data.length],
                        autotick: true,
                        ticks: 'outside',
                        //tickformat: '%X',
                        tickformat: '%H %M %S',
                        dtick: 1,
                        tickcolor: 'white',
                        showline: true,
                        tickfont: {
                            size: 10,
                            color: 'white'
                        },
                        type: 'date'
                    };
                    layout['xaxis'] = xaxis;
                }
                else {
                    let xaxis = {
                        domain: [(1 / data.length) * i, i === data.length - 1 ? 1 : (1 / data.length) * (i + 1)],
                        autotick: true,
                        ticks: 'outside',
                        //tickformat: '%X',
                        tickformat: '%H %M %S',
                        dtick: 1,
                        tickcolor: 'white',
                        showline: true,
                        tickfont: {
                            size: 10,
                            color: 'white'
                        },
                        type: 'date'
                    };
                    layout[`xaxis${i + 1}`] = xaxis;

                }

            }
            return layout;
        }

        let layout = {
            plot_bgcolor: "#303030",
            paper_bgcolor: "#303030",
            margin: {
                t: 40,
                l: 45
            },
            titlefont: {
                "size": 14
            },
            width: 1000,
            height: 500,
            yaxis: {
                //name: 'Block Temperature',
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                tick0: 0,
                dtick: 5,
                showline: true,
                tickfont: {
                    size: 10,
                    color: 'white'
                },

            },
            showlegend: true,
            legend: {
                traceorder: 'reversed',
                font: {
                    size: 10,
                    color: 'white'
                },
            }
        };

        let tempLayout = AddAxis(filteredData, layout);

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true,           
        };

        return { data, 'layout': tempLayout, config };       
    }
    catch (e) {
        return {};
    }
}