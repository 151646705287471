import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Tabs, Tab, ListGroup, Container, Row, Col, Nav, Button, Table, OverlayTrigger, Popover, Overlay } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import RenderPlot from '../plots/renderplot';
import ShowAlert from '../showalert/showalert';
import LoadingSpinner from '../spinner/spinner';
import {
    getOpticsPlotsDataAsync, getBeadLossPlots, getHealthPlotsTemperatureData, getHealthPlotsHumidityData, setOpticsPlotsRenderData, setActiveInstrHealthSubTabName, setImgDeformRenderData, setHealtDataHumidityPlotRenderData, setHealtDataTemperaturePlotRenderData, getImgDeformDataAsync, getSAMReport, getFWHMDataAsync,
    setIsFWHMPlotsLoading, getFWHMDataManyAsync, getFWHMCSVData, setIsSignalPlotsLoading
} from "../actions/index";
import { getFWHMCSVDataAsync, } from "../../services/apiService";
import * as Utils from '../../utils/utils';
import * as FlowSpinner from '../flowspinner/flowspinner';
import Image from '../image/image';
import * as RenderOpticsCharts from '../../plots/opticsplots/opticsplots';
import * as RenderHealthPlots from '../../plots/healthplots/healthplots';
import * as mpld3 from 'mpld3';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import IconButton from '@mui/material/IconButton';
import TimelineIcon from '@mui/icons-material/Timeline';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import GrainIcon from '@mui/icons-material/Grain';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import CSVDownloadLink from '../downloadlink/csvdownloadlink';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from "react-csv";
import CsvDownloader from 'react-csv-downloader';

const InstruemntHealth = (props) => {
    const { signalPlotsData, setSignalPlotsData } = props;

    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [opticsQcBeadsType, setOpticsQcBeadsType] = React.useState(1);
    const [opticsPlotDataState, setOpticsPlotDataState] = React.useState([]);
    const [healthTemprDataState, setHealthTemprDataState] = React.useState([]);
    const [healthTemprDataStateCSV, setHealthTemprDataStateCSV] = React.useState(undefined);
    const [healthHumDataState, setHealthHumDataState] = React.useState([]);
    const [healthHumDataStateCSV, setHealthHumDataStateCSV] = React.useState(undefined);
    const [imgDeformPlot1DataState, setImgDeformPlot1DataState] = React.useState([]);
    const [imgDeformPlot2DataState, setImgDeformPlot2DataState] = React.useState([]);
    const [imgDeformPlot1Value, setImgDeformPlot1Value] = React.useState(1);
    const [imgDeformPlot2Value, setImgDeformPlot2Value] = React.useState(2);
    const [imgDeformPlot3Value, setImgDeformPlot3Value] = React.useState(1);
    const [beadLossPlotsState, setBeadLossPlotsState] = React.useState([]);
    const [SAMReportState, setSAMReportState] = React.useState(undefined);
    const [fwhmPlot1DataState, setFwhmPlot1DataState] = React.useState([]);
    const [fwhmMetricTypeValue, setFwhmMetricTypeValue] = React.useState('meanFWHM');
    const [compareData, setCompareData] = React.useState([]);
    const [compareGlobalInput1, setCompareGlobalInput1] = React.useState(undefined);
    const [compareGlobalInput2, setCompareGlobalInput2] = React.useState(undefined);
    const [compareGlobalInput3, setCompareGlobalInput3] = React.useState(undefined);
    const [compareGlobalInput4, setCompareGlobalInput4] = React.useState(undefined);
    const [traceHealthChart, setTraceHealthChart] = React.useState("");
    const [fwhmCSV1DataState, setFwhmCSV1DataState] = React.useState([]);
    const [fwhmCSV2DataState, setFwhmCSV2DataState] = React.useState([]);

    const [expandedIds, setExpandedIds] = React.useState([1, 2, 3, 4, 5, 6, 7, 8]);

    useEffect(() => {
        setTraceHealthChart("");
        setHealthHumDataStateCSV(undefined);
        if (state.runId != undefined) {
            if (state.active_instr_health_sub_tab_name === 'opticsQC')
                handleOpticsQCTabClick();
            // else if (state.active_instr_health_sub_tab_name === 'beadLoss') {
            //     handleBeadLossTabClick();
            // }
            else if (state.active_instr_health_sub_tab_name === 'healthTemperature')
                handleHealthTemprTabClick();
            else if (state.active_instr_health_sub_tab_name === 'healthHumidity')
                handleHealthHumTabClick();
            else if (state.active_instr_health_sub_tab_name === 'imgDeform')
                handleImgDeformTabClick();
            else if (state.active_instr_health_sub_tab_name === 'sam')
                handleSAMTabClick();
            else if (state.active_instr_health_sub_tab_name === 'fwhm')
                handleFWHMTabClick();
        }
    }, [state.runId, state.active_instr_health_sub_tab_name]);

    useEffect(() => {
        try {
            let exists = state.signal_plots_render_data.filter(f => f.runid === state.runId);
            if (exists !== undefined && exists.length > 0) {
                if (exists !== undefined && exists.length > 0 && exists[0].data !== undefined) {
                    let updatedData = exists[0].data;
                    if(updatedData.hasOwnProperty('data3') && updatedData.hasOwnProperty('data4')) setSignalPlotsData(updatedData);
                    else setSignalPlotsData(undefined);
                    dispatch(setIsSignalPlotsLoading(false));
                }
                else {
                    setSignalPlotsData(undefined);
                    dispatch(setIsSignalPlotsLoading(false));
                }

            }
        }
        catch {
            setSignalPlotsData(undefined);
            dispatch(setIsSignalPlotsLoading(false));
        }
    }, [state.signal_plots_render_data]);

    useEffect(() => {
        if (state.instrumentId != null && state.runId != null) {
            if (state.active_tab_name === 'opticsQC') {
                var exists = state.optics_plots_data.filter(f => f.runid === state.runId && f.flow === state.flow && f.beadsType === opticsQcBeadsType);
                if (exists.length === 0) {
                    dispatch(getOpticsPlotsDataAsync(state.runId, state.flow, opticsQcBeadsType, state.jwt));
                }
                else if (exists.length > 0 && exists[0].data != undefined && exists[0].data.length === 0) {
                    dispatch(getOpticsPlotsDataAsync(state.runId, state.flow, opticsQcBeadsType, state.jwt));
                }
            }
        }
    }, [state.flow]);

    useEffect(() => {
        reRenderHealthTemprPlots();
    }, [state.health_data_tmpr, traceHealthChart]);

    const reRenderHealthTemprPlots = () => {
        try {
            Utils.purgePlot('healthTempreturePlot');
            var exists = state.health_data_tmpr.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId));
            if (exists != undefined && exists.length > 0) {
                if (exists[0].data.length > 0) {
                    var tempr = RenderHealthPlots.RenderTemperaturesHealthChartByTrace(exists[0].data, traceHealthChart);
                    setHealthTemprDataState(tempr);
                    dispatch(setHealtDataTemperaturePlotRenderData(tempr, Utils.getRunIdWithoutCamera(state.runId)));
                    setHealthTemprDataStateCSV(exists[0].data);
                }
                else setHealthTemprDataState(undefined);
            }
        }
        catch { }
    }

    useEffect(() => {
        try {
            Utils.purgePlot('healthHumidityPlot');
            var exists = state.health_data_humidity.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId));
            if (exists != undefined && exists.length > 0) {
                if (exists[0].data.length > 0) {
                    var hum = RenderHealthPlots.RenderHumidityHealthChart(exists[0].data);
                    setHealthHumDataState(hum);
                    dispatch(setHealtDataHumidityPlotRenderData(hum, Utils.getRunIdWithoutCamera(state.runId)));
                    setHealthHumDataStateCSV(exists[0].data);
                }
                else setHealthHumDataState(undefined);
            }
        }
        catch { }
    }, [state.health_data_humidity, state.runId]);

    useEffect(() => {
        try {
            var exists = state.img_deform_data.filter(f => f.runid === state.runId);
            if (exists != undefined && exists.length > 0) {
                var imgDeform1 = RenderHealthPlots.RenderImageDeformationChart1(exists[0].data, imgDeformPlot1Value, "mean & stddev");
                setImgDeformPlot1DataState(imgDeform1);
                var imgDeform2 = RenderHealthPlots.RenderImageDeformationChart2(exists[0].data, imgDeformPlot2Value, imgDeformPlot3Value);
                setImgDeformPlot2DataState(imgDeform2);
                dispatch(setImgDeformRenderData({ imgDeform1, imgDeform2 }, state.runId));
            }
            else {
                setImgDeformPlot1DataState([]);
                setImgDeformPlot2DataState([]);
            }
        }
        catch { }
    }, [state.img_deform_data]);

    useEffect(() => {
        try {
            var exists = state.optics_plots_data.filter(f => f.runid === state.runId && f.flow === state.flow && f.beadsType === opticsQcBeadsType);
            if (exists.length > 0) {
                if (!exists[0].data) setOpticsPlotDataState(undefined);
                var newData = exists[0].data.filter(f => f !== undefined).map((m, i) => {
                    if (m != 0 && m.length >= 8) {
                        if (i !== 0) {
                            return {
                                'fieldPosition': m[0],
                                'beadSize': m[1],
                                'fitBeadSize': m[2],
                                'fitMeanBidSignal': m[3],
                                'meanBidSignal': m[4],
                                'meanBidSignalDN2': m[5],
                                'numBeads': m[6],
                                'varMeanBidSignal': m[7]
                            }
                        }
                    }
                    else {
                        return {
                            'ring': m['Ring'],
                            'fieldPosition': m['FieldPosition'],
                            'beadSize': m['BeadSize'],
                            'fitBeadSize': m['FitBeadSize'],
                            'fitMeanBidSignal': m['FitMeanBidSignal'],
                            'meanBidSignal': m['MeanBidSignal'],
                            'linearMeanBidSignal': m['LinearMeanBidSignal'],
                            'numBeads': m['NumBeads'],
                            'varMeanBidSignal': m['VarMeanBidSignal'],
                            'varMeanBidSignalFit': m['VarMeanBidSignalFit'],
                            'flow': m['Flow']
                        };
                    }
                });
                if (newData && newData.length === 0) {
                    Utils.purgePlot('opticsPlot1');
                    Utils.purgePlot('opticsPlot2');
                    Utils.purgePlot('opticsPlot3');
                    setOpticsPlotDataState(undefined);
                }
                else {
                    Utils.purgePlot('opticsPlot1');
                    Utils.purgePlot('opticsPlot2');
                    Utils.purgePlot('opticsPlot3');
                    var optics = RenderOpticsCharts.RenderOpticsCharts(newData.filter(f => f !== undefined));
                    if (optics !== undefined) {
                        setOpticsPlotDataState(optics);
                        dispatch(setOpticsPlotsRenderData(optics, state.runId, state.flow, opticsQcBeadsType));
                    }
                    else setOpticsPlotDataState(undefined);
                }
            }
            //else setOpticsPlotDataState(undefined);
        }
        catch (e) {
            //console.log(e);
            setOpticsPlotDataState(undefined);
        }
    }, [state.optics_plots_data]);


    const handleSubTabsSelect = key => {
        dispatch(setActiveInstrHealthSubTabName(key));
    }

    const handleOpticsQCTabClick = () => {
        try {
            var exists = state.optics_plots_render_data.filter(f => f.runid === state.runId && f.flow === state.flow && f.beadsType === opticsQcBeadsType);
            if (exists.length === 0) {
                dispatch(getOpticsPlotsDataAsync(state.runId, state.flow, opticsQcBeadsType, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data === undefined) {
                //dispatch(setOpticsPlotsDataLoading(true));       
                dispatch(getOpticsPlotsDataAsync(state.runId, state.flow, opticsQcBeadsType, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data != undefined) {
                dispatch(setOpticsPlotsRenderData(exists[0].data, state.runId, state.flow, opticsQcBeadsType, state.jwt));
                setOpticsPlotDataState(exists[0].data);
            }
        }
        catch { }
    };

    const handleHealthTemprTabClick = () => {
        try {
            setHealthTemprDataState([]);
            var exists = state.temperature_plot_render_data.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId));
            if (exists.length === 0) {
                dispatch(getHealthPlotsTemperatureData(Utils.getRunIdWithoutCamera(state.runId), state.jwt));
            }
            else if (exists.length > 0 && exists[0].data === undefined) {
                //dispatch(setOpticsPlotsDataLoading(true));       
                dispatch(getHealthPlotsTemperatureData(Utils.getRunIdWithoutCamera(state.runId), state.jwt));
            }
            else if (exists.length > 0 && exists[0].data != undefined) {
                dispatch(setHealtDataTemperaturePlotRenderData(exists[0].data, Utils.getRunIdWithoutCamera(state.runId)));
                setHealthTemprDataState(exists[0].data);
            }
        }
        catch { }
    };

    const handleHealthHumTabClick = () => {
        try {
            setHealthHumDataState([]);
            var exists = state.humidity_plot_render_data.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId));
            if (exists.length === 0) {
                dispatch(getHealthPlotsHumidityData(Utils.getRunIdWithoutCamera(state.runId), state.jwt));
            }
            else if (exists.length > 0 && exists[0].data === undefined) {
                //dispatch(setOpticsPlotsDataLoading(true));       
                dispatch(getHealthPlotsHumidityData(Utils.getRunIdWithoutCamera(state.runId), state.jwt));
            }
            else if (exists.length > 0 && exists[0].data != undefined) {
                dispatch(setHealtDataHumidityPlotRenderData(exists[0].data, Utils.getRunIdWithoutCamera(state.runId)));
                setHealthHumDataState(exists[0].data);
            }
        }
        catch { }
    };

    const handleImgDeformTabClick = () => {
        try {
            setImgDeformPlot1DataState([]);
            setImgDeformPlot2DataState([]);
            var exists = state.img_deform_render_data.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getImgDeformDataAsync(state.runId, state.jwt));
            }
            else if (exists.length > 0 && (exists[0].data.imgDeform1.data === undefined || exists[0].data.imgDeform2.data === undefined)) {
                //dispatch(setOpticsPlotsDataLoading(true));       
                dispatch(getImgDeformDataAsync(state.runId, state.jwt));
            }
            else if (exists.length > 0 && (exists[0].data.imgDeform1.data != undefined || exists[0].data.imgDeform2.data != undefined)) {
                dispatch(setImgDeformRenderData(exists[0].data, state.runId));
                setImgDeformPlot1DataState(exists[0].data.imgDeform1);
                setImgDeformPlot2DataState(exists[0].data.imgDeform2);
            }
        }
        catch { }
    };

    const handleSAMTabClick = () => {
        try {
            setSAMReportState(undefined);
            var exists = state.sam_report.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getSAMReport(state.runId, state.jwt));
            }
            else if (exists.length > 0 && (exists[0].data === undefined)) {
                //dispatch(setOpticsPlotsDataLoading(true));       
                dispatch(getSAMReport(state.runId, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data != undefined) {
                setSAMReportState(exists[0].data);
            }
        }
        catch { }
    };

    useEffect(() => {
        try {
            var exists = state.sam_report.filter(f => f.runid === state.runId);
            if (exists != undefined && exists.length > 0) {
                setSAMReportState(exists[0].data);
            }
            else setSAMReportState([]);
        }
        catch { }
    }, [state.sam_report]);

    const handleOpticsQcBeadsTypeChange = e => {
        try {
            Utils.purgePlot('opticsPlot1');
            Utils.purgePlot('opticsPlot2');
            Utils.purgePlot('opticsPlot3');
            setOpticsQcBeadsType(parseInt(e.target.value));
            setOpticsPlotDataState(undefined);
            var exists = state.optics_plots_render_data.filter(f => f.runid === state.runId && f.flow === state.flow && f.beadsType === parseInt(e.target.value));
            if (exists.length === 0) {
                dispatch(getOpticsPlotsDataAsync(state.runId, state.flow, parseInt(e.target.value), state.jwt));
            }
            else if (exists.length > 0 && exists[0].data === undefined) {
                //dispatch(setOpticsPlotsDataLoading(true));       
                dispatch(getOpticsPlotsDataAsync(state.runId, state.flow, parseInt(e.target.value), state.jwt));
            }
            else if (exists.length > 0 && exists[0].data != undefined) {
                dispatch(setOpticsPlotsRenderData(exists[0].data, state.runId, state.flow, parseInt(e.target.value)));
                setOpticsPlotDataState(exists[0].data);
            }
        }
        catch { }
        //dispatch(getOpticsPlotsDataAsync(state.runId, state.flow, parseInt(e.target.value)));
    };

    const getImgDeformPlot1Name = (name) => {
        switch (name) {
            case 1:
                return 'mean & stddev';
            case 2:
                return 'min & max';
            case 3:
                return '1pt & 99pt';
            case 4:
                return '5pt & 95pt';
            default:
                break;
        }
    }

    const handleImgDeformOptionsChange = (imgDeformPlot1Value, imgDeformPlot2Value, imgDeformPlot3Value) => {
        try {
            var exists = state.img_deform_data.filter(f => f.runid === state.runId);
            if (exists != undefined && exists.length > 0) {
                var imgDeform1 = RenderHealthPlots.RenderImageDeformationChart1(exists[0].data, imgDeformPlot1Value, getImgDeformPlot1Name(imgDeformPlot1Value));
                setImgDeformPlot1DataState(imgDeform1);
                var imgDeform2 = RenderHealthPlots.RenderImageDeformationChart2(exists[0].data, imgDeformPlot2Value, imgDeformPlot3Value);
                setImgDeformPlot2DataState(imgDeform2);
                dispatch(setImgDeformRenderData({ imgDeform1, imgDeform2 }, state.runId));
            }
            else {
                setImgDeformPlot1DataState([]);
                setImgDeformPlot2DataState([]);
            }
        }
        catch { }
    }

    const handleImgDeform1Change = e => {
        setImgDeformPlot1Value(parseInt(e.target.value));
        handleImgDeformOptionsChange(parseInt(e.target.value), imgDeformPlot2Value, imgDeformPlot3Value);
    };

    const handleHealthTemperaturesChange = e => {
        setTraceHealthChart(e.target.value);
    };

    const handleImgDeform2Change = e => {
        setImgDeformPlot2Value(parseInt(e.target.value));
        handleImgDeformOptionsChange(imgDeformPlot1Value, parseInt(e.target.value), imgDeformPlot3Value);

    };

    const handleImgDeform3Change = e => {
        setImgDeformPlot3Value(parseInt(e.target.value));
        handleImgDeformOptionsChange(imgDeformPlot1Value, imgDeformPlot2Value, parseInt(e.target.value));
    };

    const handleBeadLossTabClick = () => {
        var exists = state.bead_loss_plots.filter(f => f.runid === state.runId);
        if (exists != undefined && exists.length > 0) {
            setBeadLossPlotsState(exists[0].data);
        }
        else dispatch(getBeadLossPlots(state.runId, 'BLALL', false, state.jwt));
    }

    useEffect(() => {
        try {
            //Utils.purgeD3Plots('BL');
            var exists = state.bead_loss_plots.filter(f => f.runid === state.runId);
            if (exists != undefined && exists.length > 0) {
                setBeadLossPlotsState(exists[0].data);
            }
            else setBeadLossPlotsState([]);
        }
        catch { }
    }, [state.bead_loss_plots]);

    useEffect(() => {
        try {
            beadLossPlotsState.forEach((plot, i) => {
                var elementExists = document.getElementById(`BL${i + 1}`);
                if (elementExists/* && !elementExists.hasChildNodes()*/) {
                    var old = JSON.stringify(plot).replace(/\bNaN\b/g, "0").replace(/\Infinity\b/g, "0").replace(/\Infinity\b/g, "").replace(/\L\b/g, "");
                    console.log("BL Data: ", old);
                    mpld3.draw_figure(`BL${i + 1}`, JSON.parse(old));
                }
            });
        }
        catch { }
    }, [beadLossPlotsState]);

    const handleFWHMMetricTypeTypeChange = (e) => {
        setFwhmMetricTypeValue(e.target.value);
        var column = e.target.value;
        var exists = state.fwhm_plots_data.filter(f => f.runid === state.runId);
        if (exists != undefined && exists.length > 0) {
            //var fwhm1 = RenderOpticsCharts.PlotFWHMSingleCam(exists[0].data.cam1, column, state.runId);
            //setFwhmPlot1DataState(fwhm1);

            if (compareData.length === 0) {
                var fwhm2 = RenderOpticsCharts.PlotFWHMColumnSummary(exists[0].data.cam1, exists[0].data.cam2, column, state.runId);
                setFwhmPlot1DataState(fwhm2);
            } else {
                var fwhm2 = RenderOpticsCharts.ComparePlotFWHMColumnSummary(exists[0].data.cam1, exists[0].data.cam2, compareData, column, state.runId);
                setFwhmPlot1DataState(fwhm2);
            }
        }
    }

    const handleCompareGlobalFWHMCLick = existingData => {
        try {
            //setCompareData([compareGlobalInput1, compareGlobalInput2, compareGlobalInput3, compareGlobalInput4]);
            let runIds = [];
            if (compareGlobalInput1 != undefined) {
                dispatch(setIsFWHMPlotsLoading(true));
                let newCompare = undefined;
                let camId = compareGlobalInput1.split("_");
                if (camId.length === 1) {
                    if (existingData) {
                        newCompare = { compare1: existingData.data.cam1, compare2: [], runid: compareGlobalInput1 };
                    }
                    else {
                        var exists = state.fwhm_plots_data.filter(f => f.runid === compareGlobalInput1);
                        if (exists.length === 0) {
                            runIds.push(compareGlobalInput1);
                            //dispatch(getFWHMDataManyAsync(compareGlobalInput1, true));
                        }
                    }
                }
                else {
                    if (existingData) {
                        newCompare = { compare1: existingData.data.cam1, compare2: existingData.data.cam2, runid: compareGlobalInput1 };
                    }
                    else {
                        var exists = state.fwhm_plots_data.filter(f => f.runid === compareGlobalInput1);
                        if (exists.length === 0) {
                            //dispatch(getFWHMDataAsync(compareGlobalInput1, true));
                            runIds.push(compareGlobalInput1);
                        }
                    }
                }

                if (newCompare != undefined) {
                    setCompareData(newCompare);
                }
            }

            if (compareGlobalInput2 != undefined) {
                dispatch(setIsFWHMPlotsLoading(true));
                let newCompare = undefined;
                let camId = compareGlobalInput2.split("_");
                if (camId.length === 1) {
                    if (existingData) {
                        newCompare = { compare1: existingData.data.cam1, compare2: [], runid: compareGlobalInput2 };
                    }
                    else {
                        var exists = state.fwhm_plots_data.filter(f => f.runid === compareGlobalInput2);
                        if (exists.length === 0) {
                            runIds.push(compareGlobalInput2);
                            //dispatch(getFWHMDataManyAsync(compareGlobalInput1, true));
                        }
                    }
                }
                else {
                    if (existingData) {
                        newCompare = { compare1: existingData.data.cam1, compare2: existingData.data.cam2, runid: compareGlobalInput2 };
                    }
                    else {
                        var exists = state.fwhm_plots_data.filter(f => f.runid === compareGlobalInput2);
                        if (exists.length === 0) {
                            //dispatch(getFWHMDataAsync(compareGlobalInput1, true));
                            runIds.push(compareGlobalInput2);
                        }
                    }
                }

                if (newCompare != undefined) {
                    setCompareData(newCompare);
                }
            }

            dispatch(getFWHMDataManyAsync(runIds, state.jwt));
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        try {
            let newCompare = [];
            if (compareGlobalInput1 != undefined) {
                let camId = compareGlobalInput1.split("_");
                if (camId.length === 1) {
                    var exists = state.fwhm_plots_camera_data.filter(f => f.runid === compareGlobalInput1);
                    if (exists.length === 0) {
                        dispatch(getFWHMDataAsync(compareGlobalInput1, true, state.jwt));
                    }
                    else {
                        newCompare.push({ compare1: exists[0].data.cam1, compare2: [], runid: compareGlobalInput1 });
                    }
                }
                else {
                    for (const [key, value] of Object.entries(state.fwhm_plots_camera_data)) {
                        if (key === compareGlobalInput1) {
                            var keyCam2;
                            var spl = key.split('_');
                            if (spl.length === 2) {
                                keyCam2 = spl[1] === 1 ? `${spl[0]}_1` : `${spl[0]}_2`;
                            }
                            else keyCam2 = key;
                            var valueCam2 = state.fwhm_plots_camera_data[keyCam2];
                            newCompare.push({ compare1: value, compare2: valueCam2, runid: compareGlobalInput1 });
                        }
                    }
                }
            }

            if (compareGlobalInput2 != undefined) {
                let camId = compareGlobalInput2.split("_");
                if (camId.length === 1) {
                    var exists = state.fwhm_plots_camera_data.filter(f => f.runid === compareGlobalInput2);
                    if (exists.length === 0) {
                        dispatch(getFWHMDataAsync(compareGlobalInput2, true, state.jwt));
                    }
                    else {
                        newCompare.push({ compare1: exists[0].data.cam1, compare2: [], runid: compareGlobalInput2 });
                    }
                }
                else {
                    for (const [key, value] of Object.entries(state.fwhm_plots_camera_data)) {
                        if (key === compareGlobalInput2) {
                            var keyCam2;
                            var spl = key.split('_');
                            if (spl.length === 2) {
                                keyCam2 = spl[1] === 1 ? `${spl[0]}_1` : `${spl[0]}_2`;
                            }
                            else keyCam2 = key;
                            var valueCam2 = state.fwhm_plots_camera_data[keyCam2];
                            newCompare.push({ compare1: value, compare2: valueCam2, runid: compareGlobalInput2 });
                        }
                    }
                }
            }

            if (compareGlobalInput3 != undefined) {
                let camId = compareGlobalInput3.split("_");
                if (camId.length === 1) {
                    var exists = state.fwhm_plots_camera_data.filter(f => f.runid === compareGlobalInput3);
                    if (exists.length === 0) {
                        dispatch(getFWHMDataAsync(compareGlobalInput3, true, state.jwt));
                    }
                    else {
                        newCompare.push({ compare1: exists[0].data.cam1, compare2: [], runid: compareGlobalInput3 });
                    }
                }
                else {
                    for (const [key, value] of Object.entries(state.fwhm_plots_camera_data)) {
                        if (key === compareGlobalInput3) {
                            var keyCam2;
                            var spl = key.split('_');
                            if (spl.length === 2) {
                                keyCam2 = spl[1] === 1 ? `${spl[0]}_1` : `${spl[0]}_2`;
                            }
                            else keyCam2 = key;
                            var valueCam2 = state.fwhm_plots_camera_data[keyCam2];
                            newCompare.push({ compare1: value, compare2: valueCam2, runid: compareGlobalInput3 });
                        }
                    }
                }
            }

            if (compareGlobalInput4 != undefined) {
                let camId = compareGlobalInput4.split("_");
                if (camId.length === 1) {
                    var exists = state.fwhm_plots_camera_data.filter(f => f.runid === compareGlobalInput4);
                    if (exists.length === 0) {
                        dispatch(getFWHMDataAsync(compareGlobalInput4, true, state.jwt));
                    }
                    else {
                        newCompare.push({ compare1: exists[0].data.cam1, compare2: [], runid: compareGlobalInput4 });
                    }
                }
                else {
                    for (const [key, value] of Object.entries(state.fwhm_plots_camera_data)) {
                        if (key === compareGlobalInput4) {
                            var keyCam2;
                            var spl = key.split('_');
                            if (spl.length === 2) {
                                keyCam2 = spl[1] === 1 ? `${spl[0]}_1` : `${spl[0]}_2`;
                            }
                            else keyCam2 = key;
                            var valueCam2 = state.fwhm_plots_camera_data[keyCam2];
                            newCompare.push({ compare1: value, compare2: valueCam2, runid: compareGlobalInput4 });
                        }
                    }
                }
            }

            if (newCompare != undefined && newCompare.length > 0) {
                setCompareData(newCompare);
                var exists = state.fwhm_plots_data.filter(f => f.runid === state.runId);
                if (exists != undefined && exists.length > 0) {
                    var fwhmCompare = RenderOpticsCharts.ComparePlotFWHMColumnSummary(exists[0].data.cam1, exists[0].data.cam2, newCompare, 'MeanFWHM', state.runId, 'GlobalFWHMChart');
                    fwhmCompare['globalData'] = fwhmPlot1DataState.globalData;
                    fwhmCompare['globalLayout'] = fwhmPlot1DataState.globalLayout;
                    setFwhmPlot1DataState(fwhmCompare);
                }
                dispatch(setIsFWHMPlotsLoading(false));
            }
        }
        catch (e) {
            console.log(e);
        }
    }, [state.fwhm_plots_camera_data]);

    const handleCompareFWHMCLick = e => {
        console.log(e);
    }

    const handleFWHMTabClick = () => {
        try {
            var exists = state.fwhm_plots_data.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getFWHMDataAsync(state.runId, false, state.jwt));
            }
            else if (exists.length > 0 && (exists[0].data === undefined)) {
                //dispatch(setOpticsPlotsDataLoading(true));       
                dispatch(getFWHMDataAsync(state.runId, false, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data != undefined) {
                setFwhmPlot1DataState(exists[0].data);
            }
        }
        catch { }
    };

    useEffect(() => {
        try {
            var exists = state.fwhm_plots_data.filter(f => f.runid === state.runId);
            if (exists != undefined && exists.length > 0) {
                if (exists[0].data.cam1.length > 0 && typeof exists[0].data.cam2 === "undefined") {
                    var fwhm1 = RenderOpticsCharts.PlotFWHMSingleCam(exists[0].data.cam1, "MeanFWHM", state.runId);
                    setFwhmPlot1DataState(fwhm1);
                }
                else if (typeof exists[0].data.cam1 != "undefined" && typeof exists[0].data.cam2 != "undefined") {
                    var fwhm2 = RenderOpticsCharts.PlotFWHMColumnSummary(exists[0].data.cam1, exists[0].data.cam2, "MeanFWHM", state.runId);
                    setFwhmPlot1DataState(fwhm2);
                }
            }
            else {
                setFwhmPlot1DataState([]);
            }
        }
        catch { }
    }, [state.fwhm_plots_data]);

    const handleCompareGlobalInputChange = (e, name) => {
        switch (name) {
            case 'g1':
                setCompareGlobalInput1(e.target.value);
                break;
            case 'g2':
                setCompareGlobalInput2(e.target.value);
                break;
            case 'g3':
                setCompareGlobalInput3(e.target.value);
                break;
            case 'g4':
                setCompareGlobalInput4(e.target.value);
                break;
            default:
                break;
        }
    }

    const handleExpandClick = id => {
        setExpandedIds(expandedIds.includes(id) ? [...expandedIds.filter(item => item !== id)] : [...expandedIds, id]);
    }

    const handleFWHMCSV1Download = async () => {
        var data = await getFWHMCSVDataAsync(state.runId, 1, state.jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json.data.map(({ FWHMSummaryId, UpdateTs, ...keepAttrs }) => keepAttrs);
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        if (data !== undefined) return Promise.resolve(data);
    }

    const handleFWHMCSV2Download = async () => {
        var data = await getFWHMCSVDataAsync(state.runId, 2, state.jwt).then(response => {
            if (!response.ok) throw Error(response.statusText);
            return response.json();
        }).then(json => {
            if (json) return json.data.map(({ FWHMSummaryId, UpdateTs, ...keepAttrs }) => keepAttrs);
            else return undefined;
        }).catch(() => {
            return undefined;
        });

        if (data !== undefined) return Promise.resolve(data);
    }

    useEffect(() => {
        try {
            if (state.fwhm_csv_data.camera !== undefined && state.fwhm_csv_data.camera === '1') {
                setFwhmCSV1DataState(state.fwhm_csv_data.data);
            }
            else if (state.fwhm_csv_data.camera !== undefined && state.fwhm_csv_data.camera === '2') {
                setFwhmCSV2DataState(state.fwhm_csv_data.data);
            }
            else {
                setFwhmCSV1DataState([]);
                setFwhmCSV2DataState([]);
            }
        }
        catch { }
    }, [state.fwhm_csv_data]);

    return (
        <React.Fragment>
            <Row style={{ marginTop: 10 }}>
                <Col lg={2} style={{ marginTop: 15 }}>
                    <Nav defaultActiveKey="run_time" className="flex-column verticalNav" onSelect={(selectedKey) => handleSubTabsSelect(selectedKey)}>
                        <Nav.Link eventKey="run_time"><TimelineIcon style={{ marginBottom: 5, marginRight: 5, marginLeft: 30, }} />RunTime</Nav.Link>
                        <Nav.Link eventKey="opticsQC"><StackedLineChartIcon style={{ marginBottom: 5, marginRight: 5, marginLeft: 40, }} />Optics QC</Nav.Link>
                        <Nav.Link eventKey="fwhm"><InsertChartIcon style={{ marginBottom: 5, marginRight: 5, marginLeft: 20 }} />FWHM</Nav.Link>
                        <Nav.Link eventKey="healthTemperature"><DeviceThermostatIcon style={{ marginBottom: 5, marginRight: 5, marginLeft: 55, }} />Temperature</Nav.Link>
                        <Nav.Link eventKey="healthHumidity"><GrainIcon style={{ marginBottom: 5, marginRight: 5, marginLeft: 35, }} />Humidity</Nav.Link>
                        {/* <Nav.Link eventKey="beadLoss"><SsidChartIcon style={{ marginBottom: 5, marginRight: 5, marginLeft: 40, }} />Bead Loss</Nav.Link> */}
                        <Nav.Link eventKey="imgDeform"><TroubleshootIcon style={{ marginBottom: 5, marginRight: 5, marginLeft: 75, }} />Image Deform</Nav.Link>
                        <Nav.Link eventKey="sam"><AnalyticsIcon style={{ marginBottom: 5, marginRight: 5 }} />SAM</Nav.Link>
                    </Nav>
                </Col>
                <Col lg={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="run_time">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                        <CardHeader
                                            action={
                                                <IconButton
                                                    onClick={() => handleExpandClick(1)}
                                                    aria-expanded={expandedIds.includes(1)}
                                                    aria-label="Expand/Collapse"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                            title="RunTime"
                                            subheader={state.runId}
                                        />
                                        <Collapse in={expandedIds.includes(1)} timeout="auto">
                                            <CardContent>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        {(signalPlotsData != undefined && signalPlotsData.data3 != undefined && signalPlotsData.data4 != undefined  && !state.isSignalPloatsLoading) ?
                                                            <React.Fragment>
                                                                <Row>
                                                                    <Col lg={6}>
                                                                        <RenderPlot data={signalPlotsData && signalPlotsData.data3} layout={signalPlotsData && signalPlotsData.layout3} config={signalPlotsData && signalPlotsData.config} isLoading={state.isSignalPloatsLoading}
                                                                            type={'rounded'} width={'100%'} height={300} />
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <RenderPlot data={signalPlotsData && signalPlotsData.data4} layout={signalPlotsData && signalPlotsData.layout4} config={signalPlotsData && signalPlotsData.config} isLoading={state.isSignalPloatsLoading}
                                                                            type={'rounded'} width={'100%'} height={300} />
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                            : <ShowAlert type={'warning'} text='No data found' />}
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="opticsQC">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                        <CardHeader
                                            action={
                                                <IconButton
                                                    onClick={() => handleExpandClick(2)}
                                                    aria-expanded={expandedIds.includes(2)}
                                                    aria-label="Expand/Collapse"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                            title="Optics QC"
                                            subheader={state.runId}
                                        />
                                        <React.Fragment>
                                            {opticsPlotDataState !== undefined &&
                                                <Row className="text-center">
                                                    <FlowSpinner.OpticFlowSpinner flow={state.flow} maxFlows={state.current_run_data.length > 0 ? state.current_run_data[0] : 999} beadType={opticsQcBeadsType} />
                                                </Row>
                                            }
                                            {(opticsPlotDataState === undefined && !state.optics_plots_data_is_loading) &&
                                                <Col lg={12}>
                                                    <ShowAlert type={'warning'} text={`Failed to display Optics data for ${state.runId}. No data available, or not uploaded`} />
                                                </Col>
                                            }
                                        </React.Fragment>
                                        <Collapse in={expandedIds.includes(2)} timeout="auto">
                                            <CardContent>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        <React.Fragment>
                                                            {!state.optics_plots_data_is_loading && opticsPlotDataState !== undefined &&
                                                                <React.Fragment>
                                                                    <Row className={'text-center'}>
                                                                        <Col lg={2} style={{ display: 'table', margin: '0 auto' }}>
                                                                            <label><strong>Beads :</strong></label>
                                                                            <select className="form-select" onChange={handleOpticsQcBeadsTypeChange} defaultValue="1">
                                                                                <option value="1">Sequencing beads</option>
                                                                                <option value="2">Base-Line beads</option>
                                                                            </select>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            }
                                                            {opticsPlotDataState !== undefined &&
                                                                <React.Fragment>
                                                                    <Row style={{ marginTop: 15 }}>
                                                                        <React.Fragment>
                                                                            <Col lg={12}>
                                                                                <RenderPlot id="opticsPlot1" data={opticsPlotDataState.data && opticsPlotDataState.data} layout={opticsPlotDataState.layout && opticsPlotDataState.layout} config={opticsPlotDataState.config && opticsPlotDataState.config} isLoading={state.optics_plots_data_is_loading} type={'rounded'} width={'100%'} height={430} />
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <RenderPlot id="opticsPlot2" data={opticsPlotDataState.data1 && opticsPlotDataState.data1} layout={opticsPlotDataState.layout1 && opticsPlotDataState.layout1} config={opticsPlotDataState.config && opticsPlotDataState.config} isLoading={state.optics_plots_data_is_loading} type={'rounded'} width={'100%'} height={430} />
                                                                            </Col>
                                                                            <Col lg={12}>
                                                                                <RenderPlot id="opticsPlot3" data={opticsPlotDataState.data3 && opticsPlotDataState.data3} layout={opticsPlotDataState.layout3 && opticsPlotDataState.layout3} config={opticsPlotDataState.config && opticsPlotDataState.config} isLoading={state.optics_plots_data_is_loading} type={'rounded'} width={'100%'} height={430} />
                                                                            </Col>
                                                                        </React.Fragment>
                                                                    </Row>
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="healthTemperature">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                        <CardHeader
                                            action={
                                                <IconButton
                                                    onClick={() => handleExpandClick(3)}
                                                    aria-expanded={expandedIds.includes(3)}
                                                    aria-label="Expand/Collapse"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                            title="Temperature"
                                            subheader={state.runId}
                                        />
                                        <Collapse in={expandedIds.includes(3)} timeout="auto">
                                            <CardContent>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        {healthTemprDataState != undefined && !state.health_data_is_loading &&
                                                            <Row className={'text-center'} style={{ marginTop: 10, marginBottom: 20 }}>
                                                                <Col lg={2} style={{ display: 'table', margin: '0 auto' }}>
                                                                    <label><strong>Trace Menu: </strong></label>
                                                                    <select className="form-select" onChange={handleHealthTemperaturesChange} defaultValue="">
                                                                        <option value="heaters">Heaters</option>
                                                                        <option value="airTemperature">Air temperature sensors</option>
                                                                        <option value="opticsTemps">Optics temps</option>
                                                                        <option value="seqPlatform">Seq platform</option>
                                                                        <option value="intakeTemperatures">Intake Temperature</option>
                                                                        <option value="">Deselect</option>
                                                                    </select>
                                                                </Col>
                                                                <Col lg={2} style={{ marginTop: 30 }}>
                                                                    {healthTemprDataStateCSV != undefined && !state.health_data_is_loading &&
                                                                        <CSVDownloadLink data={healthTemprDataStateCSV ? healthTemprDataStateCSV : [[]]} filename={`Temperature_${state.runId}.csv`} />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {healthTemprDataState != undefined &&
                                                            <RenderPlot id="healthTempreturePlot" data={healthTemprDataState.data && healthTemprDataState.data} layout={healthTemprDataState.layout && healthTemprDataState.layout} config={healthTemprDataState.config && healthTemprDataState.config} isLoading={state.health_data_is_loading}
                                                                type={'rounded'} width={'100%'} height={400} />
                                                        }
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="healthHumidity">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                        <CardHeader
                                            action={
                                                <IconButton
                                                    onClick={() => handleExpandClick(4)}
                                                    aria-expanded={expandedIds.includes(4)}
                                                    aria-label="Expand/Collapse"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                            title="Humidity"
                                            subheader={state.runId}
                                        />
                                        <Collapse in={expandedIds.includes(4)} timeout="auto">
                                            <CardContent>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        {healthHumDataState != undefined && !state.health_data_is_loading &&
                                                            <Row style={{ marginTop: 10, marginBottom: 30, display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Col lg={2}>
                                                                    <CSVDownloadLink data={healthHumDataStateCSV ? healthHumDataStateCSV : [[]]} filename={`Humidity_${state.runId}.csv`} />
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {healthHumDataState != undefined &&
                                                            <RenderPlot id="healthHumidityPlot" data={healthHumDataState.data && healthHumDataState.data} layout={healthHumDataState.layout && healthHumDataState.layout} config={healthHumDataState.config && healthHumDataState.config} isLoading={state.health_data_is_loading}
                                                                type={'rounded'} width={'100%'} height={400} />
                                                        }
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="beadLoss">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                        <CardHeader
                                            action={
                                                <IconButton
                                                    onClick={() => handleExpandClick(5)}
                                                    aria-expanded={expandedIds.includes(5)}
                                                    aria-label="Expand/Collapse"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                            title="Bead Loss"
                                            subheader={state.runId}
                                        />
                                        <Collapse in={expandedIds.includes(5)} timeout="auto">
                                            <CardContent>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        <Row>
                                                            {beadLossPlotsState.length > 0 ? beadLossPlotsState.map((item, i) => {
                                                                return (
                                                                    <Col lg={6} key={`BL${(i + 1) * 2}`}>
                                                                        <div className="BL" id={`BL${i + 1}`}></div>
                                                                    </Col>
                                                                );
                                                            }) : state.is_bead_loss_data_loading ? <Col lg={12}><LoadingSpinner /></Col> : <ShowAlert type={'warning'} text='No data found' />}
                                                        </Row>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="imgDeform">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                        <CardHeader
                                            action={
                                                <IconButton
                                                    onClick={() => handleExpandClick(6)}
                                                    aria-expanded={expandedIds.includes(6)}
                                                    aria-label="Expand/Collapse"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                            title="Image Deformation"
                                            subheader={state.runId}
                                        />
                                        <Collapse in={expandedIds.includes(6)} timeout="auto">
                                            <CardContent>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        {imgDeformPlot1DataState.data != undefined && imgDeformPlot1DataState.layout != undefined && imgDeformPlot1DataState.config != undefined ?
                                                            <Row>
                                                                <h4>Image Deformation across flows</h4>
                                                            </Row>
                                                            : null
                                                        }
                                                        <Row className={'text-center'} style={{ marginTop: 20, marginBottom: 20 }}>
                                                            {imgDeformPlot1DataState.data != undefined && imgDeformPlot1DataState.layout != undefined && imgDeformPlot1DataState.config != undefined ?
                                                                <Col lg={2} style={{ display: 'table', margin: '0 auto' }}>
                                                                    <label><strong>Please select: </strong></label>
                                                                    <select className="form-select" onChange={handleImgDeform1Change} defaultValue="1">
                                                                        <option value="1">mean & stddev</option>
                                                                        <option value="2">min & max</option>
                                                                        <option value="3">1pt & 99pt</option>
                                                                        <option value="4">5pt & 95pt</option>
                                                                    </select>
                                                                </Col>
                                                                : null
                                                            }
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>
                                                                {imgDeformPlot1DataState.data != undefined && imgDeformPlot1DataState.layout != undefined && imgDeformPlot1DataState.config != undefined ?
                                                                    <RenderPlot data={imgDeformPlot1DataState.data && imgDeformPlot1DataState.data} layout={imgDeformPlot1DataState.layout && imgDeformPlot1DataState.layout} config={imgDeformPlot1DataState.config && imgDeformPlot1DataState.config} />
                                                                    : state.isImgDeformDataLoading ? null : <ShowAlert type={'warning'} text='No data found' />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>
                                                                {imgDeformPlot1DataState.data1 != undefined && imgDeformPlot1DataState.layout1 != undefined && imgDeformPlot1DataState.config != undefined ?
                                                                    <RenderPlot data={imgDeformPlot1DataState.data1 && imgDeformPlot1DataState.data1} layout={imgDeformPlot1DataState.layout1 && imgDeformPlot1DataState.layout1} config={imgDeformPlot1DataState.config && imgDeformPlot1DataState.config} />
                                                                    : state.isImgDeformDataLoading ? null : <ShowAlert type={'warning'} text='No data found' />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        {imgDeformPlot2DataState.data != undefined && imgDeformPlot2DataState.layout != undefined && imgDeformPlot2DataState.config != undefined ?
                                                            <Row>
                                                                <h4>Image Deformation across rings</h4>
                                                            </Row>
                                                            : null
                                                        }
                                                        <Row className={'text-center'} style={{ marginTop: 20, marginBottom: 20 }}>
                                                            {imgDeformPlot2DataState.data != undefined && imgDeformPlot2DataState.layout != undefined && imgDeformPlot2DataState.config != undefined ?
                                                                <React.Fragment>
                                                                    <Col lg={2} style={{ display: 'table', margin: '0 auto' }}>
                                                                        <label><strong>Please select: </strong></label>
                                                                        <select className="form-select" onChange={handleImgDeform2Change} defaultValue="2">
                                                                            <option value="1">distance(pix)</option>
                                                                            <option value="2">rotation(turns x 1M)</option>
                                                                            <option value="3">X shift</option>
                                                                            <option value="4">Y shift</option>
                                                                            <option value="5">Axx</option>
                                                                            <option value="6">Axy</option>
                                                                            <option value="7">Ayx</option>
                                                                            <option value="8">YAyy</option>
                                                                        </select>
                                                                    </Col>
                                                                    <Col lg={2} style={{ display: 'table', margin: '0 auto' }}>
                                                                        <label><strong>Please select: </strong></label>
                                                                        <select className="form-select" onChange={handleImgDeform3Change} defaultValue="1">
                                                                            <option value="1">mean & stddev</option>
                                                                            <option value="2">min & max</option>
                                                                            <option value="3">1pt & 99pt</option>
                                                                            <option value="4">5pt & 95pt</option>
                                                                        </select>
                                                                    </Col>
                                                                </React.Fragment>
                                                                : state.isImgDeformDataLoading ? null : <ShowAlert type={'warning'} text='No data found' />
                                                            }
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>
                                                                {imgDeformPlot2DataState.data != undefined && imgDeformPlot2DataState.layout != undefined && imgDeformPlot2DataState.config != undefined ?
                                                                    <RenderPlot data={imgDeformPlot2DataState.data && imgDeformPlot2DataState.data} layout={imgDeformPlot2DataState.layout && imgDeformPlot2DataState.layout} config={imgDeformPlot2DataState.config && imgDeformPlot2DataState.config} />
                                                                    : state.isImgDeformDataLoading ? <Col lg={12}><LoadingSpinner /></Col> : <ShowAlert type={'warning'} text='No data found' />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sam">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                        <CardHeader
                                            action={
                                                <IconButton
                                                    onClick={() => handleExpandClick(7)}
                                                    aria-expanded={expandedIds.includes(7)}
                                                    aria-label="Expand/Collapse"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                            title="SAM"
                                            subheader={state.runId}
                                        />
                                        <Collapse in={expandedIds.includes(7)} timeout="auto">
                                            <CardContent>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        <Row>
                                                            <Col lg={12}>
                                                                {SAMReportState != undefined ? <div dangerouslySetInnerHTML={{ __html: SAMReportState }} /> : state.is_sam_report_loading ? <Col lg={12}><LoadingSpinner /></Col> : <ShowAlert type={'warning'} text='Report not found' />}
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fwhm">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                        <CardHeader
                                            action={
                                                <IconButton
                                                    onClick={() => handleExpandClick(8)}
                                                    aria-expanded={expandedIds.includes(8)}
                                                    aria-label="Expand/Collapse"
                                                >
                                                    <ExpandMoreIcon />
                                                </IconButton>
                                            }
                                            title="FWHM"
                                            subheader={state.runId}
                                        />
                                        <Collapse in={expandedIds.includes(8)} timeout="auto">
                                            <CardContent>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item>
                                                        {!state.is_fwhm_plot_loading ?
                                                            <React.Fragment>
                                                                <Row style={{ justifyContent: 'right' }}>
                                                                    <Col lg={3} md={3}>
                                                                        {fwhmPlot1DataState.data !== undefined ?
                                                                            <div id="compareGlobalFWHMContainer">
                                                                                <div className="input-group mb-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                                    <input className="compareGlobalFWHMChart" id="compareGlobalFWHMrunid" placeholder="Enter RunId2" style={{ marginTop: '10px' }} value={compareGlobalInput1} onChange={(e) => handleCompareGlobalInputChange(e, 'g1')} />
                                                                                    <input className="compareGlobalFWHMChart" id="compareGlobalFWHMrunid1" placeholder="Enter RunId3" style={{ marginTop: '10px' }} value={compareGlobalInput2} onChange={(e) => handleCompareGlobalInputChange(e, 'g2')} />
                                                                                    <input className="compareGlobalFWHMChart" id="compareGlobalFWHMrunid2" placeholder="Enter RunId4" style={{ marginTop: '10px' }} value={compareGlobalInput3} onChange={(e) => handleCompareGlobalInputChange(e, 'g3')} />
                                                                                    <input className="compareGlobalFWHMChart" id="compareGlobalFWHMrunid3" placeholder="Enter RunId5" style={{ marginTop: '10px' }} value={compareGlobalInput4} onChange={(e) => handleCompareGlobalInputChange(e, 'g4')} />
                                                                                    <Button variant="primary" style={{ marginTop: '10px' }} size="lg" active onClick={() => handleCompareGlobalFWHMCLick(undefined)}>Compare</Button>
                                                                                </div>
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                {
                                                                    fwhmPlot1DataState.data != undefined ?
                                                                        <Row>
                                                                            <Col lg={12} md={12}>
                                                                                <RenderPlot data={fwhmPlot1DataState.data && fwhmPlot1DataState.data} layout={fwhmPlot1DataState.layout && fwhmPlot1DataState.layout} config={fwhmPlot1DataState.config && fwhmPlot1DataState.config} />
                                                                            </Col>
                                                                        </Row>
                                                                        : null
                                                                }
                                                                {fwhmPlot1DataState.globalData != undefined ?
                                                                    <Row style={{ justifyContent: 'center' }}>
                                                                        <Col lg={3} md={3} style={{ display: 'table', margin: '0 auto' }}>
                                                                            <label><strong>Metric Menu :</strong></label>
                                                                            <select className="form-select" onChange={handleFWHMMetricTypeTypeChange} defaultValue="meanFWHM">
                                                                                <option value="MeanFWHM">Mean FWHM</option>
                                                                                <option value="FWHMAbove1Point45SpecPerc">FWHM above 1.45 Spec%</option>
                                                                                <option value="FWHMCrossFOVAbove1Point45SpecPerc">FWHM CrossFOV above 1.45 spec%</option>
                                                                                <option value="FWHMCrossFOVRMSPerc">FWHM CrossFOV RMS%</option>
                                                                                <option value="FWHMCrossScanRMSPerc">FWHM CrossScan RMS%</option>
                                                                                <option value="FWHMNaNPerc">FWHM NaN%</option>
                                                                                <option value="FWHMRMS">FWHM RMS</option>
                                                                                <option value="FWHMRMSPerc">FWHM RMS%</option>
                                                                                <option value="FWHMT2TRMS">FWHM T2T RMS</option>
                                                                                <option value="FWHMT2TRMSPerc">FWHM T2T RMS%</option>
                                                                                <option value="FWHMWithinTileRMS">FWHM WithinTile RMS</option>
                                                                                <option value="FWHMWithinTileRMSPerc">FWHM WithinTile RMS%</option>
                                                                            </select>
                                                                        </Col>
                                                                        <Col lg={3} md={3}>
                                                                            <div className="input-group mb-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                                <input className="compareFWHMChart" id="compareFWHMrunid" placeholder="Enter RunId2" style={{ marginTop: '10px' }} />
                                                                                <input className="compareFWHMChart" id="compareFWHMrunid1" placeholder="Enter RunId3" style={{ marginTop: '10px' }} />
                                                                                <input className="compareFWHMChart" id="compareFWHMrunid2" placeholder="Enter RunId4" style={{ marginTop: '10px' }} />
                                                                                <input className="compareFWHMChart" id="compareFWHMrunid3" placeholder="Enter RunId5" style={{ marginTop: '10px' }} />
                                                                                <Button variant="primary" id="compareFWHM" style={{ marginTop: '10px' }} size="lg" active onClick={handleCompareFWHMCLick}>Compare</Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row> : null
                                                                }
                                                                {
                                                                    fwhmPlot1DataState.globalData != undefined ?
                                                                        <Row>
                                                                            <Col lg={12}>
                                                                                <RenderPlot data={fwhmPlot1DataState.globalData && fwhmPlot1DataState.globalData} layout={fwhmPlot1DataState.globalLayout && fwhmPlot1DataState.globalLayout} config={fwhmPlot1DataState.config && fwhmPlot1DataState.config} />
                                                                            </Col>
                                                                        </Row> : null
                                                                }
                                                                <Row>
                                                                    <Col lg={6} md={6}>
                                                                        <CsvDownloader
                                                                            filename={`nexus-fwhm-${state.runId.split('_')[0]}-cam1`}
                                                                            extension=".csv"
                                                                            datas={handleFWHMCSV1Download}
                                                                            text="Download Raw CSV for camera 1" >
                                                                            <Button id="fwhm-csv1" className="advanced-filter-btn" color="primary" style={{ width: '30%', color: 'white', float: 'left', marginRight: 10, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>Download Raw CSV for camera 1</Button>
                                                                        </CsvDownloader></Col>
                                                                    <Col lg={6} md={6}>
                                                                        <CsvDownloader
                                                                            filename={`nexus-fwhm-${state.runId.split('_')[0]}-cam2`}
                                                                            extension=".csv"
                                                                            datas={handleFWHMCSV2Download}
                                                                            text="Download Raw CSV for camera 2" >
                                                                            <Button id="fwhm-csv2" className="advanced-filter-btn" color="primary" style={{ width: '30%', color: 'white', float: 'left', marginRight: 10, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>Download Raw CSV for camera 2</Button>
                                                                        </CsvDownloader>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment> : <Col lg={12}><LoadingSpinner /></Col>
                                                        }
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </CardContent>
                                        </Collapse>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </React.Fragment >
    )
};

export default InstruemntHealth;