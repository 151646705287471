import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import * as Utils from '../../utils/utils';
import StatusItems from './statusitems';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        width: 300
    },
    margin: {
        height: theme.spacing(3)
    },  
    mark: {
        background: "black"
    },   
    track: {
        background: "orange"
    },
}));


const SearchRangeRow = (props) => {
    const classes = useStyles();
    const { op, min, max, low, high, updateState, title, col, dirty, saved, disableLow, disableHigh, disableSlider } = props;
    const [operation, setOperation] = useState({ op: op ? op : 'a', min: min, max: max, low: low, high: high, disableSlider: disableSlider, disableLow: disableLow, disableHigh: disableHigh, dirty: dirty !== undefined ? dirty : false });

    const state = useSelector(state => state);

    const question = (title) => {
        return (
            <FontAwesomeIcon title={`Filter by ${title}`} icon={faQuestionCircle} style={{ width: "16px", height: "16px", color: "#ABABAB" }}/>
        )
    }

    // Clearing to Default state
    useEffect(() => {
        if (state.custom_filters[col].op && state.custom_filters[col].op === 'a' && (!state.custom_filters[col].dirty && state.custom_filters[col].saved)) {
           setOperation({ ...operation, op: 'a', low: operation.min, high: operation.max, disableSlider: true, disableLow: true, disableHigh: true, dirty: false });
        }
    }, [state.custom_filters]);

    const displayValues = {
        'r-int': "Range",
        'l-int': "Lower than",
        'h-int': "Higher than",
        'e-int': "Equals",
        a: "All"
    };

    useEffect(() => {
        if ((operation.op === 'a') || operation.op === 'r-int' || (operation.low !== undefined && operation.low !== operation.min) || (operation.high !== undefined && operation.high !== operation.max)) {
            let report = {
                ...operation, saved: true
            }
            updateState(col, report);
        }
    }, [operation]);

    const handleSelect = (e) => {
        let low = operation.low;
        let high = operation.high;
        let min = operation.min;
        let max = operation.max;
        let disableLow = false;
        let disableHigh = false;
        let disableSlider = false;
        let dirty = true;
        switch (e) {
            case "c":
                disableLow = false;
                disableHigh = false;
                disableSlider = false;
                break;
            case "e-int":
                high = low;
                disableLow = false;
                disableHigh = true;
                disableSlider = false;
                break;
            case "h-int":
                //low = high;
                high = 0;
                disableLow = false;
                disableHigh = true;
                disableSlider = false;
                break;
            case "l-int":
                //high = low;
                low = 0;
                disableLow = true;
                disableHigh = false;
                disableSlider = false;
                break;
            case "d":
                disableLow = false;
                disableHigh = false;
                disableSlider = false;
                break;
            case "a":
                low = min;
                high = max;
                disableLow = true;
                disableHigh = true;
                disableSlider = true;
                dirty = false;
                break;
            case "r":                             
                disableLow = false;
                disableHigh = false;
                disableSlider = false;
                break;
            case "r-int":                             
                disableLow = false;
                disableHigh = false;
                disableSlider = false;
                break;
            default:
                break;
        }
        setOperation({ op: e, min: min, max: max, low: low, high: high, disableLow: disableLow, disableHigh: disableHigh, disableSlider: disableSlider, dirty: dirty });
    }

    const handleSlider = (e, val) => {
        let low, high;
        if (Array.isArray(val))
            [low, high] = val;
        else {
            low = val;
            high = val;
        };
        if (operation.op === "e-int") {
            if (high !== operation.high) {
                low = high;
            } else {
                high = low;
            }
        }
        else if (operation.op === "l-int") {
            low = 0;
        }
        else if (operation.op === "h-int") {
            high = 0;
        }

        setOperation({
            ...operation, low: low, high: high, dirty: true
        });
    }

    const handleInputChange = (e) => {
        let name = e.target.getAttribute("name");
        let val = e.target.value;
    
        const isFloat = ["aligned_bases", "feasible_throughput", "indel_rate", "lag", "lead"].includes(col);
        
        // Only parse if the value is not empty
        let parsedValue = val === '' ? '' : (isFloat ? parseFloat(val) : parseInt(val));
    
        if (val === '' || !isNaN(parsedValue)) {
            setOperation((prevOperation) => {
                let newValue = parsedValue;
    
                // Ensure the value is within the allowed range
                if (newValue !== '') {
                    newValue = Math.max(prevOperation.min, Math.min(prevOperation.max, newValue));
                }
    
                if (prevOperation.op === "e-int") {
                    return { ...prevOperation, low: newValue, high: newValue, dirty: true };
                } else {
                    return { ...prevOperation, [name]: newValue, dirty: true };
                }
            });
        }
    }

    const getSliderValue = value => {       
        if (value.op === 'e-int') {
            return value.high;
        }
        else if (value.op === 'l-int') {
            return value.high;
        }
        else if (value.op === 'h-int') {
            return value.low;
        }
        else if (value.op === 'r-int') {
            return [value.low, value.high];
        }       
        else return value.low;
    }

    return (
        <tr>
            <td className="w100">{title}</td>
            <td className="w24">{question(title)}</td>
            <td className="w170 range-td">
                <StatusItems selection={operation} handleSelect={handleSelect} displayValues={displayValues} />
            </td>
            <td className="w100 range-td">
                <div className="d-flex align-items-center no-arrows">
                    <Form.Control name="low" type="number"
                        className="w40" onChange={handleInputChange}
                        disabled={operation.disableLow}
                        value={operation.low} />
                    <div className="px-1"> - </div>
                    <Form.Control name="high" type="number"
                        className="w40" onChange={handleInputChange}
                        disabled={operation.disableHigh}
                        value={operation.high} />
                </div>
            </td>
            <td className="w24 range-td">
                <span className="px-1">{Utils.toNumber(operation.min, 0)}</span>
            </td>
            <td className="range-td">
                <Box sx={{ width: 280 }}>
                    <Slider
                        getAriaLabel={() => 'Range'}
                        value={getSliderValue(operation)}
                        onChange={handleSlider}
                        valueLabelDisplay={operation.disableSlider ? "auto" : "on"}
                        disabled={operation.disableSlider}
                        getAriaValueText={() => getSliderValue(operation)}
                        classes={!operation.disableSlider ? {
                            thumb: classes.thumb,
                            rail: classes.rail,
                            track: classes.track,
                            valueLabel: classes.valueLabel,
                            mark: classes.mark
                        } : {}}
                        defaultValue={[operation.min, operation.max]}
                        min={operation.min}
                        max={operation.max}
                    />
                </Box>
            </td>
            <td className="w24 range-td">
                <span className="pl-2">{Utils.toNumber(operation.max, 0)}</span>
            </td>
        </tr>
    )
}

export default SearchRangeRow;
