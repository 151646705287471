import React from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const DownloadLink = props => { 
    const {file, file_name, file_link_label} = props;
    return (
        <React.Fragment>
          <a href={file} download={file_name} style={{ textDecoration: 'none', color: '#0075a9', marginTop: 15, marginBottom: 15, }}><FileDownloadIcon />{file_link_label}</a>
        </React.Fragment>
    );

}

export default DownloadLink;