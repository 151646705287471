export const PlotBarcodeCoveragePlot = (runid, csv, barcode) => {
    try {
        let data = [];

        //if (runid)
        runid = runid.split('_')[0];


        let color1 = "#3D9970";
        let color2 = "#FF4136";

        const getPlotColor = index => {
            switch (index) {
                case 0:
                    return '#3274a1';
                case 0:
                    return '#e1812c';
                case 1:
                    return '#e1812c';
                case 2:
                    return '#e1812c';
                case 3:
                    return '#e1812c';
                case 4:
                    return '#e1812c';
                case 5:
                    return '#3a923a';
                case 6:
                    return '#3a923a';
                case 7:
                    return '#3a923a';
                case 8:
                    return '#3a923a';
                case 9:
                    return '#3a923a';
                case 10:
                    return '#3a923a';
                case 11:
                    return '#3a923a';
                case 12:
                    return '#3a923a';
                case 13:
                    return '#3a923a';
                case 14:
                    return '#c03d3e';
                case 15:
                    return '#c03d3e';
                case 16:
                    return '#9372b2';
                case 17:
                    return '#9372b2';
                case 18:
                    return '#9372b2';
                case 19:
                    return '#9372b2';
                case 20:
                    return '#9372b2';
                case 21:
                    return '#9372b2';
                case 22:
                    return '#9372b2';
                case 23:
                    return '#9372b2';
                default:
                    break;
            }
        };

            csv.forEach((item) => {
                try {
                    var trace = {
                        mode: "markers",
                        type: 'box',
                        name: item.genome,
                        marker: { color: getPlotColor(data.length) },
                        y: Object.values(item.data).filter((f, i) => !isNaN(f) && f != "Infinity" && i !== 2),
                        autobinx: true,
                        autobiny: true,
                        boxpoints: 'all',
                        text: ['min', '25pct', 'median', '75pct', 'max'],
                        hovertemplate: '%{text}: %{y}<extra></extra>',
                        pointpos: 0.1
                    };
                    data.push(trace);
                } catch (e) {
                    console.log(e);
                }
            });
        
        // function get_mean_coverage(data) {
        //     let refLength = 0;
        //     let totalCoverage = 0;
        //     let meanCvg;

        //     for (let x = 0; x < data.length; x++) {
        //         totalCoverage += data[x] * x;
        //         refLength += data[x];
        //     }

        //     meanCvg = totalCoverage / refLength;

        //     return meanCvg.toFixed(2);
        // }

        // const genomeData = csv.base_coverage["Genome"];

        // if (genomeData === undefined)
        //    return undefined; 
            
        // const gac = get_mean_coverage(genomeData);

        // const calculateVector = (data) => {
        
        //     //input
        //     var C = data;
        //     var n = data.length;
        //     var x = gac;
        //     //output
        //     var H = [];
        //     var m = 100;

        //     for (var i = 0; i < m; i++) {
        //         H[i] = 0.0;
        //     }

        //     var t = 0;
        //     for (var j = 0; j < n; j++) {
        //         t = t + C[j];
        //     }

        //     if (t < 2000)
        //         return H;
        //     var j = 0;
        //     var i = 0;
        //     //var step = parseInt(t / m + 0.5);
        //     var step = parseInt(t / m) + 1; 
        //     var spil = step;

        //     while (i < n) {
        //         if (C[i] >= spil) {
        //             H[j] = H[j] + i * spil / step;
        //             C[i] = C[i] - spil;
        //             j = j + 1;
        //             spil = step;
        //         }
        //         else {
        //             H[j] = H[j] + i * C[i] / step;
        //             spil = spil - C[i];
        //             i = i + 1;
        //         }
        //     }

        //     for (var i = 0; i < m; i++) {
        //         H[i] = parseFloat(H[i] / x);
        //     }
            
        //     return H;
        // }

        // const calculateVectorManually = (data) => {
        //     var H = calculateVector(data);
        //     var min = H[9].toFixed(2);
        //     var q1 = H[24].toFixed(2);
        //     var median = H[49].toFixed(2);
        //     var q3 = H[74].toFixed(2);
        //     var max = H[89].toFixed(2);
            
        //     return [min, q1, median, q3, max];
        // }


        // Object.keys(csv.base_coverage).forEach((key, k) => {
        //     try {
        //         var vector = calculateVectorManually(csv["base_coverage"][key]);
        //         var trace = {
        //             mode: "markers",
        //             type: "box",
        //             name: key,
        //             marker: { color: getPlotColor(k) },
        //             y: vector.filter(f => !isNaN(f) && f != "Infinity"),
        //             autobinx: true,
        //             autobiny: true,
        //             boxpoints: 'all',
        //             text: ['min', '25pct', 'median', '75pct', 'max'],
        //             hovertemplate: '%{text}: %{y}<extra></extra>',
        //             pointpos: 0.1
        //         };
        //         data.push(trace);
        //     }
        //     catch (e) {
        //     }
        // });

        let layout = {
            title: `GC bias per genome region<br> ${runid}: ${barcode}`,
            font: {
                //color: 'white'
            },
            xaxis: {
                ticks: 'outside',
                //tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
                automargin: true,
                //tickangle: -90,
            },
            yaxis: {
                ticks: 'outside',
                //tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
                //autorange: true,
                range: [0, 2],
                automargin: true,
            },
            showlegend: false,
            shapes: [
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 0.5,
                    x1: 1,
                    y1: 0.5,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 1,
                    x1: 1,
                    y1: 1,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 1.5,
                    x1: 1,
                    y1: 1.5,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 2,
                    x1: 1,
                    y1: 2,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 2.5,
                    x1: 1,
                    y1: 2.5,
                    line: {
                        //color: 'white',
                        width: 1,
                        //dash: 'dot'
                    }
                },
            ]
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };
        
        return { data, layout, config };
    }
    catch (e) { 
        console.log(e);
        return undefined;
     }
}