import React from 'react';

const HtmlPopup = ({ barcode, htmlContent }) => {
  const openPopup = (event) => {
    event.preventDefault();
    try {
      // Create a Blob from the HTML content
      const blob = new Blob([htmlContent], { type: 'text/html' });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Open the Blob URL in a new window
      window.open(url, 'popupWindow', 'width=600,height=400,scrollbars=yes');
    } catch (error) {
      console.error('Failed to open HTML content:', error);
    }
  };

  return (
    <div>
      <a href="#" style={{fontSize: 16, color: '#0056b3', textDecoration: 'none'}} onClick={openPopup}>Open {barcode}</a>
    </div>
  );
};

export default HtmlPopup;