export const RenderTemperaturesHealthChart = csv => {
    try {
        let data = [];

        function unpack(rows, key) {
            return rows.map(function (row) { return row[key] });
        }

        function unpackFloat(rows, key) {
            return rows.map(function (row) { return parseFloat(row[key]) });
        }   

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };
        
        let values = groupBy(csv, 'TABLENAME');

        Object.keys(values).forEach(item => {
            let rows = values[item];
            let data_temp = {
                type: "scatter",
                mode: "lines",
                name: item,
                x: unpack(rows, 'LogTs'),
                y: unpackFloat(rows, 'Value'),
                //line: { color: '#1f77b4' },        
            };
            data.push(data_temp);
        });

        let layout = {
            margin: {
                t: 2,
                l: 45
            },
            titlefont: {
                "size": 14
            },
            //width: 1350,
            height: 750,
            xaxis: {
                type: 'date',
                tickformat: '%x %X',
                tickfont: {
                    size: 10,
                },
            },
            yaxis: {
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                tick0: 0,
                //dtick: 5,
                showline: true,
                tickfont: {
                    size: 10,
                },
            },
            showlegend: true,
            legend: {
                font: {
                    size: 10,
                },
            }
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch { return {}; }
}

export const RenderTemperaturesHealthChartByTrace = (csv, trace = "") => {
    try {
        let heater = ["Bowl1Tmpr", "Bowl2Tmpr", "Chuck1CeilingTmpr1", "Chuck1CeilingTmpr2", "Chuck2CeilingTmpr1", "Chuck2CeilingTmpr2", "MidCeilingTmpr1", "MidCeilingTmpr2", "Manifold1Tmpr", "Manifold2Tmpr", "PeltierTmpr"];
        let airTemperature = ["SeqAirTmpr", "ServerAirTmpr", "ECabinetAirTmpr", "IntakeAirTmpr"];
        let opticsTemps = ["Board1Tmpr", "Board2Tmpr", "Shutter1Tmpr", "Shutter2Tmpr", "Laser1Tmpr", "Laser2Tmpr", "Laser1HeadTmpr", "Laser2HeadTmpr", "Laser1CaseTmpr", "Laser2CaseTmpr", "AFHousing1Tmpr", "AFHousing2Tmpr", "Dichroic1Tmpr"];
        let seqPlatform = ["Linear1Tmpr", "Linear2Tmpr", "Rotary1Tmpr", "Rotary2Tmpr", "Chuck1Tmpr", "Chuck2Tmpr"];
        let intakeTemperatures = ["LeftReagentsTmpr", "RightReagentsTmpr", "WaterPurifierTmpr"];
        
        csv = csv.filter(f => {
            if ((trace === "airTemperature" && airTemperature.includes(f.TABLENAME))
            || (trace === "heaters" && heater.includes(f.TABLENAME))
            || (trace === "opticsTemps" && opticsTemps.includes(f.TABLENAME))
            || (trace === "seqPlatform" && seqPlatform.includes(f.TABLENAME))
            || (trace === "intakeTemperatures" && intakeTemperatures.includes(f.TABLENAME))
            || trace ==='') {
                return f;
            }
        });       

        let data = [];
        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };    

        const unpack = (rows, key) => {
            return rows.map(function (row) { return row[key] });
        }

        const unpackFloat = (rows, key) => {
            return rows.map(function (row) { return parseFloat(row[key]) });
        }

        let values = groupBy(csv, 'TABLENAME');

        Object.keys(values).forEach(item => {
            let rows = values[item];
            let data_temp = {
                type: "scatter",
                mode: "lines",
                name: item,
                x: unpack(rows, 'LogTs'),
                y: unpackFloat(rows, 'Value'),
            };
            if (trace === "")
                data_temp.visible = 'legendonly';
            data.push(data_temp);
        });

        let layout = {
            autosize: true,
            margin: {
                t: 2,
                l: 45
            },
            titlefont: {
                "size": 14
            },
            height: 650,
            xaxis: {
                type: 'date',
                tickformat: '%x %X',
                tickfont: {
                    size: 10,
                },
                automargin: true,
            },
            yaxis: {
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                tick0: 0,
                showline: true,
                tickfont: {
                    size: 10,
                },
                automargin: true,
            },
            showlegend: true,
            legend: {
                font: {
                    size: 10,
                },
            }
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch {
        return undefined
    }
}

export const RenderHumidityHealthChart = csv => {
    try {
        let data = [];        

        function unpackRaw(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };

        let values = groupBy(csv, 'TABLENAME');

        Object.keys(values).forEach(item => {
            let rows = values[item];
            let data_temp = {
                type: "scatter",
                mode: "lines",
                name: item,
                x: unpackRaw(rows, 'LogTs'),
                y: unpackRaw(rows, 'Value'),
                //line: { color: '#1f77b4' },        
            };
            data.push(data_temp);
        });

        let layout = {
            margin: {
                t: 2,
                l: 45
            },
            titlefont: {
                "size": 14
            },
            //width: 1350,
            height: 750,
            xaxis: {
                type: 'date',
                tickformat: '%x %X',
                tickfont: {
                    size: 10,
                },
            },
            yaxis: {
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                tick0: 0,
                showline: true,
                tickfont: {
                    size: 10,
                },
            },
            showlegend: true,
            legend: {
                font: {
                    size: 10,
                },
            }
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch { }
}

export const RenderImageDeformationChart1 = (csv, value, column) => {
    try {
        let filtered = [];
        let yData1 = [];
        let yDataStdDev1 = [];
        let yData11 = [];
        let yDataStdDev11 = [];
        let yData12 = [];
        let yDataStdDev12 = [];
        let yData13 = [];
        let yDataStdDev13 = [];
        let yData2 = [];
        let yDataStdDev2 = [];
        let yData21 = [];
        let yDataStdDev21 = [];
        let yData22 = [];
        let yDataStdDev22 = [];
        let yData23 = [];
        let yDataStdDev23 = [];

        let word = column.split(" ");


        function average(array) {
            let i = 0, sum = 0, len = array.length;
            while (i < len) {
                sum = sum + array[i++];
            }
            return sum / len;
        }

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };       

        function unpack_int(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }       

        function getData(rows, key) {
            return Object.values(rows).map(m => average(m.map(mm => parseFloat(mm[key]))));
        }


        switch (parseInt(value)) {
            case 1:
                csv.forEach(m => {
                    let temp = Object.keys(m).filter(f => f.includes('flow') || f.includes('mean') || f.includes('stddev')).reduce((obj, key) => {
                        obj[key] = m[key];
                        return obj;
                    }, {});
                    filtered.push(temp);
                    yData1 = getData(groupBy(filtered, 'flow'), 'dist_mean');
                    yDataStdDev1 = getData(groupBy(filtered, 'flow'), 'dist_stddev');
                    yData11 = getData(groupBy(filtered, 'flow'), 'angle_turns_mean').map(m => m * 1000000);
                    yDataStdDev11 = getData(groupBy(filtered, 'flow'), 'angle_stddev').map(m => m * 1000000);
                    yData12 = getData(groupBy(filtered, 'flow'), 'gx_mean');
                    yDataStdDev12 = getData(groupBy(filtered, 'flow'), 'gx_stddev');
                    yData13 = getData(groupBy(filtered, 'flow'), 'gy_mean');
                    yDataStdDev13 = getData(groupBy(filtered, 'flow'), 'gy_stddev');
                    yData2 = getData(groupBy(filtered, 'flow'), 'axx_mean');
                    yDataStdDev2 = getData(groupBy(filtered, 'flow'), 'axx_stddev');
                    yData21 = getData(groupBy(filtered, 'flow'), 'axy_mean');
                    yDataStdDev21 = getData(groupBy(filtered, 'flow'), 'axy_stddev');
                    yData22 = getData(groupBy(filtered, 'flow'), 'ayx_mean');
                    yDataStdDev22 = getData(groupBy(filtered, 'flow'), 'ayx_stddev');
                    yData23 = getData(groupBy(filtered, 'flow'), 'ayy_mean');
                    yDataStdDev23 = getData(groupBy(filtered, 'flow'), 'ayy_stddev');
                });

                break;

            case 2:
                csv.forEach(m => {
                    let temp = Object.keys(m).filter(f => f.includes('flow') || f.includes('min') || f.includes('max')).reduce((obj, key) => {
                        obj[key] = m[key];
                        return obj;
                    }, {});
                    filtered.push(temp);
                    yData1 = getData(groupBy(filtered, 'flow'), 'dist_min');
                    yDataStdDev1 = getData(groupBy(filtered, 'flow'), 'dist_max');
                    yData11 = getData(groupBy(filtered, 'flow'), 'angle_min').map(m => m * 1000000);
                    yDataStdDev11 = getData(groupBy(filtered, 'flow'), 'angle_max').map(m => m * 1000000);
                    yData12 = getData(groupBy(filtered, 'flow'), 'gx_min');
                    yDataStdDev12 = getData(groupBy(filtered, 'flow'), 'gx_max');
                    yData13 = getData(groupBy(filtered, 'flow'), 'gy_min');
                    yDataStdDev13 = getData(groupBy(filtered, 'flow'), 'gy_max');
                    yData2 = getData(groupBy(filtered, 'flow'), 'axx_min');
                    yDataStdDev2 = getData(groupBy(filtered, 'flow'), 'axx_max');
                    yData21 = getData(groupBy(filtered, 'flow'), 'axy_min');
                    yDataStdDev21 = getData(groupBy(filtered, 'flow'), 'axy_max');
                    yData22 = getData(groupBy(filtered, 'flow'), 'ayx_min');
                    yDataStdDev22 = getData(groupBy(filtered, 'flow'), 'ayx_max');
                    yData23 = getData(groupBy(filtered, 'flow'), 'ayy_min');
                    yDataStdDev23 = getData(groupBy(filtered, 'flow'), 'ayy_max');
                });

                break;

            case 3:
                csv.forEach(m => {
                    let temp = Object.keys(m).filter(f => f.includes('flow') || f.includes('1pt') || f.includes('99pt')).reduce((obj, key) => {
                        obj[key] = m[key];
                        return obj;
                    }, {});
                    filtered.push(temp);
                    yData1 = getData(groupBy(filtered, 'flow'), 'dist_1pt');
                    yDataStdDev1 = getData(groupBy(filtered, 'flow'), 'dist_99pt');
                    yData11 = getData(groupBy(filtered, 'flow'), 'angle_1pt').map(m => m * 1000000);
                    yDataStdDev11 = getData(groupBy(filtered, 'flow'), 'angle_99pt').map(m => m * 1000000);
                    yData12 = getData(groupBy(filtered, 'flow'), 'gx_1pt');
                    yDataStdDev12 = getData(groupBy(filtered, 'flow'), 'gx_99pt');
                    yData13 = getData(groupBy(filtered, 'flow'), 'gy_1pt');
                    yDataStdDev13 = getData(groupBy(filtered, 'flow'), 'gy_99pt');
                    yData2 = getData(groupBy(filtered, 'flow'), 'axx_1pt');
                    yDataStdDev2 = getData(groupBy(filtered, 'flow'), 'axx_99pt');
                    yData21 = getData(groupBy(filtered, 'flow'), 'axy_1pt');
                    yDataStdDev21 = getData(groupBy(filtered, 'flow'), 'axy_99pt');
                    yData22 = getData(groupBy(filtered, 'flow'), 'ayx_1pt');
                    yDataStdDev22 = getData(groupBy(filtered, 'flow'), 'ayx_99pt');
                    yData23 = getData(groupBy(filtered, 'flow'), 'ayy_1pt');
                    yDataStdDev23 = getData(groupBy(filtered, 'flow'), 'ayy_99pt');
                });

                break;

            case 4:
                csv.forEach(m => {
                    let temp = Object.keys(m).filter(f => f.includes('flow') || f.includes('5pt') || f.includes('95pt')).reduce((obj, key) => {
                        obj[key] = m[key];
                        return obj;
                    }, {});
                    filtered.push(temp);
                    yData1 = getData(groupBy(filtered, 'flow'), 'dist_5pt');
                    yDataStdDev1 = getData(groupBy(filtered, 'flow'), 'dist_95pt');
                    yData11 = getData(groupBy(filtered, 'flow'), 'angle_5pt').map(m => m * 1000000);
                    yDataStdDev11 = getData(groupBy(filtered, 'flow'), 'angle_95pt').map(m => m * 1000000);
                    yData12 = getData(groupBy(filtered, 'flow'), 'gx_5pt');
                    yDataStdDev12 = getData(groupBy(filtered, 'flow'), 'gx_95pt');
                    yData13 = getData(groupBy(filtered, 'flow'), 'gy_5pt');
                    yDataStdDev13 = getData(groupBy(filtered, 'flow'), 'gy_95pt');
                    yData2 = getData(groupBy(filtered, 'flow'), 'axx_5pt');
                    yDataStdDev2 = getData(groupBy(filtered, 'flow'), 'axx_95pt');
                    yData21 = getData(groupBy(filtered, 'flow'), 'axy_5pt');
                    yDataStdDev21 = getData(groupBy(filtered, 'flow'), 'axy_95pt');
                    yData22 = getData(groupBy(filtered, 'flow'), 'ayx_5pt');
                    yDataStdDev22 = getData(groupBy(filtered, 'flow'), 'ayx_95pt');
                    yData23 = getData(groupBy(filtered, 'flow'), 'ayy_5pt');
                    yDataStdDev23 = getData(groupBy(filtered, 'flow'), 'ayy_95pt');
                });

                break;

            default:
                filtered = csv;
                break;
        }

        let flows = unpack_int(filtered, 'flow');

        let data;

        //chart 1
        if (column === "mean & stddev") {
            let data1 =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData1,
                error_y: {
                    type: 'data',
                    symmetric: false,
                    array: yDataStdDev1,
                    arrayminus: yDataStdDev1
                },
                type: 'scatter',
                name: `${column} distance(pix)`,
            };

            let data11 =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData11,
                error_y: {
                    type: 'data',
                    symmetric: false,
                    array: yDataStdDev11,
                    arrayminus: yDataStdDev11
                },
                type: 'scatter',
                name: `${column} rotation(1M x radian/4096)`,
            };

            let data12 =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData12,
                error_y: {
                    type: 'data',
                    symmetric: false,
                    array: yDataStdDev12,
                    arrayminus: yDataStdDev12
                },
                type: 'scatter',
                name: `${column} X shift(pix)`
            };


            let data13 =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData13,
                error_y: {
                    type: 'data',
                    symmetric: false,
                    array: yDataStdDev13,
                    arrayminus: yDataStdDev13
                },
                type: 'scatter',
                name: `${column} Y shift(pix)`
            };

            data = [data1, data11, data12, data13];
        } else {

            let data1a =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData1,
                type: 'scatter',
                name: `${word[0]} distance(pix)`,
            };

            let data1b =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yDataStdDev1,
                type: 'scatter',
                name: `${word[2]} distance(pix)`,
            };

            let data11a =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData11,
                type: 'scatter',
                name: `${word[0]} rotation(1M x radian/4096)`,
            };

            let data11b =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yDataStdDev11,
                type: 'scatter',
                name: `${word[2]} rotation(1M x radian/4096)`,
            };

            let data12a =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData12,
                type: 'scatter',
                name: `${word[0]} X shift(pix)`
            };

            let data12b =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yDataStdDev12,
                type: 'scatter',
                name: `${word[2]} X shift(pix)`
            };

            let data13a =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData13,
                type: 'scatter',
                name: `${word[0]} Y shift(pix)`
            };

            let data13b =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yDataStdDev13,
                type: 'scatter',
                name: `${word[2]} Y shift(pix)`
            };

            data = [data1a, data1b, data11a, data11b, data12a, data12b, data13a, data13b]
        }

        //chart 2
        let data2i;

        if (column === "mean & stddev") {
            let data2 =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData2,
                error_y: {
                    type: 'data',
                    symmetric: false,
                    array: yDataStdDev2,
                    arrayminus: yDataStdDev2
                },
                type: 'scatter',
                name: `${column} Axx`,
            };

            let data21 =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData21,
                error_y: {
                    type: 'data',
                    symmetric: false,
                    array: yDataStdDev21,
                    arrayminus: yDataStdDev21
                },
                type: 'scatter',
                name: `${column} Axy`,
            };

            let data22 =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData22,
                error_y: {
                    type: 'data',
                    symmetric: false,
                    array: yDataStdDev22,
                    arrayminus: yDataStdDev22
                },
                type: 'scatter',
                name: `${column} Ayx`
            };

            let data23 =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData23,
                error_y: {
                    type: 'data',
                    symmetric: false,
                    array: yDataStdDev23,
                    arrayminus: yDataStdDev23
                },
                type: 'scatter',
                name: `${column} Ayy`
            };

            data2i = [data2, data21, data22, data23];
        } else {

            let data2a =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData2,
                type: 'scatter',
                name: `${word[0]} Axx`,
            };

            let data2b =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yDataStdDev2,
                type: 'scatter',
                name: `${word[2]} Axx`,
            };

            let data21a =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData21,
                type: 'scatter',
                name: `${word[0]} Axy`,
            };

            let data21b =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yDataStdDev21,
                type: 'scatter',
                name: `${word[2]} Axy`,
            };

            let data22a =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData22,
                type: 'scatter',
                name: `${word[0]} Ayx`
            };

            let data22b =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yDataStdDev22,
                type: 'scatter',
                name: `${word[2]} Ayx`
            };

            let data23a =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yData23,
                type: 'scatter',
                name: `${word[0]} Ayy`
            };

            let data23b =
            {
                x: flows.filter((item, index) => flows.indexOf(item) === index),
                y: yDataStdDev23,
                type: 'scatter',
                name: `${word[2]} Ayy`
            };

            data2i = [data2a, data2b, data21a, data21b, data22a, data22b, data23a, data23b]
        }

        let layout = {
            autosize: true,
            //title: '<b>Rescan Statistics</b>',
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            font: {
                size: 10,
                //color: 'white'
            },
            titlefont: {
                "size": 14
            },
            //width: 1250,
            height: 850,
            xaxis: {
                title: 'Flow',
                titlefont: {
                    "size": 12
                },
                //tick0: 0,
                dtick: 1,
                //tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
                automargin: true,
            },
            yaxis: {
                //title: 'Rescan/Flow',
                titlefont: {
                    "size": 12
                },
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                //tick0: 0,
                //dtick: 5,
                showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
                automargin: true,
            },
            showlegend: true,
            legend: {
                x: 0,
                y: -0.2
            },
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, 'data1': data2i, 'layout1': layout, config };
    }
    catch  {       
    }
}

export const RenderImageDeformationChart2 = (csv, value1, value2) => {
    try {
        let dataAll = [];
        let filtered = [];    

        let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };      

        function unpack_int(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }

        function getValue1(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'dist';

                case 2:
                    return 'angle';

                case 3:
                    return 'gx';

                case 4:
                    return 'gy';

                case 5:
                    return 'axx';

                case 6:
                    return 'axy';

                case 7:
                    return 'ayx';

                case 8:
                    return 'ayy';

                default:
                    return 'angle';
            }
        }

        function getValue2(value) {
            switch (parseInt(value)) {
                case 1:
                    return { val1: 'mean', val2: 'stddev' };

                case 2:
                    return { val1: 'min', val2: 'max' };

                case 3:
                    return { val1: '1pt', val2: '99pt' };

                case 4:
                    return { val1: '5pt', val2: '95pt' };

                default:
                    return { val1: 'mean', val2: 'stddev' };
            }
        }

        csv.forEach(m => {
            let temp = Object.keys(m).filter(f => (f.includes('flow') || f.includes('ring') || f.includes(getValue2(value2).val1) || f.includes(getValue2(value2).val2))
                && (f.includes(getValue1(value1)) || f.includes('ring') || f.includes('flow')))
                .reduce((obj, key) => {
                    obj[key] = m[key];
                    return obj;
                }, {});
            filtered.push(temp);
        });
        let flows = unpack_int(filtered, 'flow');
        let grouped = groupBy(filtered, 'ring');
        Object.keys(grouped).forEach(row => {
            let found = [];
            let foundPlus = [];
            let foundMinus = [];
            let text1;
            let text2;
            grouped[row].filter(m => {
                let k = Object.keys(m).find(key => {
                    text1 = getValue2(value2).val1;
                    return key.includes(text1);
                });
                let kPlus = Object.keys(m).find(key => {
                    text2 = getValue2(value2).val2;
                    return key.includes(text2);
                });
                let kMinus = Object.keys(m).find(key => {
                    text2 = getValue2(value2).val2;
                    return key.includes(text2);
                });
                found.push(value1 === 2 ? parseFloat(m[k]) * 1000000 : parseFloat(m[k]));
                foundPlus.push(parseFloat(m[kPlus]));
                foundMinus.push(parseFloat(m[kMinus]));

            });
            if (parseInt(value2) === 1) {

                let temp =
                {
                    x: flows.filter((item, index) => flows.indexOf(item) === index),
                    y: found,
                    error_y: {
                        type: 'data',
                        symmetric: false,
                        array: foundPlus,
                        arrayminus: foundMinus
                    },
                    type: 'scatter',
                    name: `R${row}`,
                };

                dataAll.push(temp);

            } else {
                let temp1 =
                {
                    x: flows.filter((item, index) => flows.indexOf(item) === index),
                    y: found,
                    type: 'scatter',
                    name: `R${row} ${text1}`,
                };

                let temp2 =
                {
                    x: flows.filter((item, index) => flows.indexOf(item) === index),
                    y: foundPlus,
                    type: 'scatter',
                    name: `R${row} ${text2}`,
                };

                dataAll.push(temp1);
                dataAll.push(temp2);
            }


        });

        let layout = {
            autosize: true,
            //title: '<b>Rescan Statistics</b>',
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            font: {
                size: 10,
                //color: 'white'
            },
            titlefont: {
                "size": 14
            },
            //width: 1250,
            height: 850,
            xaxis: {
                title: 'Flow',
                titlefont: {
                    "size": 12
                },
                //tick0: 0,
                dtick: 1,
                //tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
                automargin: true,
            },
            yaxis: {
                //title: 'Rescan/Flow',
                titlefont: {
                    "size": 12
                },
                ticks: 'outside',
                tickcolor: '#000',
                tickangle: 0,
                //tick0: 0,
                //dtick: 5,
                showline: true,
                tickfont: {
                    size: 10,
                    //color: 'white'
                },
                automargin: true,
            },
            showlegend: true,
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { 'data': dataAll, layout, config };
    }
    catch {
        
    }
}