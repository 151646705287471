import React from 'react';
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import LoadingSpinner from '../spinner/spinner';
import RenderPlot from '../plots/renderplot';
import ShowAlert from '../showalert/showalert';

const AMPRunHealth = (props) => {
    const state = useSelector(state => state);
   
    const { ampPlot1Data, ampPlot2Data } = props;

    return (
        <div style={{marginTop: 30, }}>
            {state.is_amp_plots_data_loading ?
                <LoadingSpinner /> :
                <React.Fragment>
                    {(ampPlot1Data != undefined) ?
                        <Row>
                            <Col lg={{ span: 11 }}>
                                {<RenderPlot id="ampPlot1" data={ampPlot1Data.data && ampPlot1Data.data} layout={ampPlot1Data.layout && ampPlot1Data.layout} config={ampPlot1Data.config && ampPlot1Data.config} />}
                            </Col>
                        </Row> : <ShowAlert type={'warning'} text='No data found' />}
                    {(ampPlot2Data != undefined) ?
                        <Row>
                            <Col lg={{ span: 11 }}>
                                {<RenderPlot id="ampPlot2" data={ampPlot2Data.data && ampPlot2Data.data} layout={ampPlot2Data.layout && ampPlot2Data.layout} config={ampPlot2Data.config && ampPlot2Data.config} />}
                            </Col>
                        </Row>
                        : <ShowAlert type={'warning'} text='No data found' />}
                    {(ampPlot2Data != undefined && ampPlot2Data.data1) ?
                        <Row>
                            <Col lg={{ span: 11 }}>
                                {<RenderPlot id="ampPlot3" data={ampPlot2Data.data1 && ampPlot2Data.data1} layout={ampPlot2Data.layout1 && ampPlot2Data.layout1} config={ampPlot2Data.config && ampPlot2Data.config} />}
                            </Col>
                        </Row>
                        : null}

                </React.Fragment>
            }
        </div>
    )
};

export default AMPRunHealth;