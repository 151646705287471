import React from "react";
import AuthAPI from '../auth/api';
import { useHistory } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { setUser, setIsAuthenticated, setJWT, setJWTExp } from '../actions/index';
import Cookies from 'universal-cookie';

const Redirect = () => {
    let history = useHistory();
    const dispatch = useDispatch();

    const redirect = () => {
        window.location.href = `${process.env.REACT_APP_API_URL_AUTH}/html/signin`;
    };

    React.useEffect(() => {
        AuthAPI.isAuthenticated().then(response => {
            if (response.status === 200 && response.data != null) {
                try {
                    const cookies = new Cookies(null, { path: '/' });
                    const token = cookies.get('token');
                    if (token) {
                        dispatch(setJWT(token));
                        dispatch(setJWTExp(Date.now() + parseInt(process.env.REACT_APP_JWT_EXP_TIME_MS)));
                        dispatch(setUser(response.data));
                        dispatch(setIsAuthenticated(true));
                        history.push("/");
                    }
                    else redirect();
                }
                catch {
                    dispatch(setJWT(undefined));
                    redirect();
                }
            }
            else redirect();
        }).catch(() => { dispatch(setJWT(undefined)); redirect(); });
    }, []);

    return (
        <React.Fragment>
        </React.Fragment>
    );
};

export default Redirect;