import React from 'react';
import { Alert } from 'react-bootstrap';

const AlertPopUp = (props) => {
    const { show, text } = props;

    return (
        <Alert show={show} variant="warning">            
             {text}
        </Alert>
    );
};

export default AlertPopUp;