export const PlotBarcodeCoverageCOVHGPlot = (runId, csv, selectedBarcode) => {
    try {
        function get_mean_coverage(data) {
            let refLength = 0;
            let totalCoverage = 0;
            let meanCvg;

            for (let x = 0; x < data.length; x++) {
                totalCoverage += data[x] * x;
                refLength += data[x];
            }

            meanCvg = totalCoverage / refLength;

            return meanCvg.toFixed(2);
        }

        const genomeData = csv.base_coverage["Genome"];
        const exomeData = csv.base_coverage["Exome"];
        const mean_cvg_genome = get_mean_coverage(genomeData);
        const mean_cvg_exome = get_mean_coverage(exomeData);

        let ref_length_G = 0;
        let ref_length_E = 0;
        let tG = [];
        let tE = [];

        for (let i = 0; i < genomeData.length; i++) {
            ref_length_G += genomeData[i];
        }
        for (let i = 0; i < exomeData.length; i++) {
            ref_length_E += exomeData[i];
        }

        for (let i = 0; i < genomeData.length; i++) {
            tG[i] = (genomeData[i] / ref_length_G).toFixed(3);
        }

        for (let i = 0; i < exomeData.length; i++) {
            tE[i] = (exomeData[i] / ref_length_E).toFixed(3);
        }

        let max_X_genome = 80;
        if (mean_cvg_genome > 45) {
            max_X_genome = 2 * mean_cvg_genome
        }
        let max_X_exome = 80
        if (mean_cvg_exome > 45) {
            max_X_exome = 2 * mean_cvg_exome;
        }

        let data = [];
        runId = runId.split('_')[0];

        let gnome_coverage = []
        let exome_coverage = []
        for (let x = 0; x < max_X_genome; x++) {
            gnome_coverage[x] = x;
        }
        for (let x = 0; x < max_X_exome; x++) {
            exome_coverage[x] = x;
        }

        let genome = {
            type: "scatter",
            mode: "lines",
            name: "Genome",
            line: { color: "#1f77b4" },
            y: tG,
            x: gnome_coverage
        }
        let exnome = {
            type: "scatter",
            mode: "lines",
            name: "Exome",
            line: { color: "orange" },
            y: tE,
            x: exome_coverage
        }

        data = [genome, exnome];

        let layout = {
            autosize: true,
            title: `Histogram of coverage per genome loci <br> ${runId}: ${selectedBarcode}`,
            margin: {
                t: 25,
                b: 105,
                l: 95
            },
            titlefont: {
                "size": 14
            },
            //width: 1500,
            //height: 450,
            xaxis: {
                title: "Coverage",
                titlefont: {
                    "size": 14
                },
                ticks: 'outside',
                automargin: true,
            },
            yaxis: {
                title: "Ratio of Target",
                titlefont: {
                    "size": 14
                },
                automargin: true,
            },
            font: {
            },
            showlegend: true,
            legend: {
                x: 0.2,
                y: 1.8
            },  
            /*legend: {
                x: 0,
                y: -0.3
            },*/
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data: data, layout: layout, config: config };
    }
    catch (e) {
        return undefined;
    }
}

export const PlotBarcodeCoveragePlot = (runid, csv, barcode, chartName) => {
    try {
        let data = [];

        function get_mean_coverage(data) {
            let refLength = 0;
            let totalCoverage = 0;
            let meanCvg;

            for (let x = 0; x < data.length; x++) {
                totalCoverage += data[x] * x;
                refLength += data[x];
            }

            meanCvg = totalCoverage / refLength;

            return meanCvg.toFixed(2);
        }

        const genomeData = csv.base_coverage["Genome"];
        const gac = get_mean_coverage(genomeData);

        //if (runid)
        runid = runid.split('_')[0];

        const calculateVector = (data) => {
            //input
            let C = data;
            let n = data.length;
            let x = gac;
            //output
            let H = [];
            let m = 100;

            for (let i = 0; i < m; i++) {
                H[i] = 0.0;
            }

            let t = 0;
            for (let j = 0; j < n; j++) {
                t = t + C[j];
            }

            if (t < m)
                return H;
            let j = 0;
            let i = 0;
            let step = parseInt(t / m + 0.5);
            let spil = step;

            while (i < n) {
                if (C[i] >= spil) {
                    H[j] = H[j] + i * spil / step;
                    C[i] = C[i] - spil;
                    j = j + 1;
                    spil = step;
                }
                else {
                    H[j] = H[j] + i * C[i] / step;
                    spil = spil - C[i];
                    i = i + 1;
                }
            }

            for (let i = 0; i < m; i++) {
                H[i] = parseFloat(H[i] / x);
            }

            return H;
        }

        const calculateVectorManually = (data) => {
            let H = calculateVector(data);
            let min = H[9];
            let q1 = H[24];
            let median = H[49];
            let q3 = H[74];
            let max = H[89];

            return [min, q1, median, q3, max];
        }       

        const getPlotColor = index => {
            switch (index) {
                case 0:
                    return '#3274a1';                    
                case 0:
                    return '#e1812c';                    
                case 1:
                    return '#e1812c';                    
                case 2:
                    return '#e1812c';                    
                case 3:
                    return '#e1812c';                    
                case 4:
                    return '#e1812c';                   
                case 5:
                    return '#3a923a';                    
                case 6:
                    return '#3a923a';                    
                case 7:
                    return '#3a923a';                    
                case 8:
                    return '#3a923a';                    
                case 9:
                    return '#3a923a';                    
                case 10:
                    return '#3a923a';                   
                case 11:
                    return '#3a923a';                    
                case 12:
                    return '#3a923a';                    
                case 13:
                    return '#3a923a';                    
                case 14:
                    return '#c03d3e';                    
                case 15:
                    return '#c03d3e';                    
                case 16:
                    return '#9372b2';                    
                case 17:
                    return '#9372b2';                    
                case 18:
                    return '#9372b2';                    
                case 19:
                    return '#9372b2';                    
                case 20:
                    return '#9372b2';                    
                case 21:
                    return '#9372b2';                    
                case 22:
                    return '#9372b2';                    
                case 23:
                    return '#9372b2';                    
                default:
                    break;
            }
        };

        Object.keys(csv.base_coverage).forEach((key, k) => {
            try {
                 let vector = calculateVectorManually(csv["base_coverage"][key]);
                 let trace = {
                    mode: "markers",
                    type: "box",
                    name: key,
                    y: vector.filter(f => !isNaN(f) && f !== "Infinity"),
                    autobinx: true,
                    autobiny: true,
                    marker: { color: getPlotColor(k) },

                };
                data.push(trace);
            }
            catch (e) {
                console.log(e);
            }
        });

        let layout = {            
            title: `${runid}: ${barcode} GC bias per genome region`,
            font: {
               
            },
            xaxis: {
                ticks: 'outside',
                tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                    
                },
                automargin: true,
                //tickangle: -90,
            },
            yaxis: {
                ticks: 'outside',
                tickcolor: 'white',
                //showline: true,
                tickfont: {
                    size: 10,
                   
                },
                //autorange: true,
                range: [0, 2],
                automargin: true,
            },
            showlegend: false,
            shapes: [
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 0.5,
                    x1: 1,
                    y1: 0.5,
                    line: {                       
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 1,
                    x1: 1,
                    y1: 1,
                    line: {                       
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 1.5,
                    x1: 1,
                    y1: 1.5,
                    line: {                        
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 2,
                    x1: 1,
                    y1: 2,
                    line: {                        
                        width: 1,
                        //dash: 'dot'
                    }
                },
                {
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    y0: 2.5,
                    x1: 1,
                    y1: 2.5,
                    line: {                        
                        width: 1,
                        //dash: 'dot'
                    }
                },
            ]
        };       

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data: data, layout: layout, config: config };
    }
    catch (e) {
        return undefined;
    }
}