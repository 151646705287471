export const RenderReadLengthPlot = (csv, runid, camera) => {
    try {
        if (!csv)
            return undefined;

        function filterIndexes(csv) {
            const filteredCsv = {};
            for (const key in csv) {
                if (key !== 'Length') {
                    filteredCsv[key] = csv[key];
                }
            }
            return filteredCsv;
        }

        let updateCsv = filterIndexes(csv);

        function calPerc(rows, sum) {
            return rows.map((currElement, index) => {
                return ((currElement / sum) * 100).toFixed(2);
            })
        }

        function makeflowbyIndex(rows) {
            return rows.map((currElement, index) => {
                return index;
            })
        }       

        var sum = array => array.reduce((partialSum, a) => partialSum + a, 0);

        const GetLineColor = name => {
            switch (name) {
                case 'Trimmed by adapter':
                    return 'red';
                case 'Trimmed by quality':
                    return '#8ae1e1';
                case 'Trimmed by Adapter or Quality':
                    return '#1f77b4';
                case 'All reads':
                    return 'black';
                default:
                    return '#1f77b4';
            }
        }

        function GenerateDataByFlow(obj) {
            var data = [];
            var keys = Object.keys(obj);
            var values = Object.values(obj);
            keys.forEach((key, i) => {
                var temp = {
                    type: "scatter",
                    mode: "lines",
                    connectgaps: false,
                    name: `${key.trimEnd()}`,
                    y: calPerc(values[i], sum(obj['All reads'])),
                    //y: values[i],
                    x: makeflowbyIndex(values[i]),
                    line: { color: GetLineColor(key) },
                }
                data.push(temp);
            });
            return data;
        }

        var data = GenerateDataByFlow(updateCsv);

        var layout = {
            bargap: 0.3,
            margin: {
                t: 25,
                b: 35,
                l: 55
            },
            titlefont: {
                "size": 14
            },
            height: 500,
            title: `Read-Length Distribution (Bases) - ${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}`,
            xaxis: { title: "Read length (bases)" },
            yaxis: {
                title: "% of reads",
                titlefont: {
                    "size": 12
                }
            },
        };

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };        

        return { data, layout, config };
    }
    catch (e) {
        console.log(e);
        return undefined;
    }
}

export const RenderHomoIndelErrorPlot = (csv, runid, camera) => {
    try {
        const makeflowbyIndex = (rows, idx) => {
            return rows.map((currElement, index) => {
                return index + idx;
            })
        }

        const unpackByCaption = (rows, caption) => {
            var data = [];
            rows.filter(function (row, index) {
                if (caption === 'T' && index % 4 === 0) {
                    data.push(row);
                    data.push(row);
                    data.push(row);
                    data.push(row);
                }
                else if (caption === 'G' && index % 4 === 1) {
                    data.push(row);
                    data.push(row);
                    data.push(row);
                    data.push(row);
                }
                else if (caption === 'C' && index % 4 === 2) {
                    data.push(row);
                    data.push(row);
                    data.push(row);
                    data.push(row);
                }
                else if (caption === 'A' && index % 4 === 3) {
                    data.push(row);
                    data.push(row);
                    data.push(row);
                    data.push(row);
                }
            });

            return data;
        }

        const GetLineColor = name => {
            switch (name) {
                case 'T':
                    return 'red';
                case 'A':
                    return 'green';
                case 'C':
                    return '#1f77b4';
                case 'G':
                    return 'black';
                default:
                    return '#1f77b4';
            }
        }

        const GenerateDataByFlow = (obj) => {
            var data = [];
            var keys = Object.keys(obj);
            var values = Object.values(obj);

            keys.forEach((key, i) => {
                var formattedValues = values[i].map(value => parseFloat(value).toFixed(2)); 
                var temp = {
                    type: "scatter",
                    mode: "lines",
                    connectgaps: false,
                    name: `${key}`,
                    x: makeflowbyIndex(values[i], keys.length + 1),
                    y: formattedValues,
                    line: { color: GetLineColor(key) },
                }
                data.push(temp);
            });
            return data;
        }

        var T = unpackByCaption(csv, 'T');
        var G = unpackByCaption(csv, 'G');
        var C = unpackByCaption(csv, 'C');
        var A = unpackByCaption(csv, 'A');

        var data = GenerateDataByFlow({T, G,C,A });

        var layout = {
            bargap: 0.3,
            margin: {
                t: 25,
                b: 35,
                l: 55
            },
            titlefont: {
                "size": 14
            },
            height: 500,
            title: `HP Indel Error per flow - ${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}`,
            xaxis: { title: "Flows" },
            yaxis: {
                title: "BER (%)",
                titlefont: {
                    "size": 12
                },
                //tickformat: '.2s' 
            },
        };

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch (e) {       
        return undefined;
     }
}

export const RenderReadLengthBaseQualityPlot = (csv, runid, camera, barcode, averageReadLength) => {
    try {
        var avgBQ = [];
        //var stdBQ = [];
        csv.forEach((item) => {
            var avgBQ_temp = (item.reduce((acc, curr, i) => acc + i*curr) / item.reduce((acc, curr) => acc + curr)).toFixed(1);            
            //var stdBQ_temp = Math.sqrt( item.reduce((acc, curr, i) => acc + curr * Math.pow((i - avgBQ), 2)) / item.reduce((acc, curr) => acc + curr) );           
            avgBQ.push(avgBQ_temp);
            //stdBQ.push(stdBQ_temp);
        });
       
        var data = [{
            type: "scatter",
            mode: "lines",
            connectgaps: false,
            name: `Base Quality`,
            //x: csv.map((item, i) => i + 1),
            x: csv.map((item, i) => {
                if(averageReadLength !== null){
                    if (i + 1 <= averageReadLength) {
                        return i + 1;
                    } else {
                        return averageReadLength;
                    }
                } else { 
                    return i + 1;
                }
            }),
            y: avgBQ,
            //line: { color: GetLineColor(key) },
        }];

        var layout = {
            bargap: 0.3,
            margin: {
                t: 25,
                b: 35,
                l: 55
            },
            titlefont: {
                "size": 14
            },
            height: 500,
            title: `Base Quality<br> ${runid !== undefined && runid !== null ? runid.split('_')[0] : runid}: ${barcode}`,
            xaxis: { title: "Read Length" },
            yaxis: {
                title: "Base Quality",
                titlefont: {
                    "size": 12
                }
            },
        };

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch (e) {
        return undefined;
     }
}

export const RenderReadLengthBarcodeDetailsPlot = (csv, runid, barcode) => {
    try { 

        var data = [{
            type: "scatter",
            mode: "lines",
            connectgaps: false,
            name: `Raw Read Length`,
            x: csv.read_length.map((item, i) => i + 1),
            y: csv.read_length,
            //line: { color: GetLineColor(key) },
        },
        {
            type: "scatter",
            mode: "lines",
            connectgaps: false,
            name: `Aligned Read Length`,
            x: csv.aligned_read_length.map((item, i) => i + 1),
            y: csv.aligned_read_length,
            //line: { color: GetLineColor(key) },
        }];

        var layout = {
            bargap: 0.3,
            margin: {
                t: 25,
                b: 35,
                l: 55
            },
            titlefont: {
                "size": 14
            },
            height: 500,
            title: `Average Read Length<br> ${runid !== undefined && runid !== null ? runid.split('_')[0] : runid}: ${barcode}`,
            xaxis: { title: "Read Length" },
            yaxis: {
                tickformat: '.3s'
            }
            /*yaxis: {
                title: "Base Quality",
                titlefont: {
                    "size": 12
                }
            },*/
        };

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch (e) {       
        return undefined;
     }
}