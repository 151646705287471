const RenderReadsFilteringCharts = (csv, runid, camera) => {
    try {
        let updatedCSV = {};
        if(csv.barcodes.length > 25){
            for (const key in csv) {
                if (Array.isArray(csv[key])) {
                    updatedCSV[key] = csv[key].slice(0, 25);
                }
              }
        } else updatedCSV = csv;
        var trace1 = {
            x: updatedCSV.barcodes,
            y: updatedCSV.passed_rsq.map(m => m.toFixed(2)),
            name: 'passed RSQ',
            type: 'bar',
            text: updatedCSV.passed_rsq.map(m => m.toFixed(2)).map(String),
            textposition: 'auto',
        };

        var trace2 = {
            x: updatedCSV.barcodes,
            y: updatedCSV.failed_rsq.map(m => m.toFixed(2)),
            name: 'failed RSQ',
            type: 'bar',
            text: updatedCSV.failed_rsq.map(m => m.toFixed(2)).map(String),
            textposition: 'auto',
        };

        var data = [trace1, trace2];

        var layout = { 
            title: `Reads Counting Breakdown - ${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}`,
            barmode: 'stack',
            yaxis: {
                title: '% of all reads',
            },           
         };

        var config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data: data, layout: layout, config: config };
    }
    catch (e) {
        return undefined;
    }
}

export default RenderReadsFilteringCharts;