export const RenderErrorNerPerFlowPlot = (csv, runid, camera) => {
    try {
        const makeflowbyIndex = (rows, idx) => {
            return rows.map((currElement, index) => {
                return index + idx;
            })
        }

        const unpackByCaption = (rows, caption) => {
            let data = [];
            rows.filter(function (row, index) {
                if (caption === 'T' && index % 4 === 0) {
                    data.push(row);
                    data.push(row);
                    data.push(row);
                    data.push(row);
                }
                else if (caption === 'G' && index % 4 === 1) {
                    data.push(row);
                    data.push(row);
                    data.push(row);
                    data.push(row);
                }
                else if (caption === 'C' && index % 4 === 2) {
                    data.push(row);
                    data.push(row);
                    data.push(row);
                    data.push(row);
                }
                else if (caption === 'A' && index % 4 === 3) {
                    data.push(row);
                    data.push(row);
                    data.push(row);
                    data.push(row);
                }
            });

            return data;
        }

        const GetLineColor = name => {
            switch (name) {
                case 'T':
                    return 'red';
                case 'A':
                    return 'green';
                case 'C':
                    return '#1f77b4';
                case 'G':
                    return 'black';
                default:
                    return '#1f77b4';
            }
        }

        const GenerateDataByFlow = (obj) => {
            let data = [];
            let keys = Object.keys(obj);
            let values = Object.values(obj);
            keys.forEach((key, i) => {
                let temp = {
                    type: "scatter",
                    mode: "lines",
                    connectgaps: false,
                    name: `${key}`,
                    x: makeflowbyIndex(values[i], keys.length + 1),
                    y: values[i],
                    line: { color: GetLineColor(key) },
                }
                data.push(temp);
            });
            return data;
        }

        let T = unpackByCaption(csv[0], 'T');
        let G = unpackByCaption(csv[0], 'G');
        let C = unpackByCaption(csv[0], 'C');
        let A = unpackByCaption(csv[0], 'A');

        let data = GenerateDataByFlow({T, G,C,A });

        let layout = {
            bargap: 0.3,
            margin: {
                t: 25,
                b: 35,
                l: 55
            },
            titlefont: {
                "size": 14
            },
            height: 500,
            title: `BER per flow - ${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}`,
            xaxis: { title: "Flows" },
            yaxis: {
                title: "BER (%)",
                titlefont: {
                    "size": 12
                }
            },
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch (e) {       
        return undefined;
     }
}

export const RenderErrorPerHistPlot = (csv, runid, camera) => {
    try {
        let trace1 = {
            x: csv.ber_bins,
            y: csv.cdf,
            name: 'cdf',
            type: 'scatter',
            //mode: 'lines',
            line: { color: '#ff7f0e' },
        };

        let trace2 = {
            x: csv.ber_bins,
            y: csv.pdf.map(m => m * 100),
            name: 'pdf',
            type: 'scatter',
            //mode: 'lines',
            line: { color: '#1f77b4' },
            yaxis: 'y2',                     
        };

        let data = [trace1, trace2];

        let layout = {
            title: `Read Quality Distribution - ${runid !== undefined && runid !== null ? runid.split('_')[0] : runid} ${camera !== undefined ? camera : ''}`,
            barmode: 'stack',
            yaxis: { 
                title: 'Normalized Counts',
            },
            autoscale: false,
            yaxis2: {
                title: 'Percentage',               
                overlaying: 'y',
                side: 'right',
                range:[0, 100],
                autorange: false,   
            },           
            xaxis: {
                title: '% BER per bead, 200bp read length'
            }
        };

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch {
        return {};
    }
}