import React from 'react';
import './authapp.css';
import '../../App.css';
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../actions/index";
import Redirect from '../auth/redirect';
import Forgot from '../auth/forgot';
import { Switch, Route } from "react-router-dom";
import ProtectedRoute from '../protectedroute/protectedroute';
import AMP from '../amp/amp';
import RootContainer from '../rootcontainer/rootcontainer';
import TopMenu from '../topmenu/topmenu';
import ChartView from '../chartview/chartview';

const AuthApp = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    React.useEffect(() => {
        dispatch(setUser(user));
    }, []);    

    return (
        <div className="App">
            <div className="row">
                <TopMenu />
            </div>
            <div className='container' id="mainContainer">
                <div className="row">
                    <Switch>
                        <ProtectedRoute exact path="/" component={() => <RootContainer />} />
                        <ProtectedRoute path="/amp" component={() => <AMP />} />
                        <ProtectedRoute path="/chartview" component={() => <ChartView />} />
                        <Route exact path="/login" component={() => <Redirect />} />
                        <Route exact path="/forgot" component={() => <Forgot />} />
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default AuthApp;