import React from 'react';
import log from 'loglevel';
import { Row, Col, } from "react-bootstrap";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        //log.error(error, errorInfo);       
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

const refreshPage = () => {
    window.location.reload(false);
}

const handleOnClick = resetErrorBoundary => {
    resetErrorBoundary();
    refreshPage();
}

export const ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
    return (
        <Row>
            <Col lg={12}>
                <div role="alert" className='text-center'>
                    <h2>Something went wrong:</h2>
                    <h4>Please, refresh the page...</h4>
                    {process.env.REACT_APP_SHOW_ERROR_STACK !== undefined && process.env.REACT_APP_SHOW_ERROR_STACK === true &&
                        <>
                            <pre>{error.message}</pre>
                            <pre>{componentStack}</pre>
                            {/*      <button style={{cursor: 'pointer'}} onClick={() => handleOnClick(resetErrorBoundary)}>Try again</button> */}
                        </>
                    }
                </div>
            </Col>
        </Row>
    )
}

export const ErrorHandler = (error, info) => {
    log.error(error, info);
}