import React, { useState } from "react";
import { Document, Page} from "react-pdf";
import "./singlepage.css";
import { Button } from "react-bootstrap";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SinglePage(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const { pdf } = props;


    return (
        <>
            <Document
                file={pdf}
                options={{ workerSrc: "/pdf.worker.js" }}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <div style={{marginTop:20}}>
                <p>
                    Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
                </p>
                <Button className='pdf' variant="primary" style={{ marginTop: '10px' }} size="lg" disabled={pageNumber <= 1} onClick={previousPage} >Previous</Button>

                <Button className='pdf' variant="primary" style={{ marginTop: '10px' }} size="lg" disabled={pageNumber >= numPages} onClick={nextPage} >Next</Button>
            </div>
        </>
    );
}
