export const RenderBinHistogramChartsDensity = (csv, flow, tileArea, isBaseLine, crop, pixelSize, tileWidth, tileHeight) => {
    try {
        let rows = csv;
        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }       

        function unpackInt(rows, key) {
            return rows.map(function (row) {
                if (crop !== undefined) {
                    if (crop.length) {
                        let cropObj = crop.find(f => parseInt(f.radius) === row.radius);
                        if (cropObj !== undefined)
                            return parseInt(parseFloat(row[key]) / parseFloat(((pixelSize * (tileWidth - cropObj.cropLeft - cropObj.cropRight) * pixelSize * tileHeight) / 1000000)));
                    }
                    else return parseInt(parseFloat(row[key]) / parseFloat(tileArea));
                }
                else return parseInt(parseFloat(row[key]) / parseFloat(tileArea));
            });
        }

        function unpackByTileArea(rows, key) {
            return rows.map(function (row) {
                if (crop !== undefined) {
                    if (crop.length) {
                        let cropObj = crop.find(f => parseInt(f.radius) === row.radius);
                        if (cropObj !== undefined)
                            return (parseFloat(row[key]) / parseFloat(((pixelSize * (tileWidth - cropObj.cropLeft - cropObj.cropRight) * pixelSize * tileHeight) / 1000000))).toFixed(0);
                    }
                    else return (parseFloat(row[key]) / parseFloat(tileArea)).toFixed(0);
                }
                else return (parseFloat(row[key]) / parseFloat(tileArea)).toFixed(0);
            });
        }

        Array.prototype.stanDeviate = function () {
            let i, j, total = 0, mean = 0, diffSqredArr = [];
            for (i = 0; i < this.length; i += 1) {
                total += this[i];
            }
            mean = total / this.length;
            for (j = 0; j < this.length; j += 1) {
                diffSqredArr.push(Math.pow((this[j] - mean), 2));
            }
            return (Math.sqrt(diffSqredArr.reduce(function (firstEl, nextEl) {
                return firstEl + nextEl;
            }) / this.length));
        };

        function getSum(total, num) {
            return total + num;
        }

        let arraySum = unpackInt(rows, 'count_beads_sig_gt_threshold').reduce(getSum);
        let arrayCount = rows.length;
        let mean = arraySum / arrayCount;

        let sigma = unpackInt(rows, 'count_beads_sig_gt_threshold').stanDeviate();
        let sigmaVal = 3 * parseInt(sigma);
        let binSIze = (6 * parseInt(sigma)) / 100;

        let Beads = {
            histfunc: "count",
            y: unpack(rows, 'tileID'),
            x: unpackByTileArea(rows, 'count_beads_sig_gt_threshold'),
            type: "histogram",
            xbins: {
                end: parseInt(mean) + sigmaVal,
                size: binSIze,
                start: parseInt(mean) - sigmaVal
            },
            name: 'Bead density per mm&#x00B2;'
        };

        let layout = {
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            bargap: 0.3,
            margin: {
                t: 25,
                b: 35,
                l: 55
            },
            titlefont: {
                "size": 14
            },
            //width: isBaseLine ? 570 : 600,
            height: isBaseLine ? 500 : 400,
            title: "Bead density per mm&#x00B2;=" + parseInt(mean) + "&#177;" + parseFloat((parseInt(sigma) / parseInt(mean)) * 100).toFixed(1) + "%",
            /*xaxis: {
                title: "k/mm2",
                titlefont: {
                    "size": 12
                }
            },*/
            yaxis: {
                title: "Tile Frequency",
                titlefont: {
                    "size": 12
                }
            },
            font: {
                //color: 'white'
            },
           // margin: {r: 10},
        };

        let data = [Beads];

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };


        return { data, layout, config };
    }
    catch {         
        return {};
    }
}

export const RenderBinHistogramChartsSignal = (csv, isBaseLine, histogramContainerName, labelName) => {
    try {
        let rows = csv;
        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }

        function unpackInt(rows, key) {
            return rows.map(function (row) { return parseInt(row[key]); });
        }

        function getSum(total, num) {
            return total + num;
        }

        let arraySum = unpackInt(rows, 'mean_Sig').reduce(getSum);
        let arrayCount = rows.length;
        let mean = arraySum / arrayCount;

        Array.prototype.stanDeviate = function () {
            let i, j, total = 0, mean = 0, diffSqredArr = [];
            for (i = 0; i < this.length; i += 1) {
                total += this[i];
            }
            mean = total / this.length;
            for (j = 0; j < this.length; j += 1) {
                diffSqredArr.push(Math.pow((this[j] - mean), 2));
            }
            return (Math.sqrt(diffSqredArr.reduce(function (firstEl, nextEl) {
                return firstEl + nextEl;
            }) / this.length));
        };

        let sigma = unpackInt(rows, 'mean_Sig').stanDeviate();
        let sigmaVal = 3 * parseInt(sigma);
        let binSIze = (6 * parseInt(sigma)) / 100;

        let Beads = {
            histfunc: "count",
            y: unpack(rows, 'tileID'),
            x: unpack(rows, 'mean_Sig'),
            type: "histogram",
            xbins: {
                end: parseInt(mean) + sigmaVal,
                size: binSIze,
                start: parseInt(mean) - sigmaVal
            },
            //name: "Flow " + flowNumber +  " Signal per tile"
        };

        let layout = {
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            bargap: 0.3,
            margin: {
                t: 25,
                b: 35,
                l: 55
            },
            titlefont: {
                "size": 14
            },
            //width: isBaseLine ? 570 : 600,
            height: isBaseLine ? 500 : 400,
            title: labelName + parseInt(mean) + "&#177;" + parseFloat((parseInt(sigma) / parseInt(mean)) * 100).toFixed(1) + "%",
            //xaxis: { title: "k/mm2" },
            yaxis: {
                title: "Tile Frequency",
                titlefont: {
                    "size": 12
                }
            },
            font: {
                //color: 'white'
            },
        };

        let data = [Beads];

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };
    }
    catch { return {}; }
}

export const RenderBinHistogramChartsSNR = (csv, isBaseLine) => {
    try {
        let rows = csv;

        if (rows == null) {
            return null;
        }

        function unpack(rows, key) {
            return rows.map(function (row) { return row[key]; });
        }        

        function getSnr(rows) {
            return rows.map(function (row) {
                return parseFloat(row['mean_Sig']) / parseFloat(row['mean_SigStd']);
            });
        }

        function getBrightBeads(rows) {
            return rows.map(function (row) {
                return parseFloat(row['mean_beads_sig_gt_threshold']);
            });
        }

        let SNR = getSnr(rows);
        let BrightBeads = getBrightBeads(rows);

        let isBrightBeads = false;
        if (!isBaseLine) {
            if (rows[0].mean_beads_sig_gt_threshold !== "" && rows[0].mean_beads_sig_gt_threshold !== null) {
                isBrightBeads = true;
            }
        }

        function getSum(total, num) {
            return total + num;
        }

        let arraySum = SNR.reduce(getSum);
        let arraySumBead = BrightBeads.reduce(getSum);
        let arrayCount = rows.length;
        let mean = arraySum / arrayCount;
        let meanBead = arraySumBead / arrayCount;

        Array.prototype.stanDeviate = function () {
            let i, j, total = 0, mean = 0, diffSqredArr = [];
            for (i = 0; i < this.length; i += 1) {
                total += this[i];
            }
            mean = total / this.length;
            for (j = 0; j < this.length; j += 1) {
                diffSqredArr.push(Math.pow((this[j] - mean), 2));
            }
            return (Math.sqrt(diffSqredArr.reduce(function (firstEl, nextEl) {
                return firstEl + nextEl;
            }) / this.length));
        };

        let sigma = SNR.stanDeviate();
        let sigmaVal = 3 * parseInt(sigma);
        let binSIze = (6 * parseInt(sigma)) / 100;

        let sigmaBead = BrightBeads.stanDeviate();
        let sigmaValBead = 3 * parseInt(sigmaBead);
        let binSIzeBead = (6 * parseInt(sigmaBead)) / 100;

        let Beads = {
            histfunc: "count",
            y: unpack(rows, 'tileID'),
            x: SNR,
            type: "histogram",
            xbins: {
                end: parseInt(mean) + sigmaVal,
                size: binSIze,
                start: parseInt(mean) - sigmaVal
            },
            name: "Zero Flow Signal per tile"
        };

        let BrightBeadsData = {
            histfunc: "count",
            y: unpack(rows, 'tileID'),
            x: BrightBeads,
            type: "histogram",
            xbins: {
                end: parseInt(meanBead) + sigmaValBead,
                size: binSIzeBead,
                start: parseInt(meanBead) - sigmaValBead
            },
            name: "Zero Flow Signal per tile"
        };

        let data;
        let layout;
        if(isBrightBeads){
            data = [BrightBeadsData];
            layout = {
                //plot_bgcolor: "#303030",
                //paper_bgcolor: "#303030",
                bargap: 0.3,
                margin: {
                    t: 25,
                    b: 35,
                    l: 35
                },
                titlefont: {
                    "size": 14
                },
                // width: isBaseLine ? 400 : 600,
                height: 400,
                title: (isBaseLine ? "Zero Flow Signal per tile" : "Signal of Bright Beads per tile=") + parseInt(meanBead) + "&#177;" + parseFloat((parseInt(sigmaBead) / parseInt(meanBead)) * 100).toFixed(1) + "%",
                //xaxis: { title: "k/mm2" },
                yaxis: {
                    title: "Tile Frequency",
                titlefont: {
                        "size": 12
                    }
                },
                font: {
                    //color: 'white'
                },
            };
        } else {
            data = [Beads];
            layout = {
            //plot_bgcolor: "#303030",
            //paper_bgcolor: "#303030",
            bargap: 0.3,
            margin: {
                t: 25,
                b: 35,
                l: 35
            },
            titlefont: {
                "size": 14
            },
           // width: isBaseLine ? 400 : 600,
            height: 400,
            title: (isBaseLine ? "Zero Flow Signal per tile" : "SNR per tile=") + parseInt(mean) + "&#177;" + parseFloat((parseInt(sigma) / parseInt(mean)) * 100).toFixed(1) + "%",
            //xaxis: { title: "k/mm2" },
            yaxis: {
                title: "Tile Frequency",
                titlefont: {
                    "size": 12
                }
            },
            font: {
                //color: 'white'
            },
        };
        }

        let config = {
            modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo', 'pan2d', 'pan3d', 'autoScale2d', 'resetScale2d'],
            displaylogo: false,
            displayModeBar: true
        };

        return { data, layout, config };

    }
    catch { return {}; }
}