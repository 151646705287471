import React from 'react';
import { Form } from 'react-bootstrap';

const StatusCheckItem = props => {
    return (
        <Form.Check className="checkbox-inline check-status d-flex align-items-center pt-2">
            <input type={props.type} 
                name={props.name} 
                checked={props.disabled ? true : props.checked} 
                onChange={props.handleOptionChange} 
                disabled={props.disabled} />
            <span className="pl-2">{props.label}</span>
        </Form.Check>
    )
}

export default StatusCheckItem;
