import React, { useState } from 'react';
import { useTable, usePagination, useRowSelect, useFilters, useGlobalFilter, useSortBy } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@mui/material/Button';
import { Row, Col, } from "react-bootstrap";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import './table.css';
import { setAMPRunId, setRunListAMPDownloadData, getAmpSingleRunCSV, setAlert } from "../actions/index";
import * as Filters from '../maintable/filters';
import * as Utils from '../../utils/utils';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { findAll } from "highlight-words-core";
import ManageColumns from '../managecolumns/managecolumns';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from "react-csv";
import ViewListIcon from '@mui/icons-material/ViewList';
import Container from '@mui/material/Container';
import useAMPStickyHeader from "../../utils/hooks/useAMPStickyHeader";

function Table({ columns, data, handleRowClick, selectedRowIndex }) {
    const [isManageColumnsShowing, showManageColumns] = useState(false);

    const { tableRef, isSticky, isStickyRow } = useAMPStickyHeader();
    const state = useSelector(state => state);

    const dispatch = useDispatch();

    const getHiddenColumns = () => {
        try {
            let cols = localStorage.getItem('non-hidden-cols-amp');
            if (cols) {
                let visible = JSON.parse(cols);
                let all = columns.map(m => m.columns).flat();
                let toHide = all.filter(f => f !== undefined).filter(function (el) {
                    return !visible.includes(el.accessor);
                });
                return toHide.map(m => m.accessor);
            }
            else return Utils.Default_Hidden_Columns_AMP;
        }
        catch (e) {
            return [];
        }
    }

    const filterTypes = React.useMemo(
        () => ({
            fuzzyText: Filters.fuzzyTextFilterFn,
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = React.useMemo(
        () => ({
            Filter: Filters.DefaultColumnFilterAMP,
        }),
        []
    )

    function filterGreaterThan(rows, id, filterValue) {
        return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue >= filterValue
        })
    }

    filterGreaterThan.autoRemove = val => typeof val !== 'number'

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        allColumns,
        getToggleHideAllColumnsProps,
        getToggleRowSelectedProps,
        selectedFlatRows,
        state: {
            pageIndex,
            pageSize,
            sortBy,
            groupBy,
            expanded,
            filters,
            selectedRowIds,
            globalFilter
        },
        initialState,
        visibleColumns,
        setHiddenColumns,
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                sortBy: [
                    {
                        id: 'start',
                        desc: true
                    }
                ],
                hiddenColumns: getHiddenColumns(),
            },
            defaultColumn,
            filterTypes,
            autoResetPage: true,
            autoResetSelectedRows: false,
            autoResetHiddenColumns: false,
            useFilters,
            useGlobalFilter,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect
    )   

    const handleAMPRunDownloadClick = (event, done) => {
        try {
            let csv = [];
            let csvColumnsAccessors = [];
            columns.forEach(m => {
                csvColumnsAccessors.push(...m.columns.map(item => item.accessor));
                csv.push([...m.columns.map(item => item.Header)]);
            });
            csv = [csv.flat()];
            data.map(m => {
                let result = csvColumnsAccessors.map(item => {
                    if (item === 'runstatus') {
                        return m[item][item];
                    }
                    return m[item];
                });
                csv.push(result);
            });
            dispatch(setRunListAMPDownloadData(csv));
            done(true);
        }
        catch { }
    }

    const handleAMPSingleRunDownloadClick = () => {
        try {
            if (state.ampRunId === null) {
                dispatch(setAlert(`Select a RunId.`, 'Warning', 'warning'));
            }
            else {
                dispatch(getAmpSingleRunCSV(state.ampRunId, state.jwt))
                    .then(() => {
                        const downloadLink = document.getElementById('downloadLink');
                        if (downloadLink) downloadLink.click();
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        }
        catch (error) {
            console.error('Error:', error);
        }
    };

    const handleManageTableClick = () => {
        showManageColumns(true);
    }

    function getColumnTitle(column) {
        if (column === 'Start Date Time') {
          return 'Toggle SortBy. \nDate and Time of the start \nof AMP run.';
        }
        else if (column === 'System'){
            return 'Toggle SortBy. \nUG system unique ID.';
        }
        else if (column === 'RunID'){
            return 'Toggle SortBy. \nUnique run ID, comes from sample tube barcode.';
        }
        else if (column === 'Details'){
            return 'Toggle SortBy. \nUser typed comments. Comments are added during sample registration in SampleDB. \nThey also can be edited here after run is completed.';
        }
        else if (column === 'Script Version'){
            return 'Toggle SortBy. \nAMP Script Version.';
        }
        else if (column === 'Reagents'){
            return 'Toggle SortBy. \nList of barcodes for racks, tubes, cartridges, wafers, \nkits utilized in AMP run.';
        }
        else if (column === 'Run Status'){
            return 'Toggle SortBy. \nStatus of the AMP run: In Progress, Completed, Aborted. \nFor Aborted hint message provides the reason for termination.';
        }
        
        return '';
    }

    return (
        <div className="tableContainer">
            <Row>
                <Col lg={3}>
                    <Filters.GlobalFilterMainAmp preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} gotoPage={gotoPage} pageIndex={pageIndex} />
                </Col>
                <Col lg={2}>
                    <ManageColumns show={isManageColumnsShowing} handleCloseModal={() => showManageColumns(false)} getToggleHideAllColumnsProps={getToggleHideAllColumnsProps} allColumns={allColumns} visibleColumns={visibleColumns} localStorageFileName='non-hidden-cols-amp' onReset={() => { setHiddenColumns(Utils.Default_Hidden_Columns_AMP)} }/>
                </Col>
                <Col lg={7}>
                    <Button id="downloadAmpRunButton" className="advanced-filter-btn" color="primary" size="large" onClick={handleAMPSingleRunDownloadClick} startIcon={<DownloadIcon />} style={{ height: '93%', width: '20%', color: 'white', float: 'right', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                        Export Amp Run
                    </Button>
                    <Button className="advanced-filter-btn" color="primary" size="large" startIcon={<DownloadIcon />} style={{ height: '93%', width: '20%', color: 'white', float: 'right', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                        <CSVLink asyncOnClick={true} filename={`nexus-amp-table-data.csv`} data={state.runlist_amp_download_data} onClick={handleAMPRunDownloadClick} style={{ color: 'white', textDecoration: 'none', }}>
                            Download
                        </CSVLink>
                    </Button>
                    <Button className="advanced-filter-btn" color="primary" size="large" startIcon={<ViewListIcon />} style={{ height: '93%', width: '20%', color: 'white', float: 'right', marginRight: 10, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }} onClick={handleManageTableClick}>Manage</Button>                
                </Col>
            </Row>
            {isSticky && state.show_frozen_header === true &&
                (<Container id="stickeHeaderContainer" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,                   
                    zIndex: 100
                }}>
                    <MaUTable {...getTableProps()} style={{ width: '99%' }} >
                        <TableHead id='stickyHeaderTableHead' style={{ width: '75%', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                            {headerGroups.map(headerGroup => (
                                <TableRow {...headerGroup.getHeaderGroupProps()} key={Utils.generateKey('trh')}>
                                    {headerGroup.headers.map(column => (
                                        <TableCell key={Utils.generateKey('tch')} {...column.getHeaderProps(column.getSortByToggleProps(), {
                                        })}
                                            style={{ width: column.widthHeader ? column.widthHeader : column.width, minWidth: column.minWidthHeader ? column.minWidthHeader : column.minWidth, textAlign: 'center', alignContent: 'center' }}
                                            align="center" title={getColumnTitle(column.Header !== undefined ? column.Header : '')}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <ExpandMoreIcon />
                                                        : <ExpandLessIcon />
                                                    : ''}
                                            </span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            <TableRow>
                                {headerGroups !== undefined && headerGroups.length > 0 && headerGroups[1] !== undefined && headerGroups[1].headers !== undefined && headerGroups[1].headers.map((column, i) => (
                                    <TableCell key={i} align="center" padding="none" className={!column.canFilter ? 'bg-white-non-filtered' : 'bg-white'}>
                                        {column.canFilter ? column.render('Filter') : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody style={selectedRowIndex !== -1 && isStickyRow ? { display: '' } : { display: 'none' }}>
                            {page.map((row, i) => {
                                if (state.ampRunId !== null && state.ampRunId !== undefined) {
                                    let runId = row.original.runid ? state.ampRunId : state.ampRunId.split('_')[0];
                                    if (row.original.runid === runId && !row.original.issubrow && selectedRowIndex !== -1) {
                                        prepareRow(row)
                                        return (
                                            <>
                                            <TableRow key={Utils.generateKey('trh')} {...row.getRowProps()} className='row-active' style={{ height: 50, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <TableCell key={Utils.generateKey('tch')} {...cell.getCellProps()} className={cell.column.id === 'details' || cell.column.id === 'analysiscomment' ? 'truncate-overflow-span' : undefined} style={{ width: cell.column.width, minWidth: cell.column.minWidth, textAlign: 'center', alignContent: 'center' }}>
                                                            {cell.render('Cell')}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                            </>
                                        )
                                    }
                                }
                            })}
                        </TableBody>
                    </MaUTable>
                </Container>)}
            <Container id="mainHeader" style={{
                margin: 0,
                width: '100%',
                maxWidth: '100%',                
            }}>
                <MaUTable ref={tableRef} {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell width={column.minWidth} {...column.getHeaderProps(column.getSortByToggleProps())} align="center" title={getColumnTitle(column.Header !== undefined ? column.Header : '')}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? <ExpandMoreIcon />
                                                    : <ExpandLessIcon />
                                                : ''}
                                        </span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        <TableRow>
                            {headerGroups !== undefined && headerGroups.length > 0 && headerGroups[1] !== undefined && headerGroups[1].headers !== undefined && headerGroups[1].headers.map((column, i) => (
                                <TableCell key={i} align="center" padding="none" className={!column.canFilter ? 'bg-white-non-filtered' : 'bg-white'}>
                                    {column.canFilter ? column.render('Filter') : null}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()} onClick={(e) => handleRowClick(e, row, i)} className={`${selectedRowIndex === i ? "row-active" : "not-row-active"}`} style={{ cursor: 'pointer' }}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell {...cell.getCellProps()} style={{ textAlign: 'left', alignContent: 'center' }}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </MaUTable>
            </Container>
            <Row className="text-center" style={{ alignItems: 'center', marginTop: 20 }}>
                <Col lg={12}>
                    <div>
                        <Button className="advanced-filter-btn-pager" variant="contained" onClick={() => gotoPage(0)} disabled={!canPreviousPage} style={{ width: '200px', marginTop: 7, height: 40, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{'<<'}</Button>{' '}
                        <Button className="advanced-filter-btn-pager" variant="contained" onClick={() => previousPage()} disabled={!canPreviousPage} style={{ width: '200px', marginTop: 7, height: 40, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{'<'}</Button>{' '}
                        <span style={{ verticalAlign: 'middle' }}>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </span>
                        <TextField size="small" id="outlined-basic" label="Go to Page" variant="outlined" inputProps={{ type: 'number', style: { textAlign: 'center', height: 23 }, }} defaultValue={pageIndex + 1} style={{ width: 120, marginTop: 8, }} onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }} />

                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small">Page Size</InputLabel>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={pageSize}
                                label="Page Size"
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                size="small"
                            >
                                <MenuItem value={10}>Show 10</MenuItem>
                                <MenuItem value={20}>Show 20</MenuItem>
                                <MenuItem value={30}>Show 30</MenuItem>
                                <MenuItem value={40}>Show 40</MenuItem>
                                <MenuItem value={50}>Show 50</MenuItem>
                            </Select>
                        </FormControl>
                        <Button className="advanced-filter-btn-pager" variant="contained" onClick={() => nextPage()} disabled={!canNextPage} style={{ width: '200px', height: 40, marginTop: 7, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{'>'}</Button>{' '}
                        <Button className="advanced-filter-btn-pager" variant="contained" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} style={{ width: '200px', height: 40, marginTop: 7, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{'>>'}</Button>{' '}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const RenderTable = (props) => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const { mainTableData, handleTabsSelect, handleClearAdvancedSearch } = props;

    const [selectedRowIndex, setSelectedRowIndex] = React.useState(-1);

    const handleRowClick = (e, data, index) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedRowIndex(index);
        dispatch(setAMPRunId(data.original.runid));
    };

    const checkColumnIsHidden = name => {
        try {
            let cols = localStorage.getItem('non-hidden-cols-amp');
            if (cols) {
                let visible = JSON.parse(cols);
                let s = visible.filter(f => f === name);
                if (s !== undefined && s.length > 0) return false
                else return true;
            }
            else return false;
        }
        catch (e) {
            return false;
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'AMP Run Info',
                columns: [
                    {
                        Header: 'Start Date Time',
                        accessor: 'start',
                        sortType: (a, b) => {
                            return new Date(a.values.start) - new Date(b.values.start);
                        },
                        minWidth: 200,
                        disableGlobalFilter: checkColumnIsHidden('start'),
                        Cell: row => renderTableCell(row.value, 'start', undefined, 'text-center'),
                    },
                    {
                        Header: 'System',
                        accessor: 'system',
                        disableGlobalFilter: checkColumnIsHidden('system'),
                        Cell: row => renderTableCell(row.value, 'system', undefined, 'text-center'),
                    },
                    {
                        Header: 'RunID',
                        accessor: 'runid',
                        disableGlobalFilter: checkColumnIsHidden('runid'),
                        Cell: row => renderTableCell(row.value, 'runid', undefined, 'text-center'),
                    },
                    {
                        Header: 'Details',
                        accessor: 'details',
                        minWidth: 800,
                        disableGlobalFilter: checkColumnIsHidden('details'),
                        Cell: row => renderTableCell(row.value, 'details', 800, 'text-left'),
                    },
                    {
                        Header: 'Script Version',
                        accessor: 'scriptVersion',
                        minWidth: 400,
                        disableGlobalFilter: checkColumnIsHidden('scriptVersion'),
                        Cell: row => renderTableCell(row.value, 'scriptVersion', 300, 'text-left'),
                    },
                    {
                        Header: 'Reagents',
                        accessor: 'reagent',
                        disableGlobalFilter: checkColumnIsHidden('reagent'),
                        Cell: row => renderTableCell(row.value, 'reagent', 200, 'text-center'),
                    },
                    {
                        Header: 'Run Status',
                        accessor: 'runstatus',
                        minWidth: 100,
                        disableFilters: true,
                        disableGlobalFilter: checkColumnIsHidden('runstatus'),
                        Cell: row => Utils.getAMPRunStatus(row.value),
                        //Cell: row => renderTableCell(row.value, 'runstatus', undefined, 'text-center'),
                    },
                ],
            },
        ],
        [state.search_term_amp, state.runlist_searched_amp, state.amplist]
    )

    const getTitleText = text => {
        try {
            if (text !== undefined && (typeof text === 'string') || typeof text === 'number') {
                return text;
            }
            else if (text !== null && text !== undefined && text.props !== undefined && text.props.children !== undefined && text.props.children !== null) {
                return Array.isArray(text.props.children) ? text.props.children.join('') : text.props.children.toString();;
            }
            else {
                return text;
            }
        }
        catch { }
    }

    const renderTableCell = (value, columnName, columnWidth, className) => {
        try {
            const {searchColumn} = state?.search_term_amp.length > 0 ? state.search_term_amp[state.search_term_amp?.length - 1] : {searchColumn: 'all', searchWords: ''}; 
            const searchWords = state?.search_term_amp.length > 0 ? state.search_term_amp?.map(value => value.searchWords) : [];
            if (searchWords !== undefined && searchColumn !== undefined && searchWords.length > 0 && searchWords[0] !== undefined && value !== undefined) {
                //var valueToHighlight = value;
                let valueToHighlight = (columnName === searchColumn || searchColumn === 'all') ? value : '';

                const chunks = findAll({
                    caseSensitive: false,
                    searchWords: searchWords,
                    textToHighlight: valueToHighlight
                });

                if ((chunks !== undefined && chunks.length === 0) || valueToHighlight === null) {
                    return <div key={Utils.generateKey(99)} title={getTitleText(value)}>{value}</div>;
                }

                return chunks
                    .map((chunk, i) => {
                        const { end, highlight, start } = chunk;
                        if (!valueToHighlight) return <span key={Utils.generateKey(i)} title={getTitleText(value)}>{value}</span>
                        const text = valueToHighlight.toString().substr(start, end - start);
                        if (highlight) {
                            let visibleColumns = Utils.getVisibleColumnsAMP();
                            //if (visibleColumns && visibleColumns.includes(columnName)) {
                                return <span key={Utils.generateKey(i)} title={getTitleText(value)} style={{ backgroundColor: 'green', boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>{text}</span>;
                            //}  
                        } else {
                            return <span key={Utils.generateKey(i)} title={getTitleText(value)}>{text}</span>;
                        }
                    });
            }
            else return <div key={Utils.generateKey(99)} title={getTitleText(value)} className={className} style={columnWidth !== undefined ? { width: columnWidth } : {}}>{value}</div>;
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Table columns={columns} data={mainTableData} handleRowClick={handleRowClick} selectedRowIndex={selectedRowIndex} handleClearAdvancedSearch={handleClearAdvancedSearch}  />
    );
}

export default RenderTable;


