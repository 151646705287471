export const DrawChartViewPlots = (csv) => {
    try {
        const unpack = (rows, key) => {
            return rows.map(function (row) { return row[key]; });
        }

        const unpackRunId = (rows, key) => {
            return rows.map(function (row) { return `RunId: ${row[key]}`; });
        }

        const unpackDate = (rows, key) => {
            return rows.map(function (row) {
                if (row[key] !== undefined && row[key] !== null && row[key] !== '') {
                    return new Date(row[key].split(' ')[0]);
                } else {
                    return null;
                }
            });
        }

        const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});

        if (csv !== undefined && csv !== "" && csv.length > 0) {
            let Aligned_Bases = [];
            let Indel_Rate = [];
            let STS = [];
            let Lag = [];
            let Lead = [];
            let Droop = [];
            let Cyc50 = [];
            let F95Min = [];
            let RLQ25 = [];
            let BER80 = [];

            let grouped = groupByKey(csv, 'sysid');
            Object.keys(grouped).forEach(key => {
                let Aligned_BasesData = grouped[key].filter(f => f.aligned_bases !== null && f.aligned_bases !== '' && f.aligned_bases !== undefined && f.aligned_bases !== 'nan' && f.aligned_bases !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                if (Aligned_BasesData !== undefined && Aligned_BasesData.length > 0) {
                    Aligned_Bases.push({
                        type: "scatter",
                        mode: "markers",
                        name: key,
                        y: unpack(Aligned_BasesData, 'aligned_bases'),
                        x: unpackDate(Aligned_BasesData, 'startdatetime'),
                        //line: { color: '#17BECF' }
                        //fill: 'tozeroy',
                        text: unpackRunId(Aligned_BasesData, 'runid'),
                    });
                }

                let Indel_RateData = grouped[key].filter(f => f.indel_rate !== null && f.indel_rate !== '' && f.indel_rate !== undefined && f.indel_rate !== 'nan' && f.indel_rate !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                Indel_Rate.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(Indel_RateData, 'indel_rate'),
                    x: unpackDate(Indel_RateData, 'startdatetime'),
                    //line: { color: '#1f77b4' },
                    //fill: 'tozeroy',
                    text: unpackRunId(Indel_RateData, 'runid'),
                });

                let stsData = grouped[key].filter(f => f.sts !== null && f.sts !== '' && f.sts !== undefined && f.sts !== 'nan' && f.sts !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                //let temp = stsData.map(m => m.startdatetime);
                //let y = unpack(stsData, 'sts');
                //let x = unpackDate(stsData, 'startdatetime');
                STS.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(stsData, 'sts'),
                    x: unpackDate(stsData, 'startdatetime'),
                    //line: { color: '#17BECF' }
                    //fill: 'tozeroy',
                    text: unpackRunId(stsData, 'runid'),
                });

                let LagData = grouped[key].filter(f => f.lag !== null && f.lag !== '' && f.lag !== undefined && f.lag !== 'nan' && f.lag !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                Lag.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(LagData, 'lag'),
                    x: unpackDate(LagData, 'startdatetime'),
                    //line: { color: '#1f77b4' },
                    //fill: 'tozeroy',
                    text: unpackRunId(LagData, 'runid'),
                });

                let LeadData = grouped[key].filter(f => f.lead !== null && f.lead !== '' && f.lead !== undefined && f.lead !== 'nan' && f.lead !== 'inf').sort(function (a, b) {
                    return new Date(b.startdatetime) - new Date(a.startdatetime);
                });
                Lead.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(LeadData, 'lead'),
                    x: unpackDate(LeadData, 'startdatetime'),
                    //line: { color: '#1f77b4' },
                    //fill: 'tozeroy',
                    text: unpackRunId(LeadData, 'runid'),
                });

                let DroopData = grouped[key].filter(f => f.droop !== null && f.droop !== '' && f.droop !== undefined && f.droop !== 'nan' && f.droop !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                Droop.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(DroopData, 'droop'),
                    x: unpackDate(DroopData, 'startdatetime'),
                    //line: { color: '#1f77b4' },
                    //fill: 'tozeroy',
                    text: unpackRunId(DroopData, 'runid'),
                });

                let Cyc50Data = grouped[key].filter(f => f.cyc50_frac !== null && f.cyc50_frac !== '' && f.cyc50_frac !== undefined && f.cyc50_frac !== 'nan' && f.cyc50_frac !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                Cyc50.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(Cyc50Data, 'cyc50_frac'),
                    x: unpackDate(Cyc50Data, 'startdatetime'),
                    // line: { color: '#1f77b4' },
                    //fill: 'tozeroy',
                    text: unpackRunId(Cyc50Data, 'runid'),
                });

                let F95MinData = grouped[key].filter(f => f.f95_30x_min !== null && f.f95_30x_min !== '' && f.f95_30x_min !== undefined && f.f95_30x_min !== 'nan' && f.f95_30x_min !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                F95Min.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(F95MinData, 'f95_30x_min'),
                    x: unpackDate(F95MinData, 'startdatetime'),
                    //line: { color: '#1f77b4' },
                    //fill: 'tozeroy',
                    text: unpackRunId(F95MinData, 'runid'),
                });

                let RLQ25Data = grouped[key].filter(f => f.rlq25 !== null && f.rlq25 !== '' && f.rlq25 !== undefined && f.rlq25 !== 'nan' && f.rlq25 !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                RLQ25.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(RLQ25Data, 'rlq25'),
                    x: unpackDate(RLQ25Data, 'startdatetime'),
                    //line: { color: '#1f77b4' },
                    //fill: 'tozeroy',
                    text: unpackRunId(RLQ25Data, 'runid'),
                });

                let BER80Data = grouped[key].filter(f => f.ber80 !== null && f.ber80 !== '' && f.ber80 !== undefined && f.ber80 !== 'nan' && f.ber80 !== 'inf').sort(function (a, b) {
                    return new Date(a.startdatetime) - new Date(b.startdatetime);
                });
                BER80.push({
                    type: "scatter",
                    mode: "markers",
                    name: key,
                    y: unpack(BER80Data, 'ber80'),
                    x: unpackDate(BER80Data, 'startdatetime'),
                    //line: { color: '#1f77b4' },
                    //fill: 'tozeroy',
                    text: unpackRunId(BER80Data, 'runid'),
                });
            });

            let layoutAligned_Bases = {               
                title: 'Aligned Bases',
                hovermode: 'closest',
                font: {
                    size: 12,
                   
                },
                margin: {
                    //t: 45,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                  
                    //showline: true,
                    tickfont: {
                        size: 10,                       
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,                       
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                       
                    },
                }
            };

            let layoutIndel_Rate = {               
                title: 'Indel Rate',
                hovermode: 'closest',
                font: {
                    size: 12,
                   
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                   
                    //showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    x: 0.2,
                    y: 1.5
                },
                legend: {
                    font: {
                        size: 10,
                       
                    },
                }
            };

            let layoutSTS = {               
                title: 'STS',
                hovermode: 'closest',
                font: {
                    size: 12,
                   
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                   
                    //showline: true,
                    tickfont: {
                        size: 10,
                        
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                       
                    },
                }
            };

            let layoutLag = {               
                title: 'Lag',
                hovermode: 'closest',
                font: {
                    size: 12,
                   
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                   
                    //showline: true,
                    tickfont: {
                        size: 10,
                        
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                       
                    },
                }
            };

            let layoutLead = {               
                title: 'Lead',
                hovermode: 'closest',
                font: {
                    size: 12,
                    
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                    tickcolor: 'white',
                    //showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                        
                    },
                }
            };

            let layoutDroop = {              
                title: 'Droop',
                hovermode: 'closest',
                font: {
                    size: 12,
                  
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                    
                    //showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                        
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                       
                    },
                }
            };

            let layoutCyc50 = {              
                title: 'Cyc50',
                hovermode: 'closest',
                font: {
                    size: 12,
                    
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                    tickcolor: 'white',
                    //showline: true,
                    tickfont: {
                        size: 10,
                        
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                        
                    },
                }
            };

            let layoutF95Min = {               
                title: 'F95Min',
                hovermode: 'closest',
                font: {
                    size: 12,
                   
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                   
                    //showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                       
                    },
                }
            };

            let layoutRLQ25 = {
               
                title: 'RLQ25',
                hovermode: 'closest',
                font: {
                    size: 12,
                   
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                    
                    //showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                        
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                        
                    },
                }
            };

            let layoutBER80 = {               
                title: 'BER80',
                hovermode: 'closest',
                font: {
                    size: 12,
                   
                },
                margin: {
                    //t: 2,
                    //l: 25
                },
                //width: 950,
                height: 650,
                xaxis: {
                    type: 'date',
                    tickformat: '%x',
                    //tick0: 0,
                    //dtick: 1,
                   
                    //showline: true,
                    tickfont: {
                        size: 10,
                        
                    },
                    automargin: true,
                },
                yaxis: {
                    ticks: 'outside',
                    tickcolor: '#000',
                    tickangle: 0,
                    //dtick: 5,
                    showline: true,
                    tickfont: {
                        size: 10,
                       
                    },
                    automargin: true,
                },
                showlegend: true,
                legend: {
                    font: {
                        size: 10,
                       
                    },
                }
            };

            let config = {
                modeBarButtonsToRemove: ['sendDataToCloud', 'displaylogo'],
                displaylogo: false,
                displayModeBar: false
            };
    
            return { Aligned_Bases, layoutAligned_Bases, Indel_Rate, layoutIndel_Rate, STS, layoutSTS, Lag, layoutLag, Lead, layoutLead, Droop, layoutDroop, Cyc50, layoutCyc50, F95Min, layoutF95Min, RLQ25, layoutRLQ25, BER80, layoutBER80, config };           
        }
    }
    catch {       
        return undefined;
    }
}