import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import './statusitems.css';

const selectItems = {
    c: "Contains",
    r: "Range", 
    'r-int': "Range",        
    l: "Lower than",
    'l-int': "Lower than",
    h: "Higher than", 
    'h-int': "Higher than", 
    d: "Doesn't contain", 
    e: "Equals", 
    'e-int': "Equals",
    a: "All",
    AND: "AND",
    OR: "OR"
}

const StatusItems = props => {
    const {handleSelect, selection, displayValues} = props; 
    
    const changeSelection = (e) => {
        handleSelect(e);
    }

    return (
        <DropdownButton key={selection.key || 'a'} title={selectItems[selection.op] || 'All'} onSelect={changeSelection} id='status-items-dropdown-id'>
            {Object.entries(displayValues).map(([key, value] ) => <Dropdown.Item key={`dd${key}`} eventKey={key}>{value}</Dropdown.Item>)}               
        </DropdownButton>
    )
}

export default StatusItems;