import React from 'react';
import { Document, Page } from 'react-pdf';
import * as Utils from '../../utils/utils';

const Frame = (props) => {
    const { data, src } = props;
    const [numPages, setNumPages] = React.useState(0);

    const onDocumentLoadSuccess = doc => setNumPages(doc.numPages);
    const range = count => Array(count).fill(0).map((_, idx) => idx);   
    return (
        <React.Fragment>
            {data ?
                <iframe classname="text-center" style={{ width: '100%', height: 1000 }} sandbox="allow-same-origin" title="static_html" srcDoc={data}></iframe> :
                <Document
                    options={{ workerSrc: "/pdf.worker.js" }}
                    file={src}
                    onLoadError={console.error}
                    onLoadSuccess={onDocumentLoadSuccess}>                        
                        {range(numPages).map(pg => (
                            <>
                                {pg !== numPages && <div style={{ margin: "1em" }} />}                               
                                <Page pageNumber={pg + 1} key={Utils.generateKey(pg)} />
                            </>
                        ))}
                </Document>
            }
        </React.Fragment>
    );

};

export default Frame;