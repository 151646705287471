import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setFlow, getHistogramPlotsDataAsync, setIsHistogramPlotsLoading, getOpticsPlotsDataAsync, setHistogramPlotsRenderData } from "../actions/index";
import TextField from '@mui/material/TextField';
import * as Utils from '../../utils/utils';

export const OpticFlowSpinner = (props) => {
    const { flow, maxFlows, beadType } = props;

    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const [currFlow, setCurrFlow] = useState(1);

    const onCurrFlowChange = e => {
        try {
            setCurrFlow(parseInt(e.target.value));
            dispatch(setFlow(parseInt(e.target.value)));
            if (state.instrumentId !== null && state.runId !== null) {
                if (state.active_tab_name === 'instrument_health') {
                    let exists = state.optics_plots_data.filter(f => f.runid === state.runId && f.flow === currFlow && f.beadsType === beadType);
                    if (exists.length === 0) {
                        dispatch(getOpticsPlotsDataAsync(state.runId, currFlow, beadType, state.jwt));
                    }
                    else if (exists.length > 0 && exists[0].data !== undefined && exists[0].data.length === 0) {
                        dispatch(getOpticsPlotsDataAsync(state.runId, currFlow, beadType, state.jwt));
                    }
                }
            }
        }
        catch { }
    }

    const handleKeyDown = e => {
        try {
            if (e.key === 'Enter') {
                dispatch(setFlow(currFlow));
                if (state.instrumentId !== null && state.runId !== null) {
                    if (state.active_tab_name === 'instrument_health') {
                        let exists = state.optics_plots_data.filter(f => f.runid === state.runId && f.flow === currFlow && f.beadsType === beadType);
                        if (exists.length === 0) {
                            dispatch(getOpticsPlotsDataAsync(state.runId, currFlow, beadType, state.jwt));
                        }
                        else if (exists.length > 0 && exists[0].data !== undefined && exists[0].data.length === 0) {
                            dispatch(getOpticsPlotsDataAsync(state.runId, currFlow, beadType, state.jwt));
                        }
                    }
                }
            }
        }
        catch(e) {
            console.error(e);
        }
    }

    return (
        <div className="row" style={{marginTop: 15,}}>
            <div className="col-lg-12">
                <TextField
                    id="outlined-uncontrolled-flow"
                    label="Flow:"
                    onChange={onCurrFlowChange}
                    value={currFlow || flow}
                    onKeyDown={handleKeyDown}
                    //defaultValue={currFlow}
                    type="number"
                    size="small"
                    inputProps={{ min: 1, max: maxFlows, style: { textAlign: 'center', fontWeight: 'bold', width: 100} }}
                />               
            </div>
        </div>
    )
};

const FlowSpinner = (props) => {
    const { maxFlows } = props;

    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const [currFlow, setCurrFlow] = useState(1);

    const onCurrFlowChange = e => {
        try {
            setCurrFlow(parseInt(e.target.value));
            dispatch(setFlow(parseInt(e.target.value)));
            if (state.instrumentId !== null && state.runId !== null) {               
                if (state.active_tab_name === 'signals') {
                    let existsHistogram = state.histogram_plots_data.filter(f => f.runid === state.runId && f.flow === parseInt(e.target.value));
                    if (existsHistogram.length === 0) {
                        if (state.instrumentId !== null) {
                            let tileArea = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrId === state.instrumentId);
                            dispatch(getHistogramPlotsDataAsync(state.runId, parseInt(e.target.value), state.instrumentId, tileArea !== undefined && tileArea.length > 0 ? tileArea[0] : undefined, state.jwt));
                        }
                    }
                    else {
                        dispatch(setIsHistogramPlotsLoading(true));
                        dispatch(setHistogramPlotsRenderData(existsHistogram[0].data, state.runId, state.flow));
                    }
                }
            }
        }
        catch { }
    }

    const handleKeyDown = e => {
        try {
            if (e.key === 'Enter') {
                dispatch(setFlow(parseInt(e.target.value)));
                if (state.instrumentId !== null && state.runId !== null) {                  
                    if (state.active_tab_name === 'signals') {
                        let existsHistogram = state.histogram_plots_data.filter(f => f.runid === state.runId && f.flow === parseInt(e.target.value));
                        if (existsHistogram.length === 0) {
                            if (state.instrumentId !== null) {
                                let tileArea = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrid === state.instrumentId);
                                dispatch(getHistogramPlotsDataAsync(state.runId, parseInt(e.target.value), state.instrumentId, tileArea !== undefined && tileArea.length > 0 ? tileArea[0] : undefined, state.jwt));
                            }
                        }
                        else {
                            dispatch(setIsHistogramPlotsLoading(true));
                            dispatch(setHistogramPlotsRenderData(existsHistogram[0].data, state.runId, state.flow));
                        }
                    }
                }
            }
        }
        catch { }
    }

    return (
        <div className="row" style={{marginTop: 15,}}>
            <div className="col-lg-12">
                <TextField
                    id="outlined-uncontrolled-flow"
                    label="Flow:"
                    onChange={onCurrFlowChange}
                    value={currFlow}
                    onKeyDown={handleKeyDown}
                    //defaultValue={currFlow}
                    type="number"
                    size="small"
                    inputProps={{ min: 1, max: maxFlows, style: { textAlign: 'center', fontWeight: 'bold', width: 100} }}
                />
                {/* <input type="number" className="form-control" style={{ marginTop: 15 }} id="flowSpinner" value={currFlow} min="1" max={maxFlows} onChange={onCurrFlowChange} onKeyDown={handleKeyDown} ></input> */}
            </div>
        </div>
    )
};

export default FlowSpinner;