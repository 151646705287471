import React from 'react';

const Image = (props) => {    
    return (
        <React.Fragment>
          <img src={`data:image/png;base64,${props.src}`} alt='Image' width={props.width || 700}/>
        </React.Fragment>
    )
};

export default Image;