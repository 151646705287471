import React, {useState, useRef, useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from "react-redux";

const useBarcodeStickyHeader = (tableRef = null, defaultSticky = false) => {
    const [isSticky, setIsSticky] = useState(defaultSticky);
    const showFrozenHeader = useSelector(state => state.show_frozen_header)

      const getFrozenHeaderHeight = useCallback(() => {
        if (!showFrozenHeader) return 0;
        const frozenHeader = document?.querySelector('#stickeHeaderContainer');
        return frozenHeader ? frozenHeader?.offsetHeight : 0;
      }, [showFrozenHeader]);
    
      const toggleStickiness = useCallback(({ top, bottom }) => {
        const frozenHeaderHeight = getFrozenHeaderHeight();
        const stickyThreshold = 10 + frozenHeaderHeight;
    
        if (top <= stickyThreshold && bottom >= frozenHeaderHeight) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }, [getFrozenHeaderHeight]);

    useEffect(() => {
        const handleScroll = () => {
            if (tableRef.current) {
                toggleStickiness(tableRef.current.getBoundingClientRect());
            }
        };
        window.addEventListener("scroll", handleScroll);
    
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [tableRef, toggleStickiness]);

    return { tableRef, isSticky };
}

export default useBarcodeStickyHeader