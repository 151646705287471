import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });
const API = {};
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL_AUTH,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('token')}`
    }
});

API.logOut = () => {
    return instance.post('/logout');
} 

API.isAuthenticated = () => {
    return instance.get('/auth');
} 

API.forgot = (body) => {
    return instance.post('/forgot', body);    
}

API.resetUserPassword  = (userId) => {
    return instance.get(`/api/resetpassword/${userId}`);
}

export default API;