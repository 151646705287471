import React, { useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import ProfileMenu from '../profilemenu/profilemenu';
import './topmenu.css';
import About from '../about/about';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/UltimaSvgLogo.svg';
import BallotIcon from '@mui/icons-material/Ballot';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import Storage from '../storage/storage';
import TimeZone from '../timezone/timezone';

const TopMenu = () => {
    const [aboutOpen, setAboutOpen] = React.useState(false);
    const [storageOpen, setStorageOpen] = useState(false);
    const [timeZoneOpen, setTimeZoneOpen] = useState(false);

    const handleOpenAbout = () => {
        setAboutOpen(true);
    };

    const handleCloseAbout = () => {
        setAboutOpen(false);
    };

    const handleOpenStorage = () => {
        setStorageOpen(true);
    };

    const handleCloseStorage = () => {
        setStorageOpen(false);
    };

    const handleOpenTimeZone = () => {
        setTimeZoneOpen(true);
    };

    const handleCloseTimeZone = () => {
        setTimeZoneOpen(false);
    };

    const location = useLocation();
    let pathName = location.pathname;

    return (
        <Navbar expand='md' className="nxs-nav sticky" sticky="top">
            <Navbar.Brand className="brand" href={`/`}>
                <img src={logo} alt="Ultimagen" style={{marginLeft:15, padding: 5   }}/>
            </Navbar.Brand>
            <About isOpen={aboutOpen} handleOpen={handleOpenAbout} handleClose={handleCloseAbout} />
            <Storage isOpen={storageOpen} handleOpen={handleOpenStorage} handleClose={handleCloseStorage} />
            <TimeZone isOpen={timeZoneOpen} handleOpen={handleOpenTimeZone} handleClose={handleCloseTimeZone} />
            <Nav fill variant="tabs" defaultActiveKey={'/'} className="container-fluid" id="topNav">
                <Nav.Item style={{borderRight: 0}}>
                    <Link to={`/`} data-rb-event-key={`/`}
                        className={(pathName === `/`) ? "tab nav-link active" : "tab nav-link"} data-toggle="tab" role="tab" style={{fontFamily: 'Arial', }}>
                        <BallotIcon style={{ marginBottom: 5,  }} />  Sequencing Runs
                    </Link>
                </Nav.Item>
                <Nav.Item style={{borderRight: 0}}>
                    <Link to={`/chartview`} data-rb-event-key={`/chartview`}
                        className={(pathName === `/chartview`) ? "tab nav-link active" : "tab nav-link"} data-toggle="tab" role="tab" style={{fontFamily: 'Arial', }}>
                        <ScatterPlotIcon style={{ marginBottom: 5,  }} />  Chart View
                    </Link>
                </Nav.Item>
                <Nav.Item style={{borderRight: 0}}>
                    <Link to={`/amp`} data-rb-event-key={`/amp`}
                        className={(pathName === `/amp`) ? "tab nav-link active" : "tab nav-link"} data-toggle="tab" role="tab" style={{fontFamily: 'Arial', }}>
                        <ReceiptLongIcon style={{ marginBottom: 5 }} /> AMP Runs
                    </Link>
                </Nav.Item>
            </Nav>

            <Nav>
                <Nav.Link style={{display: 'flex', alignItems: 'center', marginRight: 100, }}>
                    <ProfileMenu onAboutClick={handleOpenAbout} onStorageClick={handleOpenStorage} onTimeZoneClick={handleOpenTimeZone}/>
                </Nav.Link>
            </Nav>  
        </Navbar>
    );
}

export default TopMenu;